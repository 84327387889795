import React, { useState } from 'react';

import { Grid } from 'react-md';

import { DefaultLayout } from '../../components';

// Components
import { UserReport } from './subreports/UserReport';
import { EncodedReport } from './subreports/EncodedReport';
import { NewsReport } from './subreports/NewsReport';

const styles = {
    ulTabsReports: {
        height: 50,
        fontSize: 18,
    },
    liMdTab: {
        margin: 'auto',
    },
};

export const NewReportsPage = () => {

    const [pageSel, setPageSel] = useState(1);

    return (
        <DefaultLayout>
            <ul style={styles.ulTabsReports} className="md-tabs md-background--primary">
                <li
                    style={styles.liMdTab}
                    className={`md-fake-btn md-pointer--hover md-fake-btn--no-outline ${pageSel === 1 ? "md-tab--active" : "md-tab--inactive"}`}
                    onClick={() => setPageSel(1)}
                >
                    Reporte por Usuario
                </li>
                <li
                    style={styles.liMdTab}
                    className={`md-fake-btn md-pointer--hover md-fake-btn--no-outline ${pageSel === 2 ? "md-tab--active" : "md-tab--inactive"}`}
                    onClick={() => setPageSel(2)}
                >
                    Reporte por Activos
                </li>
                <li
                    style={styles.liMdTab}
                    className={`md-fake-btn md-pointer--hover md-fake-btn--no-outline ${pageSel === 3 ? "md-tab--active" : "md-tab--inactive"}`}
                    onClick={() => setPageSel(3)}
                >
                    Reporte por Novedades
                </li>
            </ul>

            <div className="md-tabs-content" style={{ overflowX: "hidden" }}>
                <Grid>

                    {
                        pageSel === 1 && (
                            <UserReport />
                        )
                    }

                    {
                        pageSel === 2 && (
                            <EncodedReport />
                        )
                    }

                    {
                        pageSel === 3 && (
                            <NewsReport />
                        )
                    }

                </Grid>
            </div>
        </DefaultLayout>
    );
};