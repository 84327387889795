import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Cell,
  Card,
  CardTitle,
  CardText,
  DataTable,
  DialogContainer,
  Grid,
  LinearProgress,
  List,
  Checkbox,
  DatePicker,
  SelectField,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  TablePagination,
} from 'react-md';
import moment from 'moment';
import ReactExport from 'react-data-export';
import { isNull } from 'util';
import { session } from '../../services/config/options';
import { fetchStationsReportLapse } from '../../services/reports';
import { DefaultLayout, ReportsNav, FlatOrIconButton, LineSeriesChart, DoubleBarChart } from '../../components';
import {
  getStationsReport,
  paginateStationsReport,
  handlePickerChangeStationsReport,
} from '../../actions';
import { capitalize } from '../../utils/textUtils';
import 'moment/locale/es';

moment.locale('es');

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

const styles = {
  card: {
    borderRadius: '2px',
  },
  selectField: {
    width: '100%',
  },
};

class StationsReport extends Component {
  state = {
    queryDone: false,
    dialogVisible: false,
    selectedOperations: [],
    loanSelfLoanReport: [],
    devolutionSelfLoanReport: [],
    renewSelfLoanReport: [],
  };

  componentDidMount() {
    if (isNull(session)) window.location.reload();
    this.getSelfLoanStationReport();
  }

  componentDidUpdate(prevProps) {
    const { selectedCompany } = this.props;
    if (prevProps.selectedCompany !== selectedCompany) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedOperations: [] });
      this.getSelfLoanStationReport();
    }
  }

  getSelfLoanStationReport = async () => {
    try {
      const { selectedCompany } = this.props;
      const params = {
        company: selectedCompany,
        type: 'monthly',
        operation: '',
        station: 'SELFLOAN'
      }

      params.operation = 'LOAN';
      const loanReportAux = await fetchStationsReportLapse(params);
      const loanReport = loanReportAux.map(item => { return { x: capitalize(moment(item.start_date).format('MMMM Do dddd')), y: item.count } });

      params.operation = 'DEVOLUTION';
      const devolutionReportAux = await fetchStationsReportLapse(params);
      const devolutionReport = devolutionReportAux.map(item => { return { x: capitalize(moment(item.start_date).format('MMMM Do dddd')), y: item.count } });

      params.operation = 'RENEW';
      const renewReportAux = await fetchStationsReportLapse(params);
      const renewReport = renewReportAux.map(item => { return { x: capitalize(moment(item.start_date).format('MMMM Do dddd')), y: item.count } });

      this.setState(
        {
          loanSelfLoanReport: loanReport,
          devolutionSelfLoanReport: devolutionReport,
          renewSelfLoanReport: renewReport,
        }
      );
    }
    catch (error) {
      alert('Hubo un error obteniendo el reporte de esta estación.');
    }
  }

  handlePickerChange = (pickerValue, pickerId) => {
    // console.log(pickerValue, pickerId);
    const { handlePickerChange } = this.props;
    handlePickerChange({ pickerValue, pickerId });
  };

  getStationsReportData = () => {
    const { selectedOperations } = this.state;
    // console.log('selectedOperations', selectedOperations);
    const {
      selectedCompany,
      selectedStation,
      getReport,
      startDateStations,
      endDateStations,
    } = this.props;

    if (!startDateStations) {
      alert('Seleccione una fecha de inicio');
      return;
    }
    if (!endDateStations) {
      alert('Seleccione una fecha de fin');
      return;
    }

    this.setState({ queryDone: true });

    let startDateForEndpoint = moment(startDateStations, 'DD/MM/YYYY').format();
    startDateForEndpoint = startDateForEndpoint.substring(0, 10);

    let endDateForEndpoint = moment(endDateStations, 'DD/MM/YYYY').format();
    endDateForEndpoint = endDateForEndpoint.substring(0, 10);

    const operationsForEndpoint = selectedOperations
      .reduce((accumulator, currentValue) => {
        let operation = '';
        switch (currentValue) {
          case 'Préstamo':
            operation = 'LOAN';
            break;
          case 'Devolución':
            operation = 'DEVOLUTION';
            break;
          case 'Renovación':
            operation = 'RENEW';
            break;
          case 'Login':
            operation = 'LOGIN';
            break;
          default:
            break;
        }
        accumulator.push(operation);
        return accumulator;
      }, [])
      .join('|');
    // console.log('operationsForEndpoint', operationsForEndpoint);
    let params = {
      company: selectedCompany,
      result: 'OK',
      start_at: startDateForEndpoint,
      end_at: endDateForEndpoint,
    };
    if (selectedOperations.length > 0) {
      params = { ...params, operation: operationsForEndpoint };
    }
    if (selectedStation !== 'Todas') {
      params = { ...params, station: `SELFLOAN-${selectedStation}` };
    } else {
      params = { ...params, station: "SELFLOAN" };
    }
    getReport(params);
  };

  handleDataPagination = (startRow, rowsPerPage) => {
    const { paginateReport } = this.props;
    paginateReport({ startRow, rowsPerPage });
  };

  truncateString = (text, truncLength) => {
    return text.length > truncLength ? `${text.substr(0, truncLength - 1)} ...` : text;
  };

  toggleFilterOptionsDialog = value => {
    this.setState({ dialogVisible: value });
  };

  toggleCheckbox = (checked, changeEvent) => {
    // console.log('checked', checked, 'changeEvent', changeEvent.target.id)
    const label = changeEvent.target.id;
    const { selectedOperations } = this.state;
    if (checked) {
      selectedOperations.push(label);
    } else {
      const index = selectedOperations.indexOf(label);
      selectedOperations.splice(index, 1);
    }
    this.setState({ selectedOperations });
    // console.log('selectedOperations', selectedOperations);
  };

  render() {
    const {
      stationsReport,
      slicedStationsReport,
      loadingStationsReport,
      startDateStations,
      endDateStations,
      stations,
      selectedStation,
    } = this.props;
    const { dialogVisible, queryDone, selectedOperations } = this.state;
    const filterOptions = ['Préstamo', 'Devolución', 'Renovación', 'Login'];
    const menuItemsSelfLoan = stations.filter(item => item.includes("SELFLOAN")).map(item => item.split("-")[1]);
    const stationsItems = ['Todas', ...menuItemsSelfLoan];

    return (
      <DefaultLayout>
        {stations.filter(item => item.indexOf('SELFLOAN') !== -1)[0] !== undefined ?
          <Grid>
            <Cell size={12}>
              <ReportsNav />
            </Cell>
            <Cell size={12}>
              <Card style={styles.card}>
                <CardTitle title="Contadores" />
                <CardText>
                  <Grid>
                    <Cell size={12}>
                      {/* <LineSeriesChart
                        title='Contadores'
                        title1='Préstamos'
                        data1={this.state.loanSelfLoanReport}
                        color1='#44aabb'
                        title2='Devoluciones'
                        data2={this.state.devolutionSelfLoanReport}
                        color2='#aa22ff'
                        title3='Renovaciones'
                        data3={this.state.renewSelfLoanReport}
                        color3='#ef7f1a'
                        height={300}
                        margin={{ bottom: 30, left: 60, top: 70 }}
                      /> */}
                      <DoubleBarChart
                        name="Contadores"
                        data1={this.state.loanSelfLoanReport}
                        color1='#44aabb'
                        title1='Préstamos'
                        data2={this.state.devolutionSelfLoanReport}
                        color2='#aa22ff'
                        title2='Devoluciones'
                        data3={this.state.renewSelfLoanReport}
                        color3='#ef7f1a'
                        title3='Renovaciones'
                        height={300}
                        margin={{ bottom: 90, left: 60, top: 70 }}
                      />
                    </Cell>
                  </Grid>
                </CardText>
              </Card>
            </Cell>
            <Cell size={12}>
              <Card style={styles.card}>
                <CardTitle title="Reporte de Estaciones" />
                <CardText>
                  <Card tableCard>
                    {loadingStationsReport && <LinearProgress id="usersProgress" />}
                    <Grid className="grid-example">
                      <Cell size={3}>
                        <p>
                          {`Desde ${stationsReport.length > 0 ? ` -> ${startDateStations}` : ''}`}
                        </p>
                        <DatePicker
                          id="date-picker-auto-ok"
                          label="Fecha"
                          className="md-cell--12"
                          onChange={value => this.handlePickerChange(value, 'startDateStations')}
                          locales="es"
                        />
                      </Cell>
                      <Cell size={3}>
                        <p>{`Hasta ${stationsReport.length > 0 ? ` -> ${endDateStations}` : ''}`}</p>
                        <DatePicker
                          id="date-picker-auto-ok"
                          label="Fecha"
                          className="md-cell--12"
                          onChange={value => this.handlePickerChange(value, 'endDateStations')}
                          locales="es"
                        />
                      </Cell>
                      <Cell size={2}>
                        <p>Sel. Estación</p>
                        <SelectField
                          simplifiedMenu
                          id="stations-select-field"
                          label="Estación"
                          className="md-cell"
                          menuItems={stationsItems}
                          style={{ marginLeft: 0, width: 130 }}
                          value={selectedStation}
                          onChange={value => this.handlePickerChange(value, 'selectedStation')}
                        />
                      </Cell>
                      <Cell size={2}>
                        <p>Filtrar por Operación</p>
                        <p>-</p>
                        <Button raised onClick={() => this.toggleFilterOptionsDialog(true)}>
                          Seleccionar
                        </Button>
                        <DialogContainer
                          id="collections-dialog"
                          visible={dialogVisible}
                          title="Operaciones"
                          onHide={() => this.toggleFilterOptionsDialog(false)}
                          dialogStyle={{ paddingLeft: 15, paddingBottom: 15, width: 500 }}
                        >
                          <List>
                            {filterOptions.map(
                              (label, index) =>
                                label && (
                                  <Checkbox
                                    key={index}
                                    id={`${label}`}
                                    name="operations-checkboxes[]"
                                    label={label}
                                    checked={selectedOperations.indexOf(label) !== -1}
                                    onChange={this.toggleCheckbox}
                                  />
                                )
                            )}
                          </List>
                        </DialogContainer>
                      </Cell>
                      <Cell size={2}>
                        <Button
                          type="submit"
                          className="md-cell--12 md-cell--bottom"
                          onClick={this.getStationsReportData}
                          primary
                          raised
                        >
                          Buscar
                        </Button>
                      </Cell>
                    </Grid>
                    <Grid>
                      {stationsReport.length > 0 && (
                        <ExcelFile
                          filename="ReporteDeEstaciones"
                          element={
                            <FlatOrIconButton raised iconChildren="folder">
                              »Exportar
                            </FlatOrIconButton>
                          }
                        >
                          <ExcelSheet data={stationsReport} name="Reporte de Estaciones">
                            <ExcelColumn label="Fecha" value="Fecha" />
                            <ExcelColumn label="Usuario" value="Usuario" />
                            <ExcelColumn label="Código de Barras" value="Código de Barras" />
                            <ExcelColumn label="Operación" value="Operación" />
                            <ExcelColumn label="Estación" value="Estación" />
                            <ExcelColumn label="Resultado" value="Resultado" />
                            <ExcelColumn label="Mensaje" value="Mensaje" />
                          </ExcelSheet>
                        </ExcelFile>
                      )}
                    </Grid>
                    <DataTable baseId="simple-pagination">
                      {stationsReport.length === 0 ? (
                        <tbody>
                          <tr>
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                              {queryDone ? 'No Hay Registros' : ''}
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <>
                          <TableHeader>
                            <TableRow selectable={false}>
                              {slicedStationsReport.length > 0 &&
                                Object.keys(slicedStationsReport[0]).map((header, index) => (
                                  <TableColumn key={`${header}_${index}`} grow={index === 1}>
                                    {header}
                                  </TableColumn>
                                ))}
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {slicedStationsReport.map((item, index) => (
                              <TableRow key={`${item.id}_${index}`} selectable={false}>
                                {Object.keys(item).map(key => (
                                  <TableColumn key={key}>{item[key]}</TableColumn>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                          <TablePagination
                            rows={stationsReport.length}
                            rowsPerPageLabel="Registros por página"
                            onPagination={this.handleDataPagination}
                          />
                        </>
                      )}
                    </DataTable>
                  </Card>
                </CardText>
              </Card>
            </Cell>
          </Grid>
          :
          <Redirect to={'/reports/persons-count'} />}
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  selectedCompany: state.user.selectedCompany,
  loadingStationsReport: state.stationsReport.loadingStationsReport,
  stationsReport: state.stationsReport.stationsReport,
  slicedStationsReport: state.stationsReport.slicedStationsReport,
  startDateStations: state.stationsReport.startDateStations,
  endDateStations: state.stationsReport.endDateStations,
  stations: state.stations.stations,
  selectedStation: state.stationsReport.selectedStation,
});

const mapDispatchToProps = dispatch => ({
  getReport: params => dispatch(getStationsReport(params)),
  paginateReport: params => dispatch(paginateStationsReport(params)),
  handlePickerChange: params => dispatch(handlePickerChangeStationsReport(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StationsReport);
