import React from 'react';
import { connect } from 'react-redux';
import { List, Divider, ListItem, FontIcon } from 'react-md';

import { hasCompany, hasRole, isAllowed } from '../../services/config/permissions';
import { logoutRequest } from '../../actions';
import NavLink from './navLink';

const styles = {
  list: {
    backgroundColor: 'transparent',
    position: 'sticky',
    top: '6rem',
  },
};

const NavList = ({ userData, selectedCompany, companies, logoutRequest }) => {

  const company = companies.filter(item => selectedCompany === item.id)[0] || [];

  const doLogout = () => {
    logoutRequest();
  }

  return (
    <List style={styles.list}>
      <NavLink label='Inicio' icon='home' to='/' exact={true} />
      {hasCompany(company, ['library']) && <NavLink {...(hasRole(userData, ['user']) && {isRight: isAllowed(userData, ['web_reports'])})} label='Reportes' icon='assignment' to='/reports/encode' exact={true} />}
      {hasCompany(company, ['assets']) && <NavLink label='Activos' icon='verified_user' to='/assets' exact={true} />}
      {hasCompany(company, ['assets']) && <NavLink label='Reporte Activos' icon='verified_user' to='/assets/report' exact={true} />}
      {hasCompany(company, ['assets']) && <NavLink label='Importar Datos' icon='storage' to='/assets/data' exact={true} />}
      {hasCompany(company, ['assets']) && hasRole(userData, ['root', 'admin']) && <NavLink label='Ubicaciones' icon='location_on' to='/assets/locations' exact={true} />}
      <NavLink {...(hasRole(userData, ['user']) && {isRight: isAllowed(userData, ['inventory'])})} label='Inventario' icon='inbox' to='/inventory' exact={true} />
      {hasRole(userData, ['root']) && <NavLink label='Cliente y facturación' icon='receipt_long' to='/billing' exact={true} />}
      <Divider />
      <NavLink {...(hasRole(userData, ['user']) && {isRight: isAllowed(userData, ['profile'])})} label="Mi perfil" icon="person" to="/profile" />
      <Divider />
      <ListItem
        primaryText="Cerrar Sessión"
        leftIcon={<FontIcon>exit_to_app</FontIcon>}
        onClick={() => doLogout()}
      />
    </List>
  );
};

const mapStateToProps = state => ({
  userData: state.user.user,
  selectedCompany: state.user.selectedCompany,
  companies: state.companies.companies
});
const mapDispatchToProps = dispatch => ({
  logoutRequest: () => dispatch(logoutRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavList);