import React, { PureComponent } from 'react';
import { Grid, Cell, Card, CardTitle, CardText } from 'react-md';
import { connect } from 'react-redux';

import { loginRequest } from '../../actions';
import { BlankLayout, LoginForm } from '../../components';

class LoginPage extends PureComponent {
  render() {
    const { requestLogin, loggingIn, loggedIn } = this.props;
    return (
      <BlankLayout>
        <Grid>
          <Cell size={6} offset={3}>
            <center>
              <h2>Bienvenidos a Genia</h2>
            </center>
          </Cell>
        </Grid>
        <Grid>
          <Cell size={4} offset={4}>
            <Card className="md-block-centered">
              <CardTitle title="Ingresa" subtitle="Inicia sesión con tus datos de acceso" />
              <CardText>
                <Grid>
                  <Cell size={10} offset={1}>
                    <LoginForm action={requestLogin} disabled={loggingIn} loading={loggedIn} />
                  </Cell>
                </Grid>
              </CardText>
            </Card>
          </Cell>
        </Grid>
      </BlankLayout>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  loggingIn: state.user.loggingIn,
});

const mapDispatchToProps = dispatch => ({
  requestLogin: credentials => dispatch(loginRequest(credentials)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
