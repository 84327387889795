import React from 'react';
import { List } from 'react-md';
import _ from 'lodash';
import NavLink from './navLink';
import { connect } from 'react-redux';

// const subPages = [
//   {
//     label: 'Conteo de Personas',
//     to: '/reports/persons-count',
//   },
//   {
//     label: 'Asociados',
//     to: '/reports/encode',
//   },
//   {
//     label: 'Autopréstamo',
//     to: '/reports/stations',
//   },
//   {
//     label: 'Devoluciones',
//     to: '/reports/stations-devolutions',
//   },
// ];

const ReportsNav = (props) => {

  const subPages = [
    {
      label: 'Asociados',
      to: '/reports/encode',
    },
  ];

  if (props.cameras.length !== 0) {
    subPages.push(
      {
        label: 'Conteo de Personas',
        to: '/reports/persons-count',
      },
    );
  }

  let isSelfLoan = false;
  let isSelfReturn = false;

  props.stations.forEach(item => {
    if (item.includes('SELFLOAN')) {
      isSelfLoan = true;
    }

    if (item.includes('SELFRETURN')) {
      isSelfReturn = true;
    }
  });

  if (isSelfLoan && (props.userData.role === "admin" || props.userData.status.includes('web_reports'))) {
    subPages.push(
      {
        label: 'Autopréstamo',
        to: '/reports/stations',
      },
    );
  }

  if (isSelfReturn && (props.userData.role === "admin" || props.userData.status.includes('web_reports'))) {
    subPages.push(
      {
        label: 'Devoluciones',
        to: '/reports/stations-devolutions',
      },
    );
  }

  return (
    <List className="md-paper md-paper--1" inline>
      {_.map(subPages, ({ label, to }, key) => (
        <div className="md-list-item" key={key}>
          <NavLink exact to={to} label={label} />
        </div>
      ))}
    </List>
  );
};

const mapStateToProps = state => ({
  stations: state.stations.stations,
  cameras: state.cameras.cameras,
  userData: state.user.user,
});

export default connect(mapStateToProps)(ReportsNav);