/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  LinearProgress,
  Button,
  FontIcon,
  TablePagination,
} from 'react-md';
import TableFilter from './tableFilter';

export default function AssetTable(props) {
  const [slicedData, setSlicedData] = useState([]);
  const [translatedProps, setTranslatedProps] = useState([]);
  const [searchBy, setSearchBy] = useState('Placa');
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const rows = props.assets.length;

  useEffect(() => {
    /* This will be the table order, this function happens to translate the headers */
    /* Campos Fijos
      Código de barras (serial)
      Tag rfid (tag_id)
      Descripción (name)
      Docuemento Dueño (custom_fields/owner_identifier)
      Dueño (custom_fields/owner)
      Costo (custom_fields/cost)
      Fecha de compra (custom_fields/purchase_date)
      Estado (state) [0: Inactivo, 1: Activo]
      Placa (location)
    */
    const localCustomArray = props.customFields.map(custom => {
      return {
        name: custom.name,
        key: custom.key,
      };
    });

    const translated = props.assets.map(asset => {
      const localCustom = {};
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < localCustomArray.length; index++) {
        const element = localCustomArray[index];
        localCustom[element.key] = asset.custom_fields[element.key];
      }
      return {
        CodigoDeBarras: asset.serial,
        Placa: asset.location,
        Nombre: asset.name,
        RFID: asset.tag_id,
        Estado: asset.state,
        Categoria: asset.category,
        custom_fields: localCustom,
        id: asset.id,
      };
    });

    setTranslatedProps(translated);
  }, [props.assets, props.customFields]);

  useEffect(() => setSlicedData(translatedProps.slice(0, 10)), [translatedProps]);

  const refreshData = () => {
    setSlicedData(translatedProps.slice(0, 10));
  };

  const handlePagination = (start, rowsPerPage) => {
    setSlicedData(translatedProps.slice(start, start + rowsPerPage));
  };

  const handleChange = (value, e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    switch (searchBy) {
      case 'Placa':
        props.onSearchAssets(searchText);
        break;
      case 'CodigoDeBarras':
        props.onSearchAssetsSerial(searchText);
        break;
      case 'RFID':
        props.onSearchAssetsId(searchText);
        break;
      default:
        break;
    }
  }

  const handleSelectChange = (value, index, event, details) => {
    setSearchBy(details.value);
    setSearchText('');
    refreshData();
  };
  /*
  let search = (e) => {
    e.preventDefault()
    setLoading(true)
    if (searchText.length > 0) {
      let filtered = translatedProps.filter((el, index) => {
        let customPart = (searchBy.split(".")[1])
        if (searchBy.toLowerCase().includes("custom")) {
          let currentValue = el.custom_fields[customPart]
          return currentValue
            ? currentValue.toLowerCase().includes(searchText.toLowerCase())
            : false
        }
        return el[searchBy]
          ? el[searchBy].toLowerCase().includes(searchText.toLowerCase())
          : false
      })
      setSlicedData(filtered)

    } else {
      refreshData()
    }
    setLoading(false)
  }
*/

  return (
    <Card tableCard>
      {loading && <LinearProgress id="usersProgress" />}
      <TableFilter
        search={handleSearch}
        handleChange={handleChange}
        searchText={searchText}
        searchBy={searchBy}
        handleSelectChange={handleSelectChange}
      />
      <DataTable baseId="dynamic-content-desserts">
        <>
          <TableHeader>
            <TableRow selectable={false}>
              <TableColumn key="actions">Acciones</TableColumn>
              {slicedData[0] &&
                Object.keys(slicedData[0]).map((header, i) => {
                  return header === 'custom_fields' ? (
                    <>
                      {props.customFields.map(custom => (
                        <TableColumn key={custom.name}>{custom.name}</TableColumn>
                      ))}
                    </>
                  ) : (
                    <TableColumn key={i}>{header}</TableColumn>
                  );
                })}
            </TableRow>
          </TableHeader>
          <TableBody>
            {slicedData.map(item => (
              <TableRow selectable={false} key={item.id}>
                <TableColumn key="actions">
                  <div className="buttons__group" style={{ marginBottom: '2rem' }}>
                    <Button onClick={() => props.setForEdit(item)} icon>
                      <FontIcon>edit</FontIcon>
                    </Button>
                  </div>
                </TableColumn>
                {Object.keys(item).map((key, index) =>
                  key === 'custom_fields' ? (
                    <>
                      {Object.keys(item.custom_fields).map((customKey, i) => {
                        return (
                          <TableColumn key={`${customKey}${i}`}>
                            {item.custom_fields[customKey]}
                          </TableColumn>
                        );
                      })}
                    </>
                  ) : key === 'Estado' ? (
                    <TableColumn key={`${key}${index}`}>
                      {item[key] === null
                        ? 'Inactivo'
                        : item[key].toString() === '1'
                        ? 'Activo'
                        : 'Inactivo'}
                    </TableColumn>
                  ) : (
                    <TableColumn key={key}>{item[key]}</TableColumn>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
          <TablePagination rows={rows} rowsPerPageLabel="Filas" onPagination={handlePagination} />
        </>
      </DataTable>
    </Card>
  );
}
