import React from 'react';
import { TextField, Grid, Button, SelectField } from 'react-md';

export default function TableFilter(props) {
  const { search, searchText, handleChange, searchBy, handleSelectChange } = props;

  const handleSubmit = (e) => {
    e.preventDefault();        // Evita que la página se refresque al presionar la tecla Enter.
    search();
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <TextField
          id="filterTable"
          className="md-cell md-cell--bottom"
          label="Buscar"
          value={searchText}
          onChange={handleChange}
        />
        <SelectField
          required
          id="category"
          name="category"
          label="Buscar Por"
          placeholder="Buscar Por"
          className="md-cell md-cell--bottom"
          menuItems={[
            { label: 'Placa', value: 'Placa' },
            { label: 'CodigoDeBarras', value: 'CodigoDeBarras' },
            // { label: 'Nombre', value: 'Nombre' },
            { label: 'RFID', value: 'RFID' },
            // { label: 'Lote', value: 'custom_fields.lot' },
          ]}
          value={searchBy}
          onChange={handleSelectChange}
        />
        <Button type="submit" flat swapTheming primary className="sm-cell sm-cell--bottom">
          Buscar
        </Button>
      </Grid>
    </form>
  );
}