import React, { Component } from 'react';
import { Grid, Cell, Button, FontIcon, TextField, Snackbar, LinearProgress } from 'react-md';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      toasts: [],
      autohide: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addToast = this.addToast.bind(this);
    this.dismissToast = this.dismissToast.bind(this);
  }

  dismissToast = () => {
    const [, ...toasts] = this.state.toasts;
    this.setState({ toasts });
  };

  addToast(text, action, autohide = true) {
    this.setState(state => {
      const toasts = state.toasts.slice();
      toasts.push({ text, action });
      return { toasts, autohide };
    });
  }

  handleSubmit(event) {
    const { action } = this.props;
    const { username, password } = this.state;
    event.preventDefault();
    action({
      username,
      password,
    }).then(response => {
      if (!response) {
        this.addToast('Usuario o contraseña incorrecto');
      }
    });
  }

  handleChange(value, e) {
    this.setState({ [e.target.name]: value });
  }

  render() {
    const { loading, disabled } = this.props;
    const { username, password, toasts, autohide } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        {loading && <LinearProgress />}
        <Grid>
          <Cell size={12}>
            <TextField
              id="loginUsername"
              label="Nombre de usuario"
              name="username"
              value={username}
              onChange={this.handleChange}
              disabled={disabled}
              required
            />
          </Cell>
          <Cell size={12}>
            <TextField
              id="loginPassword"
              label="Contraseña"
              name="password"
              type="password"
              value={password}
              passwordIcon={<FontIcon>remove_red_eye</FontIcon>}
              onChange={this.handleChange}
              disabled={disabled}
              required
            />
          </Cell>
          <Cell size={12}>
            <Button type="submit" disabled={disabled} flat primary swapTheming>
              Iniciar Sesión
            </Button>
          </Cell>
        </Grid>
        <Snackbar
          id="loginForm-snackbar"
          toasts={toasts}
          autohide={autohide}
          onDismiss={this.dismissToast}
        />
      </form>
    );
  }
}

export default LoginForm;
