import { companiesEndpoint, campusEndpoint } from './config/endpoints';
import { optionsGET, optionsPOST, optionsPATCH } from './config/options';

export const fetchCompanies = async () => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(companiesEndpoint(), optionsGET());
    if (response.status === 401) {
      return { status: 'unauthorized' };
    }
    const companies = await response.json();
    return { companies };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};

export const postCompany = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(companiesEndpoint(), optionsPOST(data));
    if (response.status === 401) {
      return { status: 'unauthorized' };
    }
    const company = await response.json();
    return { company };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information from the database: ${err}`);
    throw err;
  }
};

export const patchCompany = async (params, data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(companiesEndpoint(params), optionsPATCH(data));
    if (response.status === 401) {
      return { status: 'unauthorized' };
    }
    const company = await response.json();
    return { company };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to patch the information from the database: ${err}`);
    throw err;
  }
};

export const fetchCampus = async (params) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(campusEndpoint(params), optionsGET());
    if (response.status === 401) {
      return { status: 'unauthorized' };
    }
    const campus = await response.json();
    return campus;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};