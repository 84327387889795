import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DefaultLayout } from '../../../components';
import { Button, Cell, Card, CardTitle, CardText, Grid, TextField, ExpansionList, ExpansionPanel, 
    DataTable, TableHeader, TableBody, TableRow, TableColumn, TablePagination, FontIcon, DialogContainer } from 'react-md';
import { getFields, getFieldDropdown, postFieldDropdown, patchFieldDropdown, deleteFieldDropdown } from '../../../services/assets';

const styles = {
    card: {
        borderRadius: '2px',
    },
    marginButtons: {
        marginTop: '40px',
    },
    expansionStyle: {
        margin: '3px 0px 3px 0px',
        marginLeft: '0px',
    },
    expansionPanelHeader: {
        fontSize: '14px', 
        height: '40px',
        paddingLeft: '10px',
    },
}

function Location (props) {
    const [locations, setLocations] = useState([]);
    const [mainLocationField, setMainLocationField] = useState(undefined);
    const [floorLocationField, setFloorLocationField] = useState(undefined);
    const [roomLocationField, setRoomLocationField] = useState(undefined);

    const [search, setSearch] = useState('');   // Búsqueda

    const [rows, setRows] = useState(0);    // Paginación
    const [sliceData, setSliceData] = useState([]);

    const [mainLocationList, setMainLocationList] = useState([]);
    const [floorLocationList, setFloorLocationList] = useState([]);
    const [roomLocationList, setRoomLocationList] = useState([]);

    const [expandedIndexMain, setExpandedIndexMain] = useState(-1);
    const [expandedIndexFloor, setExpandedIndexFloor] = useState(-1);

    const [mainId, setMainId] = useState(null);     // ids para el method POST
    const [floorId, setFloorId] = useState(null);

    const [showDialog, setShowDialog] = useState(false);    // Modal
    const [operationLabel, setOperationLabel] = useState('');
    const [idControlPatch, setIdControlPatch] = useState(null);   // id para el method PATCH
    const [labelDialog, setLabelDialog] = useState('');

    const [showDialogConfirm, setShowDialogConfirm] = useState(false);    // Modal para la confirmación
    const [idControlRemove, setIdControlRemove] = useState(null);   // id para el method DELETE
    const [labelControlRemove, setLabelControlRemove] = useState(null);

    useEffect(() => {
        getFields({ company: props.selectedCompany })
        .then(response => response.asset)
        .then(response => setLocations(response.filter(item => item.key.includes('location'))))     // setLocations(response.sort((a, b) => a.order > b.order))
        .catch(error => console.log(error));
    }, []);

    useEffect(() => {
        const mainLocationField = locations.filter(item => item.key === 'main_location')[0];
        const floorLocationField = locations.filter(item => item.key === 'floor_location')[0];
        const roomLocationField = locations.filter(item => item.key === 'room_location')[0];

        setMainLocationField(mainLocationField);
        setFloorLocationField(floorLocationField);
        setRoomLocationField(roomLocationField);
    }, [locations]);

    useEffect(() => {
        if(mainLocationField !== undefined) {
            const params = {
                table: mainLocationField.table,
                company: props.selectedCompany,
                type: mainLocationField.key
            }
    
            getFieldDropdown(params)
            .then(response => setMainLocationList(response.asset))  // setMainLocationList(response.asset)
            .catch(error => console.log(error))
        }
    }, [mainLocationField]);

    useEffect(() => {
        setSliceData(mainLocationList.slice(0, 10));
        setRows(mainLocationList.length);
    }, [mainLocationList]);

    const searchFilterFunction = (value) => {
        // Check if searched text is not blank
        if (value) {
          // Inserted text is not blank
          const newData = mainLocationList.filter(function (item) {
            const itemData = item.name
              ? item.name.toUpperCase()
              : ''.toUpperCase();
            const textData = value.toUpperCase();
            return itemData.indexOf(textData) > -1;
          });
          setSliceData(newData);
          setSearch(value);
        } else {
          // Inserted text is blank
          setSliceData(mainLocationList.slice(0, 10));
          setSearch(value);
        }
    }

    const handlePagination = (start, rowsPerPage) => {
        setSliceData(mainLocationList.slice(start, start + rowsPerPage));
    }

    const onExpandedToggleMain = (id, index, expanded) => {
        if(expanded === true && mainLocationField.type !== null) {
            const params = {
                table: floorLocationField.table,
                company: props.selectedCompany,
                type: floorLocationField.key,
                local_id: id,
            }
    
            getFieldDropdown(params)
            .then(response => setFloorLocationList(response.asset))
            .catch(error => console.log(error))
        }

        if(expanded === false) {
            setExpandedIndexMain(-1);
            setMainId(null);
        }
        else {
            setExpandedIndexMain(index);
            setExpandedIndexFloor(-1);
            setMainId(id);
        }
    }

    const onExpandedToggleFloor = (id, index, expanded) => {
        if(expanded === true && floorLocationField.type !== null) {
            const params = {
                table: roomLocationField.table,
                company: props.selectedCompany,
                type: roomLocationField.key,
                local_id: id,
            }
    
            getFieldDropdown(params)
            .then(response => setRoomLocationList(response.asset))
            .catch(error => console.log(error))
        }

        if(expanded === false) {
            setExpandedIndexFloor(-1);
            setFloorId(null);
        }
        else {
            setExpandedIndexFloor(index);
            setFloorId(id);
        }
    }

    const onShowDialog = (operationLabel, label, id) => {
        setShowDialog(true);
        setOperationLabel(operationLabel);
        setLabelDialog(label);

        if(operationLabel === 'Editar')
            setIdControlPatch(id);
    }

    const onHideDialog = () => {
        setShowDialog(false);
    }

    const onShowDialogConfirm = (id, label) => {
        setShowDialogConfirm(true);
        setIdControlRemove(id);
        setLabelControlRemove(label);
    }

    const onHideDialogConfirm = () => {
        setShowDialogConfirm(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const name = event.target.name.value;   // Sacando el value del textField con name = 'name'

        let params = {};
        let data = {};

        switch (operationLabel) {
            case 'Agregar':
                switch (labelDialog) {
                    case 'ubicación':
                        params = {
                            table: mainLocationField.table,
                        }
        
                        data = {
                            company: props.selectedCompany,
                            type: mainLocationField.key,
                            name: name,
                        }
                
                        postFieldDropdown(params, data)
                        .then(response => {
                            const newMainLocationList = [...mainLocationList];
                            newMainLocationList.push(response.asset);
                            setMainLocationList(newMainLocationList);
                            onHideDialog();
                            alert('La ubicación ha sido creada con éxito.');
                        })
                        .catch(error => {
                            console.log(error);
                            alert('Ocurrió un error.');
                        });
                        break;
                    case 'piso':
                        params = {
                            table: floorLocationField.table,
                        }
        
                        data = {
                            company: props.selectedCompany,
                            type: floorLocationField.key,
                            name: name,
                            local_id: mainId,
                        }
                
                        postFieldDropdown(params, data)
                        .then(response => {
                            const newFloorLocationList = [...floorLocationList];
                            newFloorLocationList.push(response.asset);
                            setFloorLocationList(newFloorLocationList);
                            onHideDialog();
                            alert('El piso ha sido creado con éxito.');
                        })
                        .catch(error => {
                            console.log(error);
                            alert('Ocurrió un error.');
                        });
                        break;
                    case 'sala':
                        params = {
                            table: roomLocationField.table,
                        }
        
                        data = {
                            company: props.selectedCompany,
                            type: roomLocationField.key,
                            name: name,
                            local_id: floorId,
                        }
                
                        postFieldDropdown(params, data)
                        .then(response => {
                            const newRoomLocationList = [...roomLocationList];
                            newRoomLocationList.push(response.asset);
                            setRoomLocationList(newRoomLocationList);
                            onHideDialog();
                            alert('La sala ha sido creada con éxito.');
                        })
                        .catch(error => {
                            console.log(error);
                            alert('Ocurrió un error.');
                        });
                        break;
                }
                break;
            case 'Editar':
                switch (labelDialog) {
                    case 'ubicación':
                        params = {
                            table: mainLocationField.table,
                            id: idControlPatch,
                        }
        
                        data = {
                            name: name,
                        }
                
                        patchFieldDropdown(params, data)
                        .then(response => {
                            const asset = response.asset;
                            const newMainLocationList = [...mainLocationList];
                            const index = newMainLocationList.map(item => {return item.id}).indexOf(idControlPatch);
                            newMainLocationList[index].name = asset.name;
                            setMainLocationList(newMainLocationList);
                            onHideDialog();
                            alert('La ubicación ha sido modificada con éxito.');
                        })
                        .catch(error => {
                            console.log(error);
                            alert('Ocurrió un error.');
                        });
                        break;
                    case 'piso':
                        params = {
                            table: floorLocationField.table,
                            id: idControlPatch,
                        }
        
                        data = {
                            name: name,
                        }
                
                        patchFieldDropdown(params, data)
                        .then(response => {
                            const asset = response.asset;
                            const newFloorLocationList = [...floorLocationList];
                            const index = newFloorLocationList.map(item => {return item.id}).indexOf(idControlPatch);
                            newFloorLocationList[index].name = asset.name;
                            setFloorLocationList(newFloorLocationList);
                            onHideDialog();
                            alert('El piso ha sido modificado con éxito.');
                        })
                        .catch(error => {
                            console.log(error);
                            alert('Ocurrió un error.');
                        });
                        break;
                    case 'sala':
                        params = {
                            table: roomLocationField.table,
                            id: idControlPatch,
                        }
        
                        data = {
                            name: name,
                        }
                
                        patchFieldDropdown(params, data)
                        .then(response => {
                            const asset = response.asset;
                            const newRoomLocationList = [...roomLocationList];
                            const index = newRoomLocationList.map(item => {return item.id}).indexOf(idControlPatch);
                            newRoomLocationList[index].name = asset.name;
                            setRoomLocationList(newRoomLocationList);
                            onHideDialog();
                            alert('La sala ha sido modificada con éxito.');
                        })
                        .catch(error => {
                            console.log(error);
                            alert('Ocurrió un error.');
                        });
                        break;
                }
                break;
        }
    }

    const itemRemove = () => {
        let params = {};

        switch (labelControlRemove) {
            case 'ubicación':
                params = {
                    table: mainLocationField.table,
                    id: idControlRemove,
                }

                deleteFieldDropdown(params)
                .then(response => {
                    if(response.ok) {
                        const newMainLocationList = [...mainLocationList];
                        const index = newMainLocationList.map(item => {return item.id}).indexOf(idControlRemove);
                        newMainLocationList.splice(index, 1);
                        setMainLocationList(newMainLocationList);
                        onHideDialogConfirm();
                        alert('La ubicación ha sido eliminada con éxito.');
                    }
                    else {
                        onHideDialogConfirm();
                        alert('Error... no se realizó con éxito. Asegúrate de eliminar primero las subdivisiones.');
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert('Ocurrió un error.');
                });
                break;
            case 'piso':
                params = {
                    table: floorLocationField.table,
                    id: idControlRemove,
                }

                deleteFieldDropdown(params)
                .then(response => {
                    if(response.ok) {
                        const newFloorLocationList = [...floorLocationList];
                        const index = newFloorLocationList.map(item => {return item.id}).indexOf(idControlRemove);
                        newFloorLocationList.splice(index, 1);
                        setFloorLocationList(newFloorLocationList);
                        onHideDialogConfirm();
                        alert('El piso ha sido eliminado con éxito.');
                    }
                    else {
                        onHideDialogConfirm();
                        alert('Error... no se realizó con éxito. Asegúrate de eliminar primero las subdivisiones.');
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert('Ocurrió un error.');
                });
                break;
            case 'sala':
                params = {
                    table: roomLocationField.table,
                    id: idControlRemove,
                }

                deleteFieldDropdown(params)
                .then(response => {
                    if(response.ok) {
                        const newRoomLocationList = [...roomLocationList];
                        const index = newRoomLocationList.map(item => {return item.id}).indexOf(idControlRemove);
                        newRoomLocationList.splice(index, 1);
                        setRoomLocationList(newRoomLocationList);
                        onHideDialogConfirm();
                        alert('La sala ha sido eliminada con éxito.');
                    }
                    else {
                        onHideDialogConfirm();
                        alert('Error... no se realizó con éxito.');
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert('Ocurrió un error.');
                });
                break;
        }
    }

    const RenderFloor = () => {
        return(
            <>
            <Button
                icon
                key="add"
                onClick={() => onShowDialog('Agregar', 'piso')}
                tooltipLabel="Agregar"
                tooltipDelay={300}
            >
                add
            </Button>
            <DataTable baseId="simple-pagination">
                <TableHeader>
                    <TableRow selectable={false}>
                        <TableColumn>Piso</TableColumn>
                        <TableColumn>Acciones</TableColumn>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {floorLocationList.map((item, index)=>
                    <TableRow key={index} selectable={false}>
                        <TableColumn>
                            <ExpansionList className='md-cell md-cell--12' style={styles.expansionStyle}>
                                {floorLocationField.type !== null ? 
                                <ExpansionPanel
                                    style={styles.expansionStyle}
                                    headerStyle={styles.expansionPanelHeader}
                                    label={item.name} 
                                    footer={null}
                                    expanded={expandedIndexFloor === index}
                                    onExpandToggle={(expanded) => onExpandedToggleFloor(item.id, index, expanded)}
                                >
                                    <RenderRoom/>
                                </ExpansionPanel>
                                :
                                <ExpansionPanel
                                    style={styles.expansionStyle}
                                    headerStyle={styles.expansionPanelHeader}
                                    label={item.name} 
                                    footer={null}
                                    expanderIcon={<FontIcon></FontIcon>}
                                    expanded={false}
                                    onExpandToggle={() => {}}
                                >
                                </ExpansionPanel>}
                            </ExpansionList>
                        </TableColumn>
                        <TableColumn style={{verticalAlign: 'top'}}>
                            <Button 
                                icon
                                tooltipLabel="Editar"
                                tooltipDelay={300}
                                onClick={() => onShowDialog('Editar', 'piso', item.id)}
                            >
                                <FontIcon>edit</FontIcon>
                            </Button>
                            <Button 
                                icon
                                tooltipLabel="Eliminar"
                                tooltipDelay={300}
                                onClick = {() => onShowDialogConfirm(item.id, 'piso')}
                            >
                                <FontIcon>delete</FontIcon>
                            </Button>
                        </TableColumn>
                    </TableRow>
                )}
                </TableBody>
            </DataTable>
            </>
        );
    }

    const RenderRoom = () => {
        return(
            <>
            <Button
                icon
                key="add"
                onClick={() => onShowDialog('Agregar', 'sala')}
                tooltipLabel="Agregar"
                tooltipDelay={300}
            >
                add
            </Button>
            <DataTable baseId="simple-pagination">
                <TableHeader>
                    <TableRow selectable={false}>
                        <TableColumn>Sala</TableColumn>
                        <TableColumn>Acciones</TableColumn>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {roomLocationList.map((item, index)=>
                    <TableRow key={index} selectable={false}>
                        <TableColumn>
                            <ExpansionList className='md-cell md-cell--12' style={styles.expansionStyle}> 
                                <ExpansionPanel
                                    style={styles.expansionStyle}
                                    headerStyle={styles.expansionPanelHeader}
                                    label={item.name} 
                                    footer={null}
                                    expanderIcon={<FontIcon></FontIcon>}
                                    expanded={false}
                                    onExpandToggle={() => {}}
                                >
                                </ExpansionPanel>
                            </ExpansionList>
                        </TableColumn>
                        <TableColumn style={{verticalAlign: 'top'}}>
                            <Button 
                                icon
                                tooltipLabel="Editar"
                                tooltipDelay={300}
                                onClick={() => onShowDialog('Editar', 'sala', item.id)}
                            >
                                <FontIcon>edit</FontIcon>
                            </Button>
                            <Button 
                                icon
                                tooltipLabel="Eliminar"
                                tooltipDelay={300}
                                onClick = {() => onShowDialogConfirm(item.id, 'sala')}
                            >
                                <FontIcon>delete</FontIcon>
                            </Button>
                        </TableColumn>
                    </TableRow>
                )}
                </TableBody>
            </DataTable>
            </>
        );
    }

    return(
        <DefaultLayout>
            <Grid>
                <Cell size={12}>
                    <Card style={styles.card}>
                        <CardTitle title="Ubicaciones" />
                        <CardText>
                            <Grid>
                                <Cell size={12}>
                                    <TextField type='search'
                                        id='search'
                                        rightIcon={<FontIcon>search</FontIcon>}
                                        fullWidth={false}
                                        className='search'
                                        onChange={searchFilterFunction}
                                        placeholder="Type Here..."
                                        value={search}
                                    />
                                    <Button
                                        icon
                                        key="add"
                                        onClick={() => onShowDialog('Agregar', 'ubicación')}
                                        tooltipLabel="Agregar Ubicación"
                                        tooltipDelay={300}
                                    >
                                        add
                                    </Button>
                                </Cell>
                                <Cell size={12}>
                                    <DataTable baseId="simple-pagination">
                                        <TableHeader>
                                            <TableRow selectable={false}>
                                                <TableColumn>Ubicación</TableColumn>
                                                <TableColumn>Acciones</TableColumn>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                        {sliceData.map((item, index)=>
                                            <TableRow key={index} selectable={false}>
                                                <TableColumn>
                                                    <ExpansionList className='md-cell md-cell--12' style={styles.expansionStyle}>
                                                        {mainLocationField.type !== null ? 
                                                        <ExpansionPanel
                                                            style={styles.expansionStyle}
                                                            headerStyle={styles.expansionPanelHeader}
                                                            label={item.name}
                                                            footer={null}
                                                            expanded={expandedIndexMain === index}
                                                            onExpandToggle={(expanded) => onExpandedToggleMain(item.id, index, expanded)}
                                                        >
                                                            <RenderFloor/>
                                                        </ExpansionPanel>
                                                        :
                                                        <ExpansionPanel
                                                            style={styles.expansionStyle}
                                                            headerStyle={styles.expansionPanelHeader}
                                                            label={item.name} 
                                                            footer={null}
                                                            expanderIcon={<FontIcon></FontIcon>}
                                                            expanded={false}
                                                            onExpandToggle={() => {}}
                                                        >
                                                        </ExpansionPanel>}
                                                    </ExpansionList>
                                                </TableColumn>
                                                <TableColumn style={{verticalAlign: 'top'}}>
                                                    <Button 
                                                        icon
                                                        tooltipLabel="Editar"
                                                        tooltipDelay={300}
                                                        onClick={() => onShowDialog('Editar', 'ubicación', item.id)}
                                                    >
                                                        <FontIcon>edit</FontIcon>
                                                    </Button>
                                                    <Button 
                                                        icon
                                                        tooltipLabel="Eliminar"
                                                        tooltipDelay={300}
                                                        onClick = {() => onShowDialogConfirm(item.id, 'ubicación')}
                                                    >
                                                        <FontIcon>delete</FontIcon>
                                                    </Button>
                                                </TableColumn>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                        <TablePagination rows={rows} rowsPerPageLabel={'Filas por página'} onPagination={handlePagination} />
                                    </DataTable>
                                </Cell>
                            </Grid>
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
            <DialogContainer
                visible={showDialog}
                title={operationLabel + ' ' + labelDialog}
                onHide={() => onHideDialog()}
                id="add-location"
                width={"25%"}
            >
                <form onSubmit={handleSubmit}>
                    <Grid>
                        <Cell size={12}>
                            <TextField
                                id="name"
                                name="name"
                                label="Nombre"
                                placeholder={'Nombre de ' + labelDialog}
                                required
                            />
                        </Cell>
                        <Cell size={12} style={styles.marginButtons}>
                            <Button
                                raised
                                primary
                                type='submit'
                            >
                                {operationLabel}
                            </Button>
                            <Button
                                raised
                                onClick = {() => onHideDialog()}
                            >
                                Cancelar
                            </Button>
                        </Cell>
                    </Grid>
                </form> 
            </DialogContainer>
            <DialogContainer
                focusOnMount={false}
                visible={showDialogConfirm}
                title='Confirmación'
                onHide={() => onHideDialogConfirm()}
                id="confirm"
                width={"25%"}
                actions={[
                    <Button
                        raised
                        primary
                        onClick={() => itemRemove()}
                    >
                        Si
                    </Button>,
                    <Button
                        raised
                        onClick={() => onHideDialogConfirm()}
                    >
                        No
                    </Button>,
                ]}
            >
                <p>¿Está seguro que desea eliminar?</p>
            </DialogContainer>
        </DefaultLayout>
    );
}

const mapStateToProps = state => ({
    selectedCompany: state.user.selectedCompany,
});

export default connect(mapStateToProps)(Location);