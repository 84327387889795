import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import {
  userReducer,
  companiesReducer,
  collectionsReducer,
  stationsReducer,
  camerasReducer,
  summariesReducer,
  personsCountReportReducer,
  notEncodedReportReducer,
  stationsReportReducer,
} from '../reducers';

const rootReducer = combineReducers({
  user: userReducer,
  companies: companiesReducer,
  collections: collectionsReducer,
  stations: stationsReducer,
  cameras: camerasReducer,
  summaries: summariesReducer,
  personsCountReport: personsCountReportReducer,
  notEncodedReport: notEncodedReportReducer,
  stationsReport: stationsReportReducer,
});

const middlewares = [thunk];

const store = createStore(
  rootReducer,
  //  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
