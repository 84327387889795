export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SELECT_COMPANY = 'SELECT_COMPANY';
export const GET_COMPANIES = 'GET_COMPANIES';

export const GET_COLLECTIONS = 'GET_COLLECTIONS';

export const GET_STATIONS = 'GET_STATIONS';

export const GET_COPIES_SUMMARY = 'GET_COPIES_SUMMARY';
export const LOADING_COPIES_SUMMARY = 'LOADING_COPIES_SUMMARY';
export const COPIES_SUMMARY_ERROR = 'COPIES_SUMMARY_ERROR';

export const GET_PERSONS_COUNTER_SUMMARY = 'GET_PERSONS_COUNTER_SUMMARY';
export const PERSONS_COUNTER_SUMMARY_ERROR = 'PERSONS_COUNTER_SUMMARY_ERROR';
export const LOADING_PERSONS_COUNTER_SUMMARY = 'LOADING_PERSONS_COUNTER_SUMMARY';
export const CLEAN_PERSONS_COUNTER_DATA = 'CLEAN_PERSONS_COUNTER_DATA';

export const GET_PERSONS_COUNT_REPORT = 'GET_PERSONS_COUNT_REPORT';
export const PAGINATE_PERSONS_COUNT_REPORT = 'PAGINATE_PERSONS_COUNT_REPORT';
export const TOGGLE_LOADING_PERSONS_COUNT_REPORT = 'TOGGLE_LOADING_PERSONS_COUNT_REPORT';
export const SET_DATE_TIME_PERSONS_COUNT_REPORT = 'SET_DATE_TIME_PERSONS_COUNT_REPORT';

export const GET_NOT_ENCODED_REPORT = 'GET_NOT_ENCODED_REPORT';
export const PAGINATE_NOT_ENCODED_REPORT = 'PAGINATE_NOT_ENCODED_REPORT';
export const TOGGLE_LOADING_NOT_ENCODED_REPORT = 'TOGGLE_LOADING_NOT_ENCODED_REPORT';
export const HANDLE_PICKER_CHANGE = 'HANDLE_PICKER_CHANGE';

export const GET_STATIONS_ACTIVITY_SUMMARY = 'GET_STATIONS_ACTIVITY_SUMMARY';
export const LOADING_STATIONS_ACTIVITY_SUMMARY = 'LOADING_STATIONS_ACTIVITY_SUMMARY';
export const STATIONS_ACTIVITY_ERROR = 'STATIONS_ACTIVITY_ERROR';

export const GET_CAMERAS_BY_COMPANY = 'GET_CAMERAS_BY_COMPANY';
export const LOADING_CAMERAS_BY_COMPANY = 'LOADING_CAMERAS_BY_COMPANY';
export const CAMERAS_BY_COMPANY_ERROR = 'CAMERAS_BY_COMPANY_ERROR';
export const SELECTED_CAMERA = 'SELECTED_CAMERA';

export const GET_STATIONS_REPORT = 'GET_STATIONS_REPORT';
export const PAGINATE_STATIONS_REPORT = 'PAGINATE_STATIONS_REPORT';
export const TOGGLE_LOADING_STATIONS_REPORT = 'TOGGLE_LOADING_STATIONS_REPORT';
export const HANDLE_PICKER_CHANGE_STATIONS_REPORT = 'HANDLE_PICKER_CHANGE_STATIONS_REPORT';
export const CLEAR_SELECTED_STATION_STATIONS_REPORT = 'CLEAR_SELECTED_STATION_STATIONS_REPORT';
