import { stationsEndpoint } from './config/endpoints';
import { optionsGET } from './config/options';

export const fetchStations = async params => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(stationsEndpoint(params), optionsGET());
    const stations = await response.json();
    return { stations };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};
