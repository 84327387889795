import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    Cell,
    ListItem,
    List,
    Button,
    DialogContainer,
} from 'react-md';

const BillingNotif = (props) => {

    const [visible, setVisible] = useState(false);
    const [servicesOnTimeLeft, setServicesOnTimeLeft] = useState([]);

    useEffect(() => {
        if (props.companies.length !== 0) {
            const company = props.companies.filter(item => item.id === props.selectedCompany)[0];
    
            if (company.custom_fields !== null) {
                const dateStart = new Date();
                const dateEnd = new Date();
                dateEnd.setDate(dateEnd.getDate() + 30);

                if (company.custom_fields.services !== undefined) {
                    const services = company.custom_fields.services;
                    const servicesOnTimeLeft = services.filter(item => item.date_paid === null && (new Date(item.service_end).getTime() >= dateStart.getTime()) && (new Date(item.service_end).getTime() <= dateEnd.getTime()));
    
                    setServicesOnTimeLeft(servicesOnTimeLeft);
                }
            }
        }
    }, [props.companies, props.selectedCompany]);

    useEffect(() => {
        servicesOnTimeLeft.length !== 0 && setVisible(true);
    }, [servicesOnTimeLeft]);

    return (
        <DialogContainer
            focusOnMount={false}
            id='notification'
            visible={visible}
            // actions={actions}
            title='Recordatorio de renovación'
            onHide={() => setVisible(false)}
            dialogStyle={{ paddingLeft: 15, paddingBottom: 15, width: 500 }}
        >
            <Grid>
                <Cell size={12}>
                    <p>Hola,</p>
                    <p>¡Saludos desde Genia Tecnología S.A.S! y gracias por su apoyo continuo.</p>
                    <p>
                        Su suscripción anual del servicio está próxima a vencer. Si desea hacer 
                        cambios a su suscripción, no dude en ponerse en contacto con nuestro equipo de soporte técnico.
                    </p>
                    <p>A continuación se mostrará el detallado del servicio:</p>
                </Cell>
                <Cell size={12}>
                    <List>
                        {servicesOnTimeLeft.map((item, i) => 
                            <ListItem
                                key={i}
                                primaryText={item.description}
                                secondaryText={'Inicio: ' + item.service_start + ' Vencimiento: ' + item.service_end}
                            />
                        )}
                    </List>
                </Cell>
            </Grid>
        </DialogContainer>
    );
}

const mapStateToProps = state => ({
    companies: state.companies.companies,
    selectedCompany: state.user.selectedCompany,
});

export default connect(mapStateToProps)(BillingNotif);