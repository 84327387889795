import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DefaultLayout } from '../../components';
import { Card, CardTitle, CardText, Grid, Cell, Button, FileUpload, LinearProgress, DialogContainer } from 'react-md';
import XLSX from 'xlsx';
import moment from 'moment';
import { postAsset } from '../../services/assets';

const styles = {
    card: {
        borderRadius: '2px',
    },
}

function Data (props) {

    const [files, setFiles] = useState([]);
    const [file, setFile] = useState(null);
    const [fileObject, setFileObject] = useState(null);
    const [showDialogConfirm, setShowDialogConfirm] = useState(false);
    const [fileIndex, setFileIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {   // Asegura que el push de files, se realice en el mismo hilo cada vez que se cargue file
        if (file !== null) {
            const filesAux = [...files, file];
            setFiles(filesAux);
        }
    }, [file]);

    useEffect(() => {
        if (fileObject !== null) {
            postFile();
        }
    }, [fileObject]);

    const handleLoad = (uploadedFile, uploadedData) => {
        const { name, size, type, lastModified } = uploadedFile;
        const file = {
          name,
          size,
          type,
          data: uploadedData,
          lastModified: new Date(lastModified),
        };

        setFile(file);
    };

    const onShowDialogConfirm = (i) => {
        setShowDialogConfirm(true);
        setFileIndex(i);
    }

    const onHideDialogConfirm = () => {
        setShowDialogConfirm(false);
    }

    const handleFileSelect = () => {
        onHideDialogConfirm();

        const file = files[fileIndex];
        
        let blob = new Blob(new Uint8Array(file.data));  //file.data es un ArrayBuffer
        let fileReader = new FileReader();

        setIsLoading(true);

        fileReader.onload = () => {     // Hará que XLSX.read() se ejecute en otro hilo y evitará que genere un bloqueo de espera
            let data = '';
            let bytes = new Uint8Array(file.data);  // file.data es un ArrayBuffer

            for (let i = 0; i < bytes.length; i++) {
                data += String.fromCharCode(bytes[i]);
            }

            let workBook = XLSX.read(data, {
                type: 'binary'
            });

            const fileObject = [];

            workBook.SheetNames.forEach(sheet => {
                let rowObject = XLSX.utils.sheet_to_row_object_array(
                    workBook.Sheets[sheet]
                );
                
                fileObject.push(rowObject);
            });

            setFileObject(fileObject);
            setIsLoading(false);
        }
        fileReader.readAsArrayBuffer(blob);     // Ejecuta el onload del fileReader
    }

    const postFile = () => {
        fileObject[0].map((item) => {     // Mapear sólo la hoja 1 del excel
            const itemArray = Object.entries(item);
            const params = {
                company: props.selectedCompany, // FRONT
                serial: itemArray.filter(item => item[0] === 'Placa')[0][1], // Placa
                tag_id: itemArray.filter(item => item[0] === 'Placa')[0][1], // 0000000000000000 + Placa 
                name: itemArray.filter(item => item[0] === 'Descripción')[0][1], // Descripción
                state: "1", // quemado
                location: itemArray.filter(item => item[0] === 'Placa')[0][1], //Placa
                status_code: 1, // quemado
                custom_fields: {
                    lot: itemArray.filter(item => item[0] === 'Serial S/N:')[0][1], // Serial S/N:
                    user: props.userData.username, // FRONT
                    owner: itemArray.filter(item => item[0] === 'Nombre Dueño')[0][1], // Nombre Dueño
                    asset_type: itemArray.filter(item => item[0] === 'Tipo de Activo')[0][1], // Tipo de Activo
                    classifier: itemArray.filter(item => item[0] === 'Clasificador')[0][1], // Clasificador
                    encoded_at: moment(new Date(), 'YYYY-MM-DD').format().replace('T', ' ').split('-05:00')[0], // Fecha actual del sistema en este formato "2021-03-02T16:30:00"
                    local_status: "Activo de Menor Cuantía", // quemado
                    main_location: "", // Ubicación (sin definir = "")
                    purchase_date: moment(new Date(((itemArray.filter(item => item[0] === 'Fecha de Adquisición')[0][1]) - (25567 + 1)) * 86400 * 1000), 'YYYY-MM-DD').format().split('T')[0], // Fecha de Adquisición (en formato yyyy-mm-dd)
                },
            }

            postAsset(params);
        });

        alert('Se han registrado ' + fileObject[0].length + ' activos en la base de datos.');
    }

    const removeItem = (i) => {
        const filesAux = [...files];
        filesAux.splice(i, 1);
        setFiles(filesAux);
    }

    return (
        <DefaultLayout>
            <Grid>
                <Cell size={12}>
                    <Card style={styles.card}>
                        <CardTitle title="Importar Datos"/>
                        <CardText>
                            <Grid>
                                <Cell size={4}>
                                    <FileUpload
                                        id="multiple-file-upload"
                                        accept={".xlsx, .xls"}
                                        multiple     // booleano que permite seleccionar múltiples archivos
                                        primary
                                        name="multipart-file-upload"
                                        label="Cargar Activos"
                                        onLoad={handleLoad}
                                    />
                                </Cell>
                            </Grid>
                            <Grid>
                                <Cell size={4}>
                                    {files.map((item, i) => {
                                        return (
                                            <Card key={i}>
                                                <Button 
                                                    icon 
                                                    onClick={() => removeItem(i)}
                                                >
                                                    close
                                                </Button>
                                                <CardText>
                                                    <strong>{item.name}</strong>
                                                    <p>{item.size} bytes</p>
                                                </CardText>
                                                <Grid>
                                                    <Cell size={4}>
                                                        <Button
                                                            flat
                                                            primary
                                                            swapTheming
                                                            onClick={() => onShowDialogConfirm(i)}
                                                        >
                                                            Subir
                                                        </Button>
                                                    </Cell>
                                                    <Cell size={8}>
                                                        {isLoading && 
                                                        <div>
                                                            <strong style={{fontSize: 12}}>procesando...</strong>
                                                            <LinearProgress id='dataProgress'/>
                                                        </div>}
                                                    </Cell>
                                                </Grid>
                                            </Card>
                                        );
                                    })}
                                </Cell>
                            </Grid>
                        </CardText>
                    </Card>
                    <DialogContainer
                        focusOnMount={false}
                        visible={showDialogConfirm}
                        title='Confirmación'
                        onHide={() => onHideDialogConfirm()}
                        id="confirm"
                        width={"25%"}
                        actions={[
                            <Button
                                raised
                                primary
                                onClick={() => handleFileSelect()}
                            >
                                Si
                            </Button>,
                            <Button
                                raised
                                onClick={() => onHideDialogConfirm()}
                            >
                                No
                            </Button>,
                        ]}
                    >
                        <p>¿Está seguro que desea enviar este archivo a la base de datos?</p>
                    </DialogContainer>
                </Cell>
            </Grid>
        </DefaultLayout>
    );
}

const mapStateToProps = state => ({
    userData: state.user.user,
    selectedCompany: state.user.selectedCompany,
});

export default connect(mapStateToProps)(Data);