import { GET_COMPANIES, LOGOUT_SUCCESS } from './types';
import { fetchCompanies } from '../services/companies';

export const getCompaniesData = params => {
  return async dispatch => {
    try {
      const { companies = [], status = '' } = await fetchCompanies(params);
      if (status === 'unauthorized') {
        dispatch({ type: LOGOUT_SUCCESS });
        return;
      }
      dispatch({ type: GET_COMPANIES, companies });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`Unable to get the companies from the database: ${err}`);
      throw err;
    }
  };
};
