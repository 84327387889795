/* eslint-disable no-console */
import {
  personsCountReportEndpoint,
  encodeReportEndpoint,
  stationsReportEndpoint,
  stationsReportLapseEndpoint
} from './config/endpoints';
import { optionsGET } from './config/options';

export const fetchPersonsCountReport = async params => {
  try {
    let response = await fetch(personsCountReportEndpoint(params), optionsGET());
    response = response.json();
    return response;
  } catch (err) {
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};

export const fetchNotEncodedReport = async params => {
  //const newParams = { ...params, type: 1 };
  try {
    let response = await fetch(encodeReportEndpoint(params), optionsGET());
    response = response.json();
    return response;
  } catch (err) {
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};

export const fetchStationsReport = async params => {
  try {
    let response = await fetch(stationsReportEndpoint(params), optionsGET());
    response = response.json();
    return response;
  } catch (err) {
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};

export const fetchStationsReportLapse = async params => {
  try {
    let response = await fetch(stationsReportLapseEndpoint(params), optionsGET());
    response = response.json();
    return response;
  } catch (err) {
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};