import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SELECT_COMPANY,
} from '../actions/types';

const user = JSON.parse(localStorage.getItem('user'));
const session = JSON.parse(localStorage.getItem('session'));
const selectedCompany = JSON.parse(localStorage.getItem('selected_company'));
const company = selectedCompany ? selectedCompany.value : 0;
const expire = session ? session.exp : null;
const validSession = user && expire && expire < Date.now();

const initialState = {
  user: validSession ? user : null,
  loggedIn: !!validSession,
  loggingIn: false,
  selectedCompany: company,
  msg: {
    type: null,
    response: null,
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        user: action.payload,
        selectedCompany: action.payload.companies[0],
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        msg: {
          type: LOGIN_FAILURE,
          response: action.payload,
        },
      };
    case LOGOUT_REQUEST:
      return state;
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        user: null,
        selectedCompany: null,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        msg: {
          type: LOGIN_FAILURE,
          response: action.payload,
        },
      };
    case SELECT_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
