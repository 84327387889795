/* eslint-disable no-console */
import {
  totalCopiesSummaryEndpoint,
  totalEncodeSummaryEndpoint,
  totalPersonsCounterSummaryEndpoint,
  stationsActivitySummaryEndpoint,
} from './config/endpoints';

import { optionsGET } from './config/options';

export const getTotalCopiesSummary = async params => {
  try {
    // eslint-disable-next-line no-undef
    let response = await fetch(totalCopiesSummaryEndpoint(params), optionsGET());
    response = response.json();
    return response;
  } catch (err) {
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};

export const getTotalEncodeSummary = async params => {
  try {
    // eslint-disable-next-line no-undef
    let response = await fetch(totalEncodeSummaryEndpoint(params), optionsGET());
    response = response.json();
    return response;
  } catch (err) {
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};

export const getPersonsCounterSummary = async params => {
  try {
    // eslint-disable-next-line no-undef
    let response = await fetch(totalPersonsCounterSummaryEndpoint(params), optionsGET());
    response = response.json();
    return response;
  } catch (err) {
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};

export const getStationsActivitySummary = async params => {
  try {
    // eslint-disable-next-line no-undef
    let response = await fetch(stationsActivitySummaryEndpoint(params), optionsGET());
    response = response.json();
    return response;
  } catch (err) {
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};
