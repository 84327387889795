import { GET_STATIONS } from '../actions/types';

const initialState = {
  stations: [],
};

const stationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATIONS:
      return {
        ...state,
        stations: action.stations,
      };
    default:
      return state;
  }
};

export default stationsReducer;
