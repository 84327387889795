import { TextField, SelectField, DatePicker } from "react-md";
import React, { useState, useEffect } from 'react'
import { getFieldDropdown } from "../../services/assets";

function CustomDropdown(props) {
  
  let [dropdownList, setDropdownList] = useState([]);

  let { field, company } = props;

  useEffect(() => {
    getFieldDropdown({
      table: field.table,
      company: company,
      type: field.key
    })
      .then(resp => {
        setDropdownList(resp.asset)
      })
      .catch(err => console.log(err))
  }, []);

  return (
    <SelectField
      autoComplete
      keyboardMatchingTimeout={1500}
      key={props.index}
      id={props.id}
      label={field.name}
      lineDirection="center"
      className="md-cell md-cell--bottom"
      required={field.required}
      onChange={props.onChange}
      value={props.value}
      menuItems={dropdownList.map(el => {
        return {
          label: el.name,
          value: el.name,
        };
      })}
    />
  )
}

function CustomDatetime(props) {
  let { field, id } = props

  return (
    <DatePicker
      displayMode='portrait'
      id={id}
      label={field.name}
      name={field.name}
      lineDirection='center'
      className='md-cell md-cell--bottom'
      required={field.required}
      onChange={(value, dateValue, event) => props.onChange(value, dateValue, event, id)}
      value={props.value}
    />
  )
}

export default function CustomFields(props) {
  
  return (
    <>
      {props.customFields.map((field, index) => {
        if (field.table) {
          switch (field.key) {
            case 'main_location':
              return null;
            case 'floor_location':
              return null;
            case 'room_location':
              return null;
            default: 
              return <CustomDropdown
              value={props.otherCustomFields[field.key]}
              onChange={props.setOtherCustomFieldsDropdown}
              id={field.key}
              field={field}
              key={index}
              company={props.company}
              floorLocationById={props.floorLocationById}
              />
          }
        } else {
          switch (field.data_type) {
            case "string":
              return <TextField
                key={index}
                id={field.key}
                label={field.name}
                value={props.otherCustomFields[field.key] || ""}
                onChange={props.setOtherCustomFields}
                lineDirection='center'
                className='md-cell md-cell--bottom'
                required={field.required}
              />
            case "datetime":
              return <CustomDatetime
                value={props.otherCustomFields[field.key]}
                onChange={props.setOtherCustomFieldsDate}
                id={field.key}
                field={field}
                key={index}
                company={props.company}
              />
            default:
              return <TextField
                key={index}
                id={field.key}
                label={field.name}
                value={props.otherCustomFields[field.key] || ""}
                onChange={props.setOtherCustomFields}
                lineDirection='center'
                className='md-cell md-cell--bottom'
                required={field.required}
              />
          }
        }
      }
      )}
    </>
  )
}