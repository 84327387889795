import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Cell,
  Card,
  CardTitle,
  CardText,
  DataTable,
  Grid,
  LinearProgress,
  DatePicker,
  TimePicker,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  TablePagination,
  SelectField
} from 'react-md';
import ReactExport from 'react-data-export';

import { isNull } from 'util';
import moment from 'moment';
import { session } from '../../services/config/options';
import { DefaultLayout, ReportsNav, FlatOrIconButton, DoubleBarChart } from '../../components';
import {
  getPersonsCountReport,
  paginatePersonsCountReport,
  setDateAndTimePersonsCountReport,
  fetchingCamerasByCompany,
  selectCamera,
  fetchingPersonsCounterSummary
} from '../../actions';

import 'moment/locale/es';

moment.locale('es');

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

const styles = {
  card: {
    borderRadius: '2px',
  },
  selectField: {
    marginLeft: 50,
    width: 100,
  },
  selectField2: {
    marginLeft: 50,
    width: 300,
  },
};

class PersonsCountReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryDone: false,
      pickerMenuItems: [
        {
          value: 1,
          label: 'Mensual',
          name: 'monthly',
        },
        {
          value: 2,
          label: 'Semanal',
          name: 'weekly',
        },
      ],
      menuItemSelected: 1,
    }
  }

  componentDidMount() {
    if (isNull(session)) {
      window.location.reload();
    } else {
      this.getCamerasList();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedCompany,
      selectedCamera,
    } = this.props;
    if (prevProps.selectedCompany !== selectedCompany) {
      this.getCamerasList();
    }
    if (prevProps.selectedCamera !== selectedCamera) {
      this.getPersonsCounterList();
    }
  }

  getCamerasList = async () => {
    const { selectedCompany, getCameras } = this.props;
    if (!selectedCompany) {
      return;
    }
    try {
      await getCameras();
      this.getPersonsCounterList();
    } catch (err) {
      alert('Hubo un error obteniendo la lista de cámaras de esta universidad.');
    }
  };

  _onChangePicker = value => {
    const { cameras } = this.props;
    this.setState(
      prevState => {
        return {
          ...prevState,
          menuItemSelected: value,
        };
      },
      () => {
        if (cameras.length > 0) this.getPersonsCounterList();
      }
    );
  };

  getPersonsCounterList = async () => {
    const { selectedCompany } = this.props;
    if (!selectedCompany) {
      return;
    }

    try {
      const { getPersonsCounterSummary, cameras, selectedCamera } = this.props;
      const { pickerMenuItems, menuItemSelected } = this.state;
      const found = pickerMenuItems.find(element => {
        return element.value === menuItemSelected;
      });
      const cameraName = cameras.find(camera => {
        return camera.value === selectedCamera;
      });
      if (cameraName) {
        await getPersonsCounterSummary({ type: found.name, camera: cameraName.label });
      }
    } catch (err) {
      alert('Hubo un error obteniendo la información del conteo de personas.');
    }
  };

  handleDatePickerChange = (pickerValue, pickerId) => {
    const { setTimeAndDate } = this.props;
    setTimeAndDate({ pickerValue, pickerId });
  };

  getPersonsCountReportData = () => {
    const {
      selectedCompany,
      getPersonsCountReportConnect,
      startDatePersonsCount,
      startTimePersonsCount,
      endDatePersonsCount,
      endTimePersonsCount,
      cameras,
      selectedCamera
    } = this.props;

    const camera = cameras.filter(item => item.value === selectedCamera)[0];

    if (!startDatePersonsCount) {
      alert('Seleccione una fecha de inicio');
      return;
    }
    if (!startTimePersonsCount) {
      alert('Seleccione una hora de inicio');
      return;
    }
    if (!endDatePersonsCount) {
      alert('Seleccione una fecha de fin');
      return;
    }
    if (!endTimePersonsCount) {
      alert('Seleccione una hora de fin');
      return;
    }

    this.setState({ queryDone: true });

    let startDateForEndpoint = moment(
      `${startDatePersonsCount} ${startTimePersonsCount}`,
      'DD/MM/YYYY hh:mm'
    ).format();
    startDateForEndpoint = startDateForEndpoint.substring(0, 19).replace('T', '%20');

    let endDateForEndpoint = moment(
      `${endDatePersonsCount} ${endTimePersonsCount}`,
      'DD/MM/YYYY hh:mm'
    ).format();
    endDateForEndpoint = endDateForEndpoint.substring(0, 19).replace('T', '%20');

    const params = {
      company: selectedCompany,
      start_at: startDateForEndpoint,
      end_at: endDateForEndpoint,
      camera_name: camera.label
    };
    getPersonsCountReportConnect(params);
  };

  handleDataPagination = (startRow, rowsPerPage) => {
    const { paginatePersonsCountReportConnect } = this.props;
    paginatePersonsCountReportConnect({ startRow, rowsPerPage });
  };

  render() {
    const {
      personsCountReport,
      slicedPersonsCountReport,
      loadingPersonsCountReport,
      startDatePersonsCount,
      startTimePersonsCount,
      endDatePersonsCount,
      endTimePersonsCount,
      cameras,
      selectedCamera,
      selectACamera,
      inData,
      outData,
    } = this.props;
    const { 
      queryDone,
      pickerMenuItems,
      menuItemSelected 
    } = this.state;
    return (
      <DefaultLayout>
        <Grid>
          <Cell size={12}>
            <ReportsNav />
          </Cell>
          <Cell size={12}>
            <Card style={styles.card}>
              <CardTitle title="Contador de Personas" />
              <CardText>
                <Cell size={12}>
                  <SelectField
                    simplifiedMenu
                    id="type-select-field"
                    label="Tipo de gráfico"
                    placeholder="Tipo de gráfico"
                    menuItems={pickerMenuItems}
                    value={menuItemSelected}
                    // eslint-disable-next-line no-underscore-dangle
                    onChange={value => this._onChangePicker(value)}
                    style={styles.selectField}
                  />
                  <SelectField
                    simplifiedMenu
                    id="type-select-field"
                    label="Selecciona una cámara"
                    placeholder="Selecciona una cámara"
                    menuItems={cameras}
                    value={selectedCamera}
                    onChange={value => selectACamera(value)}
                    style={styles.selectField2}
                  />
                </Cell>
                {/* {loadingPersonsCounterSummary && <LinearProgress id="loanProgress" />} */}
                <DoubleBarChart
                  //clearData={loadingPersonsCounterSummary}
                  name="Fecha"
                  data1={inData}
                  color1="#134074"
                  title1="Entradas"
                  data2={outData}
                  color2="#21C590"
                  title2="Salidas"
                  height={300}
                  margin={{ bottom: 90, left: 60, top: 70 }}
                />
              </CardText>
            </Card>
          </Cell>
          <Cell size={12}>
            <Card style={styles.card}>
              <CardTitle title="Reporte de Conteo de Personas" />
              <CardText>
                <Cell>
                  <SelectField
                          simplifiedMenu
                          id="type-select-field"
                          label="Selecciona una cámara"
                          placeholder="Selecciona una cámara"
                          menuItems={cameras}
                          value={selectedCamera}
                          onChange={value => selectACamera(value)}
                          style={styles.selectField2}
                  />
                </Cell>
                <Card tableCard>
                  {loadingPersonsCountReport && <LinearProgress id="usersProgress" />}
                  <Grid className="grid-example">
                    <Cell size={5}>
                      <p>
                        {`Desde ${
                          personsCountReport.length > 0
                            ? ` -> ${startDatePersonsCount} ${startTimePersonsCount}`
                            : ''
                        }`}
                      </p>
                      <DatePicker
                        id="date-picker-auto-ok"
                        label="Fecha"
                        className="md-cell--12"
                        onChange={value =>
                          this.handleDatePickerChange(value, 'startDatePersonsCount')
                        }
                        locales="es"
                      />
                      <TimePicker
                        id="appointment-time-auto"
                        label="Hora"
                        className="md-cell--12"
                        onChange={value =>
                          this.handleDatePickerChange(value, 'startTimePersonsCount')
                        }
                        locales="es"
                      />
                    </Cell>
                    <Cell size={5}>
                      <p>
                        {`Hasta ${
                          personsCountReport.length > 0
                            ? ` -> ${endDatePersonsCount} ${endTimePersonsCount}`
                            : ''
                        }`}
                      </p>
                      <DatePicker
                        id="date-picker-auto-ok"
                        label="Fecha"
                        className="md-cell--12"
                        onChange={value =>
                          this.handleDatePickerChange(value, 'endDatePersonsCount')
                        }
                        locales="es"
                      />
                      <TimePicker
                        id="appointment-time-auto"
                        label="Hora"
                        className="md-cell--12"
                        onChange={value =>
                          this.handleDatePickerChange(value, 'endTimePersonsCount')
                        }
                        locales="es"
                      />
                    </Cell>
                    <Cell size={2}>
                      <Button
                        type="submit"
                        className="md-cell--12 md-cell--bottom"
                        onClick={this.getPersonsCountReportData}
                        primary
                        raised
                      >
                        Buscar
                      </Button>
                    </Cell>
                  </Grid>
                  <Grid>
                    {personsCountReport.length > 0 && (
                      <ExcelFile
                        filename="ConteoDePersonas"
                        element={
                          <FlatOrIconButton raised iconChildren="folder">
                            »Exportar
                          </FlatOrIconButton>
                        }
                      >
                        <ExcelSheet data={personsCountReport} name="Conteo de Personas">
                          <ExcelColumn label="Inicio" value="Inicio" />
                          <ExcelColumn label="Fin" value="Fin" />
                          <ExcelColumn label="Entraron" value="Entraron" />
                          <ExcelColumn label="Salieron" value="Salieron" />
                        </ExcelSheet>
                      </ExcelFile>
                    )}
                  </Grid>
                  <DataTable baseId="simple-pagination">
                    {personsCountReport.length === 0 ? (
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'center', padding: '10px' }}>
                            {queryDone ? 'No Hay Registros' : ''}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <>
                        <TableHeader>
                          <TableRow selectable={false}>
                            {slicedPersonsCountReport.length > 0 &&
                              Object.keys(slicedPersonsCountReport[0]).map((header, index) => (
                                <TableColumn key={`${header}_${index}`}>{header}</TableColumn>
                              ))}
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {slicedPersonsCountReport.map((item, index) => (
                            <TableRow key={`${item.id}_${index}`} selectable={false}>
                              {Object.keys(item).map(key => (
                                <TableColumn key={key}>{item[key]}</TableColumn>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                        <TablePagination
                          rows={personsCountReport.length}
                          rowsPerPageLabel="Registros por página"
                          onPagination={this.handleDataPagination}
                        />
                      </>
                    )}
                  </DataTable>
                </Card>
              </CardText>
            </Card>
          </Cell>
        </Grid>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  selectedCompany: state.user.selectedCompany,
  cameras: state.cameras.cameras,
  selectedCamera: state.cameras.selectedCamera,
  loadingPersonsCountReport: state.personsCountReport.loadingPersonsCountReport,
  personsCountReport: state.personsCountReport.personsCountReport,
  slicedPersonsCountReport: state.personsCountReport.slicedPersonsCountReport,
  startDatePersonsCount: state.personsCountReport.startDatePersonsCount,
  startTimePersonsCount: state.personsCountReport.startTimePersonsCount,
  endDatePersonsCount: state.personsCountReport.endDatePersonsCount,
  endTimePersonsCount: state.personsCountReport.endTimePersonsCount,
  inData: state.summaries.inData,
  outData: state.summaries.outData,
});

const mapDispatchToProps = dispatch => ({
  getPersonsCountReportConnect: params => dispatch(getPersonsCountReport(params)),
  paginatePersonsCountReportConnect: params => dispatch(paginatePersonsCountReport(params)),
  setTimeAndDate: params => dispatch(setDateAndTimePersonsCountReport(params)),
  getCameras: () => dispatch(fetchingCamerasByCompany()),
  selectACamera: value => dispatch(selectCamera({ selectedCamera: value })),
  getPersonsCounterSummary: params => dispatch(fetchingPersonsCounterSummary(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonsCountReport);
