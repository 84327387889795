import { inventoryEndpoint, inventorySummaryEndpoint, inventoryDeleteEndpoint, inventoryTotalEndpoint } from './config/endpoints'
import { optionsGET, optionsDELETE } from './config/options'

export const getInventories = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(inventoryEndpoint(data), optionsGET(data))
    if (response.status === 401) {
      return { status: 'unauthorized' }
    }
    const asset = await response.json()
    return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const getInventorySummary = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(inventorySummaryEndpoint(data), optionsGET(data))
    if (response.status === 401) {
      return { status: 'unauthorized' }
    }
    const asset = await response.json()
    return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const getInventoryTotal = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(inventoryTotalEndpoint(data), optionsGET(data))
    if (response.status === 401) {
      return { status: 'unauthorized' }
    }
    const asset = await response.json()
    return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const deleteInventorySummary = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(inventoryDeleteEndpoint(data), optionsDELETE(data))
    if (response.status === 401) {
      return { status: 'unauthorized' }
    }
    const asset = await response.json()
    return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}
