import {
  GET_COPIES_SUMMARY,
  LOADING_COPIES_SUMMARY,
  COPIES_SUMMARY_ERROR,
  GET_PERSONS_COUNTER_SUMMARY,
  PERSONS_COUNTER_SUMMARY_ERROR,
  LOADING_PERSONS_COUNTER_SUMMARY,
  CLEAN_PERSONS_COUNTER_DATA,
  GET_STATIONS_ACTIVITY_SUMMARY,
  LOADING_STATIONS_ACTIVITY_SUMMARY,
  STATIONS_ACTIVITY_ERROR,
} from '../actions/types';

const initialState = {
  totalCopies: [],
  notEncodedCopies: [],
  loadingCopiesSummary: false,
  copiesSummaryError: false,
  inData: [],
  outData: [],
  loadingPersonsCounterSummary: false,
  personsCounterError: false,
  stationsActivity: [],
  loadingStationsActivitySummary: false,
  stationsActivityError: false,
};

const summariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COPIES_SUMMARY:
      return {
        ...state,
        totalCopies: action.totalCopies,
        notEncodedCopies: action.notEncodedCopies,
        loadingCopiesSummary: false,
        copiesSummaryError: false,
      };
    case LOADING_COPIES_SUMMARY:
      return {
        ...state,
        loadingCopiesSummary: true,
      };
    case COPIES_SUMMARY_ERROR:
      return {
        ...state,
        loadingCopiesSummary: false,
        copiesSummaryError: action.copiesSummaryError,
      };
    case GET_PERSONS_COUNTER_SUMMARY:
      return {
        ...state,
        inData: action.inData,
        outData: action.outData,
        loadingPersonsCounterSummary: false,
        personsCounterError: false,
      };
    case LOADING_PERSONS_COUNTER_SUMMARY:
      return {
        ...state,
        loadingPersonsCounterSummary: true,
      };
    case PERSONS_COUNTER_SUMMARY_ERROR:
      return {
        ...state,
        loadingPersonsCounterSummary: false,
        personsCounterError: action.personsCounterError,
      };
    case CLEAN_PERSONS_COUNTER_DATA:
      return {
        ...state,
        inData: [],
        outData: [],
        loadingPersonsCounterSummary: false,
        personsCounterError: false,
      };
    case GET_STATIONS_ACTIVITY_SUMMARY:
      return {
        ...state,
        stationsActivity: action.stationsActivity,
        loadingStationsActivitySummary: false,
        stationsActivityError: false,
      };
    case LOADING_STATIONS_ACTIVITY_SUMMARY:
      return {
        ...state,
        loadingStationsActivitySummary: true,
      };
    case STATIONS_ACTIVITY_ERROR:
      return {
        ...state,
        loadingStationsActivitySummary: false,
        stationsActivityError: action.stationsActivityError,
      };
    default:
      return state;
  }
};

export default summariesReducer;
