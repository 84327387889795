// eslint-disable-next-line no-undef
export const session = JSON.parse(localStorage.getItem('session'));

const headers = jwt => ({
  'Content-Type': 'application/json',
  Authorization: jwt ? `Bearer ${JSON.parse(localStorage.getItem('session')).jwt}` : null,  //por defecto Bearer ${session.jwt}
});

export const optionsGET = (jwt = true) => ({
  method: 'GET',
  headers: headers(jwt),
});

export const optionsPOST = (body, jwt = true) => ({
  method: 'POST',
  headers: headers(jwt),
  body: JSON.stringify(body),
});

export const optionsPUT = (body, jwt = true) => ({
  method: 'PUT',
  headers: headers(jwt),
  body: JSON.stringify(body),
});

export const optionsPATCH = (body, jwt = true) => ({
  method: 'PATCH',
  headers: headers(jwt),
  body: JSON.stringify(body),
});

export const optionsDELETE = (jwt = true) => ({
  method: 'DELETE',
  headers: headers(jwt),
});
