import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';

import { getReport } from '../../../services/assets';
import {
    Cell, Card, DatePicker, CardTitle, Grid,
    Button
} from 'react-md';
import moment from 'moment';

import 'moment/locale/es';
import ReactExport from "react-data-export";

import PaginationTable from '../../../components/tables/pagination_table';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
moment.locale('es');

export const UserReport = () => {

    const { loggedIn, userData, selectedCompany } = useSelector(state => ({
        loggedIn: state.user.loggedIn,
        userData: state.user.user,
        selectedCompany: state.user.selectedCompany || 13
    }));

    let [isLoading, setIsLoading] = useState(false);
    let [messageFetch, setMessageFetch] = useState('');
    let [report, setReport] = useState([]);
    let [encodedReportParams, setencodedReportParams] = useState({});

    // TODO: loading
    // TODO: No se encontraron registros

    // =======================================================
    /// 
    // =======================================================
    const getReportFromApi = () => {
        setIsLoading(true);
        let startDateForEndpoint = moment(
            encodedReportParams.start_at,
            'YYYY-MM-DD'
        ).format();
        let endDateForEndpoint = moment(
            encodedReportParams.end_at,
            'YYYY-MM-DD'
        ).format();
        getReport({
            company: selectedCompany,
            start_at: startDateForEndpoint.split("T")[0],
            end_at: endDateForEndpoint.split("T")[0],
        })
            .then(resp => {
                if( resp.success ) {
                    setMessageFetch('');
                    setReport(resp.data);
                }else {
                    setReport([]);
                    setMessageFetch('No hay registros');
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err.message);
                setMessageFetch('No hay registros');
                setIsLoading(false);
                alert(err.message);
            });
    }

    // =======================================================
    /// 
    // =======================================================
    const handleStartDateChange = (value, dateValue, event) => {
        setencodedReportParams({ ...encodedReportParams, start_at: dateValue })
    }

    // =======================================================
    /// 
    // =======================================================
    const handleEndDateChange = (value, dateValue, event) => {
        setencodedReportParams({ ...encodedReportParams, end_at: dateValue })
    }

    return (
        <Cell size={12}>
            <Card>
                <CardTitle title="Consolidado de Asociaciones por Usuario" />
                {
                    isLoading && (<LinearProgress color="primary" />)
                }
                <Grid>
                    <Cell size={3}>
                        <DatePicker
                            label="Fecha de inicio"
                            fullWidth={false}
                            id={'encodedReportParams_start_at'}
                            value={encodedReportParams.start_at}
                            onChange={handleStartDateChange}
                        />
                    </Cell>
                    <Cell size={3}>
                        <DatePicker
                            label="Fecha final"
                            fullWidth={false}
                            id={'encodedReportParams_end_at'}
                            value={encodedReportParams.end_at}
                            onChange={handleEndDateChange}
                        />
                    </Cell>
                    <Cell size={3}>
                        <Button
                            type="submit"
                            flat
                            className="md-cell md-cell--bottom"
                            primary
                            swapTheming
                            onClick={getReportFromApi}
                        >
                            Ver
                    </Button>
                    </Cell>
                </Grid>
                {report.length > 0 &&
                    <ExcelFile
                        filename={`Reporte por usuario`}
                        element={<Button
                            raised
                            primary
                            swapTheming
                        >
                            Descargar
                        </Button>}>
                        <ExcelSheet data={report} name={`Reporte por usuario`}>
                            <ExcelColumn label="Usuario" value="user" />
                            <ExcelColumn label="Estado" value="state" />
                            <ExcelColumn label="Número" value="count" />
                        </ExcelSheet>
                    </ExcelFile>
                }

                <PaginationTable
                    rows={report}
                    countRows={report.length}
                    title="Reporte usuarios"
                    headCells={[
                        {id: 'user', numeric: false, disablePadding: false, label: 'Usuario' },
                        {id: 'count', numeric: false, disablePadding: false, label: 'Cantidad' },
                        {id: 'state', numeric: false, disablePadding: false, label: 'Estado' },
                    ]}
                    fields={['user', 'count', 'state']}
                />
            </Card>
        </Cell>
    );
};