import React from 'react';
import { connectAdvanced } from 'react-redux';
import { Button } from 'react-md';

const FlatOrIconButton = ({ mobile, children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Button primary icon={mobile} tooltipLabel={mobile ? children : null} {...props}>
    {mobile ? null : children}
  </Button>
);

export default connectAdvanced(() => (state, props) => ({ ...props }))(FlatOrIconButton);
