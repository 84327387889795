import React, { Component } from 'react';
import {
  Card,
  CardTitle,
  CardText,
  Grid,
  Cell,
  TextField,
  LinearProgress,
  Button,
  FontIcon,
  SelectField,
} from 'react-md';

import userService from '../../services/users';
import { DefaultLayout } from '../../components';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      userLoading: false,
    };

    this.getSystemUser = this.getSystemUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getSystemUser();
  }

  getSystemUser() {
    this.setState({ userLoading: true });
    return userService
      .getSystemUserProfile()
      .then(response => {
        this.setState({
          userLoading: false,
          user: true,
          systemUserUsername: response.username,
          systemUserFirstName: response.first_name,
          systemUserLastName: response.last_name,
          systemUserEmail: response.email,
          // systemUserRole: response.role,
        });
      })
      .catch(() => {
        this.setState({ userLoading: false });
      });
  }

  handleChange(value, e) {
    this.setState({ [e.target.name]: value });
  }

  // handleSelectChange(value, index, event, details) {
  //   this.setState({ [details.name]: value });
  // }

  handleSubmit(event) {
    const {
      systemUserUsername,
      // systemUserRole,
      systemUserFirstName,
      systemUserLastName,
      systemUserEmail,
      systemUserPassword,
    } = this.state;
    event.preventDefault();
    this.setState({ userLoading: true });
    return userService
      .updateSystemUserProfile({
        username: systemUserUsername,
        status: 'active',
        // role: systemUserRole,
        first_name: systemUserFirstName,
        last_name: systemUserLastName,
        email: systemUserEmail,
        password: systemUserPassword,
      })
      .then(() => {
        this.setState({
          userLoading: false,
        });
        this.getSystemUser();
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        this.setState({ userLoading: false });
      });
  }

  render() {

    const {
      userLoading,
      user,
      systemUserUsername,
      addUserLoading,
      systemUserFirstName,
      systemUserLastName,
      systemUserEmail,
      systemUserPassword,
      // systemUserRole,
    } = this.state;

    return (
      <DefaultLayout>
        <Card>
          <CardTitle title="Mi perfil" />
          <CardText>
            {userLoading && <LinearProgress id="devicesProgress" />}
            {!userLoading && user && (
              <form onSubmit={this.handleSubmit}>
                <Grid>
                  <TextField
                    id="systemUserUsername"
                    name="systemUserUsername"
                    label="Nombre de usuario"
                    lineDirection="center"
                    placeholder="Ingrese el nombre de usuario"
                    className="md-cell md-cell--bottom"
                    value={systemUserUsername}
                    onChange={this.handleChange}
                    disabled={addUserLoading}
                  />
                  <TextField
                    id="systemUserFirstName"
                    name="systemUserFirstName"
                    label="Nombre"
                    lineDirection="center"
                    placeholder="Ingrese el nombre"
                    className="md-cell md-cell--bottom"
                    value={systemUserFirstName}
                    onChange={this.handleChange}
                    disabled={addUserLoading}
                  />
                  <TextField
                    id="systemUserLastName"
                    name="systemUserLastName"
                    label="Apellido"
                    lineDirection="center"
                    placeholder="Ingrese el apellido"
                    className="md-cell md-cell--bottom"
                    value={systemUserLastName}
                    onChange={this.handleChange}
                    disabled={addUserLoading}
                  />
                  <TextField
                    id="systemUserEmail"
                    name="systemUserEmail"
                    label="Email"
                    lineDirection="center"
                    placeholder="Ingrese la dirección de email"
                    className="md-cell md-cell--bottom"
                    value={systemUserEmail}
                    onChange={this.handleChange}
                    disabled={addUserLoading}
                  />
                  <TextField
                    id="systemUserPassword"
                    name="systemUserPassword"
                    label="Contraseña"
                    type="password"
                    passwordIcon={<FontIcon>remove_red_eye</FontIcon>}
                    className="md-cell md-cell--bottom"
                    value={systemUserPassword}
                    onChange={this.handleChange}
                    disabled={addUserLoading}
                  />
                  {/* <SelectField
                    id="systemUserRole"
                    name="systemUserRole"
                    label="Rol"
                    placeholder="Seleccione rol de usuario"
                    className="md-cell md-cell--bottom"
                    menuItems={[
                      {
                        label: 'Usuario',
                        value: 'user',
                      },
                      {
                        label: 'Administrador',
                        value: 'admin',
                      },
                    ]}
                    value={systemUserRole}
                    onChange={this.handleSelectChange}
                    disabled={addUserLoading}
                  /> */}
                  <Cell size={12}>
                    <Button type="submit" disabled={addUserLoading} flat primary swapTheming>
                      Actualizar información
                    </Button>
                  </Cell>
                </Grid>
              </form>
            )}
          </CardText>
        </Card>
      </DefaultLayout>
    );
  }
}

export default Profile;
