import React, { Component } from 'react';
import { SelectField, Toolbar, Chip, Avatar } from 'react-md';

const styles = {
  container: {
    alignItems: 'center',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  brand: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: '2rem',
    color: 'white',
    verticalAlign: 'middle',
  },
  selectField: {
    marginLeft: 30,
    width: 350,
  },
};

class Header extends Component {
  state = {
    image: '',
  };

  componentDidUpdate(prevProps) {
    const { pickerMenuItems, pickerValue } = this.props;
    if (pickerValue !== prevProps.pickerValue || pickerMenuItems !== prevProps.pickerMenuItems) {
      this.setImageUrl();
    }
  }

  setImageUrl = () => {
    const { pickerMenuItems, pickerValue } = this.props;
    const selectedCompany = pickerMenuItems.find(company => {
      return company.value === pickerValue;
    });
    const imageUrl =
      selectedCompany && selectedCompany.custom_fields
        ? selectedCompany.custom_fields.header_logo
        : '';
    this.setState({ image: imageUrl });
  };

  render() {
    const {
      pickerDisable = false,
      pickerMenuItems = [],
      pickerValue = 0,
      userData = { first_name: '', last_name: '' },
      pickerOnChange = () => {},
    } = this.props;

    const { image } = this.state;

    const textAvatar =
      userData.first_name && userData.last_name
        ? `${userData.first_name[0]}${userData.last_name[0]}`
        : 'GB';

    return (
      <Toolbar fixed colored style={styles.container} zDepth={1}>
        <img src="/images/logo2.png" alt="Genia" style={{ verticalAlign: 'middle' }} />
        <span style={styles.brand}> </span>
        {pickerMenuItems && (
          <SelectField
            simplifiedMenu
            disabled={pickerDisable}
            id="company-select-field"
            label="Institución"
            placeholder="Universidad"
            menuItems={pickerMenuItems}
            value={pickerValue}
            onChange={pickerOnChange}
            style={styles.selectField}
          />
        )}
        <Chip
          style={{
            verticalAlign: 'middle',
            alignSelf: 'center',
            marginLeft: 'auto',
            marginRight: 20,
          }}
          avatar={<Avatar>{textAvatar}</Avatar>}
          label={`${userData.first_name || ''} ${userData.last_name || ''}`}
        />
        <div>
          <img
            src={image}
            alt=""
            style={{
              height: 55,
              marginLeft: 7,
            }}
          />
        </div>
      </Toolbar>
    );
  }
}

export default Header;