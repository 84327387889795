/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  LoginPage,
  HomePage,
  ProfilePage,
  NotEncodedReportPage,
  PersonsCountReportPage,
  StationsReportPage,
  StationsDevolutionsReportPage
} from './pages';
import Assets from './pages/assets';
import AssetsReport from './pages/assets/report';
import Data from './pages/assets/data';
import Location from './pages/assets/locations/location';
import Inventory from './pages/inventory';
import Billing from './pages/billing/Billing';
import {NewReportsPage} from './pages/assets/NewReportsPage';

import { hasCompany, hasRole, isAllowed } from './services/config/permissions';

const App = ({ loggedIn, userData }) => {
  // eslint-disable-next-line no-console
  console.log('userData', userData);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={localProps =>
        loggedIn === true ? <Component {...localProps} /> : <Redirect to="/login" />
      }
    />
  );

  const GuestRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={localProps =>
        loggedIn === false ? <Component {...localProps} /> : <Redirect to="/" />
      }
    />
  );

  const NoMatch = ({ location }) => (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );

  return (
    <main>
      <Switch>
        <GuestRoute exact path="/login" component={LoginPage} />
        <PrivateRoute exact path="/" component={HomePage} />
        <PrivateRoute exact path="/reports/" component={PersonsCountReportPage} />
        <PrivateRoute exact path="/reports/persons-count" component={PersonsCountReportPage} />
        <PrivateRoute exact path="/reports/encode" component={NotEncodedReportPage} />
        <PrivateRoute exact path="/reports/stations" component={StationsReportPage} />
        <PrivateRoute exact path="/reports/stations-devolutions" component={StationsDevolutionsReportPage} />
        <PrivateRoute exact path="/profile" component={ProfilePage} />
        <PrivateRoute exact path="/assets" component={Assets} />
        <PrivateRoute exact path="/assets/data" component={Data} />
        {/* <PrivateRoute exact path="/assets/report" component={AssetsReport} /> */}
        <PrivateRoute exact path="/assets/report" component={NewReportsPage} />
        {userData && hasRole(userData, ['root', 'admin']) && <PrivateRoute exact path="/assets/locations" component={Location} />}
        {userData && hasRole(userData, ['root']) && <PrivateRoute exact path="/billing" component={Billing} />}
        <PrivateRoute exact path="/inventory" component={Inventory} />
        <Route component={NoMatch} />
      </Switch>
    </main>
  );
};

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  userData: state.user.user,
});

export default withRouter(connect(mapStateToProps)(App));
