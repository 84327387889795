import React, { Component } from 'react';
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  Hint,
  LabelSeries,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
} from 'react-vis';
import 'react-vis/dist/style.css';

class DoubleBarChart extends Component {
  state = {
    event1: null,
    event2: null,
    event3: null,
    serie1: null,
    serie2: null,
    serie3: null,
  };

  componentDidUpdate(prevProps) {
    const { clearData } = this.props;
    if (prevProps.clearData !== clearData) {
      this.setState({
        event1: null,
        event2: null,
        event3: null,
        serie1: null,
        serie2: null,
        serie3: null,
      });
    }
  }

  render() {
    const {
      data1,
      data2,
      data3,
      height,
      margin,
      title1,
      color1,
      title2,
      color2,
      title3,
      color3,
      name,
    } = this.props;
    const { event1, event2, event3, serie1, serie2, serie3 } = this.state;

    const dataList = [
      { data: data1, title: title1, color: color1, serie: serie1 },
      { data: data2, title: title2, color: color2, serie: serie2 },
      { data: data3, title: title3, color: color3, serie: serie3 },
    ];
    const minYValues = [];
    const maxYValues = [];
    const labelsList = [];
    const items = [];
    const values = {};

    dataList.forEach((item, index) => {
      if (!item.data) {
        dataList.splice(index, 1);
      }
      else {
        const yValues = [];
        const labels = {
          color: item.color,
          data: [],
        };

        item.data.forEach(data => {
          yValues.push(data.y);
          labels.data.push({ x: data.x, y: data.y, xOffset: index > 0 ? 10 : -5, yOffset: -5, rotation: -90 });
        });

        minYValues.push(Math.min(...yValues));
        maxYValues.push(Math.max(...yValues));
        labelsList.push(labels);

        items.push({ title: item.title, color: item.color });

        if (item.serie) {
          values[item.title] = item.serie.y;
        }
      }
    });

    const lowerYValue = Math.max(...minYValues);
    const upperYLimit = Math.max(...maxYValues) * 1.2;
    const bottomYLimit = lowerYValue >= 0 ? 0 : lowerYValue * 1.2;

    return (
      <FlexibleXYPlot
        height={height}
        margin={margin}
        yType="linear"
        yDomain={[bottomYLimit, upperYLimit]}
        xType="ordinal"
        animation
      >
        <HorizontalGridLines />
        <VerticalGridLines />
        {dataList.map((item, i) =>
          <VerticalBarSeries
            key={i + 1}
            yDomanin={[0, 20000]}
            data={item.data}
            color={item.color}
            onNearestX={datapoint => {
              this.setState({ [`serie${i + 1}`]: datapoint });
            }}
            onValueMouseOver={(datapoint, event) => {
              this.setState({ [`serie${i + 1}`]: datapoint, [`event${i + 1}`]: event });
            }}
            onValueMouseOut={() => {
              this.setState({ [`event${i + 1}`]: null });
            }}
          />
        )}
        <XAxis tickLabelAngle={-40} />
        <DiscreteColorLegend
          style={{
            position: 'absolute',
            alignSelf: 'center',
            left: '42%',
            top: '-10px',
          }}
          orientation="horizontal"
          items={items}
        />
        <YAxis />
        {(event1 || event2 || event3) && (
          <Hint
            xType="literal"
            yType="literal"
            getX={d => d.x}
            getY={d => d.y}
            value={values}
            style={{ fontSize: 14, position: 'absolute', left: '0px', top: '0px', bottom: '20px' }}
          />
        )}
        {labelsList.map((item, i) =>
          <LabelSeries
            key={i + 1}
            animation
            data={item.data}
            getLabel={d => d.y}
            labelAnchorX="start"
            labelAnchorY="text-after-edge"
            style={{ fill: 'gray', stroke: item.color, strokeWidth: 0.5 }}
          />
        )}
      </FlexibleXYPlot>
    );
  }
}

export default DoubleBarChart;
