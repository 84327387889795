import {
  GET_STATIONS_REPORT,
  PAGINATE_STATIONS_REPORT,
  TOGGLE_LOADING_STATIONS_REPORT,
  HANDLE_PICKER_CHANGE_STATIONS_REPORT,
  CLEAR_SELECTED_STATION_STATIONS_REPORT,
} from '../actions/types';

const initialState = {
  loadingStationsReport: false,
  stationsReport: [],
  slicedStationsReport: [],
  startDateStations: '',
  endDateStations: '',
  selectedStation: 'Todas',
};

const stationsReportReducer = (state = initialState, action) => {
  const { type, payload, startRow, rowsPerPage, pickerId, pickerValue } = action;
  switch (type) {
    case GET_STATIONS_REPORT:
      return {
        ...state,
        stationsReport: payload,
        slicedStationsReport: payload.slice(0, 10),
        loadingStationsReport: false,
      };
    case PAGINATE_STATIONS_REPORT:
      return {
        ...state,
        slicedStationsReport: state.stationsReport.slice(startRow, startRow + rowsPerPage),
      };
    case TOGGLE_LOADING_STATIONS_REPORT:
      return {
        ...state,
        loadingStationsReport: payload,
      };
    case HANDLE_PICKER_CHANGE_STATIONS_REPORT:
      return {
        ...state,
        [pickerId]: pickerValue,
      };
    case CLEAR_SELECTED_STATION_STATIONS_REPORT:
      return {
        ...state,
        selectedStation: 'Todas',
      };
    default:
      return state;
  }
};

export default stationsReportReducer;
