import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Snackbar } from 'react-md';

const BillingToast = (props) => {

    const [servicesExpired, setServicesExpired] = useState([]);

    useEffect(() => {
        if (props.companies.length !== 0) {
            const company = props.companies.filter(item => item.id === props.selectedCompany)[0];
    
            if (company.custom_fields !== null) {
                if (company.custom_fields.services !== undefined) {
                    const dateCurrent = new Date();
                    const services = company.custom_fields.services;

                    const servicesExpired = services.map(item => {
                        if (item.date_paid === null && (new Date(item.service_end).getTime() < dateCurrent.getTime())) {
                            return {
                                text: `Su suscripción anual del servicio '${item.description}' ha vencido el '${item.service_end}'`,
                                action: 'Ocultar',
                            }
                        }
                    });
    
                    setServicesExpired(servicesExpired);
                }
            }
        }
    }, [props.companies, props.selectedCompany]);

    const dismissToast = () => {
        const [, ...toasts] = servicesExpired;
        setServicesExpired(toasts);
    }

    return (
        <Snackbar
            id="snackbar"
            toasts={servicesExpired}
            autohide={false}
            onDismiss={dismissToast}
        />
    );
}

const mapStateToProps = state => ({
    companies: state.companies.companies,
    selectedCompany: state.user.selectedCompany,
});

export default connect(mapStateToProps)(BillingToast);