import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Cell,
  Card,
  CardTitle,
  CardText,
  CardActions,
  LinearProgress,
  SelectField,
  List,
  Checkbox,
  Button,
  DialogContainer,
  SelectionControlGroup,
  Avatar,
  ListItem,
  FontIcon,
} from 'react-md';
import { isNull } from 'util';
import { DefaultLayout, DoubleBarChart, LineSeriesChart, DynamicLineSeriesChart, BillingNotif } from '../../components';

import { session } from '../../services/config/options';
import { getAppHealth } from '../../services/amazon';
import {
  selectCamera,
  fetchingCamerasByCompany,
  fetchingCopiesSummary,
  fetchingPersonsCounterSummary,
  cleanPersonsCounterData,
  fetchingStationsActivitySummary
} from '../../actions';
import { fetchCampus } from '../../services/companies';
import { fetchStationsReportLapse } from '../../services/reports';
import { getTotalCopiesSummary } from '../../services/summaries';
import { getCurrentMonth } from '../../utils/moment';
import { capitalize } from '../../utils/textUtils';
import moment from 'moment';

const styles = {
  card: {
    alignItems: 'center',
    borderRadius: '2px',
  },
  cellContainer: {
    alignItems: 'center',
  },
  stationInfoContainer: {
    textAlign: 'center',
  },
  textTitle: {
    fontSize: 16,
    textAlign: 'center',
  },
  textSubtitle: {
    color: '#888',
    fontSize: 13,
    textAlign: 'center',
  },
  img: {
    alignSelf: 'center',
  },
  rightColumn: {
    backgroundColor: 'red',
    alignSelf: 'flex-end',
  },
  text: {
    color: '#888',
    fontSize: 9,
    textAlign: 'center',
  },
  numberText: {
    color: '#888',
    fontSize: 16,
    textAlign: 'left',
  },
  selectField: {
    marginLeft: 50,
    width: 100,
  },
  selectField2: {
    marginLeft: 50,
    width: 200,
  },
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartWidth1: 480,
      chartWidth2: 1000, //por defecto 1090
      modules: {},
      appHealthList: [],
      moduleRegistersDataChart: [],
      pickerMenuItems: [
        {
          value: 1,
          label: 'Mensual',
          name: 'monthly',
        },
        {
          value: 2,
          label: 'Semanal',
          name: 'weekly',
        },
      ],
      menuItemSelected: 1,
      marginLegend1: '350px',
      marginLegend2: '700px', //por defecto 970px
      dialogVisible: false,
      dialogCampusVisible: false,
      campusList: [],
      collectionsCampusList: [],
      totalCollections: [],
      notEncodedCollections: [],
      selectedCampusCheckbox: 'allCampus',
      selectedCheckboxes: [],
      totalCopiesArray: [],
      notEncodedCopiesArray: [],
      loanSelfLoanReport: [],
      devolutionSelfLoanReport: [],
      renewSelfLoanReport: [],
      selectedSelfLoan: 'Todas',
      menuItemsSelfLoan: [],
      loanSelfReturnReport: [],
      devolutionSelfReturnReport: [],
      renewSelfReturnReport: [],
      selectedSelfReturn: 'Todas',
      menuItemsSelfReturn: [],
      booleanLoan: false,
      booleanReturn: false,
      isAssets: false,
      labelButton: '',
      idModal: '',
      titleModal: '',
      listItems: [],
      peopleEntryAverage: 0,
      peopleDeparturesAverage: 0,
    }

  }
  i = 0

  componentDidMount() {
    if (isNull(session)) {
      window.location.reload();
    } else {
      this.getAppHealthList();
      this.getCamerasList();
      this.getCopiesSummaryList();
      // this.getStationsActivityList();
      this.getCampusList();
      this.getSelfLoanStationReport();
      this.getSelfReturnStationReport();
      this.getMenuItemsStations();
      this.getLocations();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      collections,
      totalCopies,
      notEncodedCopies,
      selectedCompany,
      selectedCamera,
      cleanDataPersonsCounter,
      companies,
      inData,
      outData,
      stations,
    } = this.props;
    if (prevProps.companies !== companies) {
      this.getAssetsValue();
    }
    if (prevProps.selectedCompany !== selectedCompany) {
      cleanDataPersonsCounter();
      this.cleanDataModules();
      this.getAppHealthList();
      this.getCamerasList();
      this.getCopiesSummaryList();
      // this.getStationsActivityList();
      this.getCampusList();
      this.setState({ selectedCampusCheckbox: 'allCampus' });
      this.getSelfLoanStationReport();
      this.getSelfReturnStationReport();
      this.getAssetsValue();
      this.getLocations();
    }
    if (prevProps.selectedCamera !== selectedCamera) {
      this.getPersonsCounterList();
    }
    if (prevProps.totalCopies !== totalCopies || prevProps.notEncodedCopies !== notEncodedCopies) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => {
        return {
          ...prevState,
          totalCopiesArray: totalCopies,
          notEncodedCopiesArray: notEncodedCopies,
        };
      });
    }
    if (prevProps.collections !== collections) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => {
        return {
          ...prevState,
          selectedCheckboxes: collections, //collections por defecto
        };
      });
    }
    if (prevProps.inData !== inData) {
      this.getPeopleEntryAverage();
    }
    if (prevProps.outData !== outData) {
      this.getPeopleDeparturesAverage();
    }
    if (prevProps.stations !== stations) {
      this.getMenuItemsStations();
    }

  }

  cleanDataModules = () => {
    this.setState({
      modules: {},
      appHealthList: [],
      moduleRegistersDataChart: [],
    });
  }

  getAppHealthList = async () => {
    const { selectedCompany } = this.props;
    var today = Math.round(new Date().getTime() / 1000);
    const params = {
      company: selectedCompany,
      // limit: 40,
      // start_at: 1624597200,
      start_at: today - 622800,   //ver registros desde la última semana (+ GMT-5) en segundos.
      indexforward: false
    }

    const response = await getAppHealth(params);

    if (response.success) {
      this.setState({ appHealthList: response.data }, () => this.getModules());
    }
  }

  getModules = () => {
    const { companies, selectedCompany } = this.props;
    const company = companies.filter(item => item.id === selectedCompany)[0];
    if (company) {
      if (company.custom_fields !== null) {
        if (company.custom_fields.modules_enabled !== undefined) {
          var today = Math.round(new Date().getTime() / 1000);

          const modulesList = Object.entries(company.custom_fields.modules_enabled);
          const modulesEnabled = this.state.appHealthList.filter(register => modulesList.some(module => register.app === module[0])).reduce((groups, item) => {
            const value = item['app'];
            const module = modulesList.filter(item => item[0] === value)[0][1];
            if (item.status_datetime > (today - 21600)) {


              groups[value] = groups[value] || { app: item.app, module, statusAcc: 0, count: 0, average: 0, color: '' };
              groups[value].statusAcc += item.status;
              groups[value].count += 1;
              groups[value].average = groups[value].statusAcc / groups[value].count;


              if (item.status_datetime > (today - 21600)) {
                if (groups[value].average > 6) {

                  groups[value].color = 'red';
                }
                else if (groups[value].average > 4 && groups[value].average <= 6) {
                  groups[value].color = 'yellow';
                }
                else {
                  groups[value].color = 'green';
                }

              }
            }
            return groups;



          }, {});

          const modulesDisabled = modulesList.filter(module => !Object.entries(modulesEnabled).some(modEn => module[0] === modEn[0])).reduce((groups, item) => {
            groups[item[0]] = { app: item[0], module: item[1], statusAcc: null, count: null, average: null, color: 'gray' };

            return groups;
          }, {});

          const modules = Object.assign(modulesEnabled, modulesDisabled);

          this.setState({ modules });
        }
      }
    }
  }

  getModuleRegistersChart = (app, module, color, count) => {
    const moduleRegisters = this.state.appHealthList.filter(item => item.app === app);

    const data = moduleRegisters.map(item => {
      return {
        x: moment(item.status_datetime * 1000).add(5, 'hours').format("DD MMM YYYY hh:mm a"),
        y: item.status,
        message: item.message,
      }

    });

    const moduleRegistersDataChart = [
      {
        name: module,
        data: data,
        title: 'Estado',
        color,
        count,
      }
    ];

    this.setState({ moduleRegistersDataChart });

  }

  getAssetsValue = () => {
    const { companies, selectedCompany } = this.props;

    let company = companies.filter((element) => {
      if (element.id === selectedCompany) {
        return true;
      }
      return false;
    })

    if (company[0] && company[0].type === "assets") {
      this.setState({ isAssets: true });
    }
    else if (company[0] && company[0].type === "library") {
      this.setState({ isAssets: false });
    }
  }

  getLocations = async () => {
    const { selectedCompany } = this.props;
    const params = {
      company: selectedCompany,
    }

    try {
      const data = await getTotalCopiesSummary(params);
      const locations = data.map(item => item.location);
      this.displayTypeInterface(locations);
    } catch (error) {
      console.log(error);
    }
  }

  displayTypeInterface = (locations) => {
    const { collections } = this.props;

    if (this.state.isAssets) {
      this.setState(prevState => {
        return {
          ...prevState,
          selectedCheckboxes: locations, //collections por defecto
        };
      });

      this.setState({
        labelButton: 'Filtrar por Ubicación',
        idModal: 'locations-dialog',
        titleModal: 'Ubicaciones',
        listItems: locations,
      });
    }
    else {
      this.setState(prevState => {
        return {
          ...prevState,
          selectedCheckboxes: collections, //collections por defecto
        };
      });

      this.setState({
        labelButton: 'Filtrar por Colecciones', //valores por defecto y usados en bibliotecas
        idModal: 'collections-dialog',
        titleModal: 'Colecciones',
        listItems: collections,
      });
    }
  }

  getTotalsCopies = () => {
    let totalCopies = 0;
    let totalNotEncodedCopies = 0;
    let totalEncodedCopies = 0;

    this.state.totalCopiesArray.map((ele) => { totalCopies = totalCopies + ele.y });
    this.state.notEncodedCopiesArray.map((ele) => { totalNotEncodedCopies = totalNotEncodedCopies + ele.y });

    totalEncodedCopies = totalCopies - totalNotEncodedCopies; // Calculando el total de Asociados

    const totals = {
      totalCopies: totalCopies,
      totalNotEncodedCopies: totalNotEncodedCopies,
      totalEncodedCopies: totalEncodedCopies
    }

    return totals;
  }

  getCampusList = async () => {
    const { selectedCompany } = this.props;
    const params = {
      company: selectedCompany
    }
    try {
      const campusList = await fetchCampus(params);
      this.setState({ campusList: campusList });
    } catch (error) {
      alert('Hubo un error obteniendo la lista de sedes de esta universidad.');
    }
  }

  getCollectionsCampusList = async (selectedCampus) => {
    const { selectedCompany } = this.props;
    const params = {
      company: selectedCompany,
      campus: selectedCampus
    }
    try {
      const collectionsCampusList = await getTotalCopiesSummary(params);
      this.setState({ collectionsCampusList: collectionsCampusList });
    } catch (error) {
      alert('Hubo un error obteniendo la lista de colecciones de esta sede universitaria.');
    }
  }

  getCamerasList = async () => {
    const { selectedCompany, getCameras } = this.props;
    if (!selectedCompany) {
      return;
    }
    try {
      await getCameras();
      this.getPersonsCounterList();
    } catch (err) {
      alert('Hubo un error obteniendo la lista de cámaras de esta universidad.');
    }
  };

  getMenuItemsStations = () => {
    const menuItemsSelfLoan = this.props.stations.filter(item => item.includes("SELFLOAN")).map(item => item.split("-")[1]);
    const menuItemsSelfReturn = this.props.stations.filter(item => item.includes("SELFRETURN")).map(item => item.split("-")[1]);
    this.setState(prevState => {
      return {
        ...prevState,
        menuItemsSelfLoan: ['Todas'].concat(menuItemsSelfLoan),
        menuItemsSelfReturn: ['Todas'].concat(menuItemsSelfReturn),
      };
    });
  }

  getSelfLoanStationReport = async (value = 'Todas') => {
    try {
      const { selectedCompany } = this.props;
      const params = {
        company: selectedCompany,
        type: 'monthly',
      }

      if (value !== 'Todas') {
        params.station = `SELFLOAN-${value}`;
      } else {
        params.station = "SELFLOAN";
      }

      params.operation = 'LOAN';
      const loanReportAux = await fetchStationsReportLapse(params);
      const loanReport = loanReportAux.map(item => { return { x: capitalize(moment(item.start_date).format('MMMM Do dddd')), y: item.count } });

      params.operation = 'DEVOLUTION';
      const devolutionReportAux = await fetchStationsReportLapse(params);
      const devolutionReport = devolutionReportAux.map(item => { return { x: capitalize(moment(item.start_date).format('MMMM Do dddd')), y: item.count } });

      params.operation = 'RENEW';
      const renewReportAux = await fetchStationsReportLapse(params);
      const renewReport = renewReportAux.map(item => { return { x: capitalize(moment(item.start_date).format('MMMM Do dddd')), y: item.count } });

      if ((loanReport.length !== 0) || (devolutionReport.length !== 0) || (renewReport.length !== 0)) {
        this.setState({ booleanLoan: true });
      }

      this.setState(
        {
          loanSelfLoanReport: loanReport,
          devolutionSelfLoanReport: devolutionReport,
          renewSelfLoanReport: renewReport,
          selectedSelfLoan: value,
        }
      );
    }
    catch (error) {
      alert('Hubo un error obteniendo el reporte de esta estación.');
    }
  }

  getSelfReturnStationReport = async (value = 'Todas') => {
    try {
      const { selectedCompany } = this.props;
      const params = {
        company: selectedCompany,
        type: 'monthly',
      }

      if (value !== 'Todas') {
        params.station = `SELFRETURN-${value}`;
      } else {
        params.station = "SELFRETURN";
      }

      params.operation = 'LOAN';
      const loanReportAux = await fetchStationsReportLapse(params);
      const loanReport = loanReportAux.map(item => { return { x: capitalize(moment(item.start_date).format('MMMM Do dddd')), y: item.count } });

      params.operation = 'DEVOLUTION';
      const devolutionReportAux = await fetchStationsReportLapse(params);
      const devolutionReport = devolutionReportAux.map(item => { return { x: capitalize(moment(item.start_date).format('MMMM Do dddd')), y: item.count } });

      params.operation = 'RENEW';
      const renewReportAux = await fetchStationsReportLapse(params);
      const renewReport = renewReportAux.map(item => { return { x: capitalize(moment(item.start_date).format('MMMM Do dddd')), y: item.count } });

      if ((loanReport.length !== 0) || (devolutionReport.length !== 0) || (renewReport.length !== 0)) {
        this.setState({ booleanReturn: true });
      }

      this.setState(
        {
          loanSelfReturnReport: loanReport,
          devolutionSelfReturnReport: devolutionReport,
          renewSelfReturnReport: renewReport,
          selectedSelfReturn: value,
        }
      );
    }
    catch (error) {
      alert('Hubo un error obteniendo el reporte de esta estación.');
    }
  }

  toggleCollectionsDialog = value => {
    this.setState({ dialogVisible: value });
  };

  toggleCampusDialog = value => {
    this.setState({ dialogCampusVisible: value });
  }

  toggleCheckbox = (checked, changeEvent) => {
    const { selectedCheckboxes } = this.state;
    const selectedCheckboxesArray = selectedCheckboxes.slice();
    const label = changeEvent.target.id;

    if (checked) {
      selectedCheckboxesArray.push(label);
    }
    else {
      const index = selectedCheckboxesArray.indexOf(label);
      index !== -1 && selectedCheckboxesArray.splice(index, 1);
    }

    this.setState({ selectedCheckboxes: selectedCheckboxesArray }, () => this.refreshChartDistribution());
  };

  toggleCampusCheckbox = async (label) => {
    try {
      if (label === 'allCampus') {
        const { totalCopies, notEncodedCopies } = this.props;
        const collectionsEnables = totalCopies.map(value => value.x);

        this.setState({ selectedCampusCheckbox: label });

        this.setState(prevState => {
          return {
            ...prevState,
            selectedCheckboxes: collectionsEnables,
            totalCopiesArray: totalCopies,
            notEncodedCopiesArray: notEncodedCopies
          };
        });
      }
      else {
        await this.getCollectionsCampusList(label);

        const collectionsEnables = this.state.collectionsCampusList.map(value => value.location);
        const totalCollections = this.state.collectionsCampusList.map(value => {
          return {
            x: value.location,
            y: value.total
          }
        });
        const notEncodedCollections = this.state.collectionsCampusList.map(value => {
          return {
            x: value.location,
            y: value.not_encoded
          }
        });

        this.setState(
          {
            selectedCampusCheckbox: label,
            totalCollections: totalCollections,
            notEncodedCollections: notEncodedCollections
          }
        );

        this.setState(prevState => {
          return {
            ...prevState,
            selectedCheckboxes: collectionsEnables,
            totalCopiesArray: totalCollections,
            notEncodedCopiesArray: notEncodedCollections
          };
        });
      }
    } catch (error) {
      alert('Hubo un error obteniendo la lista de colecciones de esta universidad.');
    }
  };

  toggleSelectAllCheckboxes = (checked) => {
    let collectionsEnables = [];

    if (this.state.selectedCampusCheckbox === 'allCampus') {
      const { totalCopies } = this.props;
      collectionsEnables = totalCopies.map(value => value.x);
    }
    else {
      collectionsEnables = this.state.collectionsCampusList.map(value => value.location);
    }

    if (checked) {
      this.setState({ selectedCheckboxes: collectionsEnables }, () => this.refreshChartDistribution());
    }
    else {
      this.setState({ selectedCheckboxes: [] }, () => this.refreshChartDistribution());
    }
  }

  refreshChartDistribution = () => {
    if (this.state.selectedCampusCheckbox === 'allCampus') {
      const { totalCopies, notEncodedCopies } = this.props;
      this.chartDistributionParams(totalCopies, notEncodedCopies);
    }
    else {
      const { totalCollections, notEncodedCollections } = this.state;
      this.chartDistributionParams(totalCollections, notEncodedCollections);
    }
  }

  chartDistributionParams = (totalCopies, notEncodedCopies) => {
    const { selectedCheckboxes } = this.state;
    const selectedCheckboxesArray = selectedCheckboxes.slice();

    const totalCopiesArray = totalCopies.filter(item => {
      return selectedCheckboxesArray.includes(item.x);
    });
    const notEncodedCopiesArray = notEncodedCopies.filter(item => {
      return selectedCheckboxesArray.includes(item.x);
    });

    this.setState(prevState => {
      return {
        ...prevState,
        totalCopiesArray,
        notEncodedCopiesArray
      };
    });
  }

  getCopiesSummaryList = async () => {
    const { selectedCompany } = this.props;
    if (!selectedCompany) {
      return;
    }
    try {
      const { getCopiesSummary } = this.props;
      await getCopiesSummary();
    } catch (err) {
      alert(
        'Hubo un error obteniendo la información de la distribución de colecciones y los no asociados.'
      );
    }
  };

  _onChangePicker = value => {
    const { cameras } = this.props;
    this.setState(
      prevState => {
        return {
          ...prevState,
          menuItemSelected: value,
        };
      },
      () => {
        if (cameras.length > 0) this.getPersonsCounterList();
      }
    );
  };

  getPersonsCounterList = async () => {
    const { selectedCompany } = this.props;
    if (!selectedCompany) {
      return;
    }

    try {
      const { getPersonsCounterSummary, cameras, selectedCamera } = this.props;
      const { pickerMenuItems, menuItemSelected } = this.state;
      const found = pickerMenuItems.find(element => {
        return element.value === menuItemSelected;
      });
      const cameraName = cameras.find(camera => {
        return camera.value === selectedCamera;
      });
      if (cameraName) {
        await getPersonsCounterSummary({ type: found.name, camera: cameraName.label });
      }
    } catch (err) {
      alert('Hubo un error obteniendo la información del conteo de personas.');
    }
  };

  getPeopleEntryAverage = () => {
    const { inData } = this.props;

    let peopleEntryTotal = 0;

    inData.map(item => {
      peopleEntryTotal = peopleEntryTotal + item.y;
    });

    let peopleEntryAverage = (peopleEntryTotal / inData.length).toFixed(2);

    this.setState({ peopleEntryAverage });
  }

  getPeopleDeparturesAverage = () => {
    const { outData } = this.props;

    let peopleDeparturesTotal = 0;

    outData.map(item => {
      peopleDeparturesTotal = peopleDeparturesTotal + item.y;
    });

    let peopleDeparturesAverage = (peopleDeparturesTotal / outData.length).toFixed(2);

    this.setState({ peopleDeparturesAverage });
  }

  // getStationsActivityList = async () => {
  //   const { selectedCompany } = this.props;
  //   if (!selectedCompany) {
  //     return;
  //   }

  //   try {
  //     const { getStationsActivitySummary } = this.props;
  //     await getStationsActivitySummary();
  //   } catch (err) {
  //     alert('Hubo un error obteniendo la información del registro de actividad por estaciones');
  //   }
  // };

  transformDataToChartStructure = data => {
    const chartData = [];
    data.forEach(item => {
      chartData.push({ x: item.location, y: item.count });
    });
    return chartData;
  };

  render() {
    const {
      cameras,
      selectACamera,
      selectedCamera,
      loadingCopiesSummary,
      totalCopies,
      notEncodedCopies,
      inData,
      outData,
      //loadingPersonsCounterSummary,
      stationsActivity,
      loadingStationsActivitySummary,
    } = this.props;
    const {
      pickerMenuItems,
      menuItemSelected,
      dialogVisible,
      campusList,
      dialogCampusVisible,
      selectedCheckboxes,
      selectedCampusCheckbox,
      totalCopiesArray,
      notEncodedCopiesArray,
      isAssets,
      labelButton,
      idModal,
      titleModal,
      listItems,
    } = this.state;

    const actions = [];
    const actionsCampus = [];
    actions.push(
      <Checkbox
        id='allSelect'
        name='allSelect'
        label='Marcar/Desmarcar todo'
        checked={this.state.selectedCheckboxes.length !== 0 ? true : false}
        onChange={this.toggleSelectAllCheckboxes}
      />,
      <Button raised onClick={() => this.toggleCollectionsDialog(false)}>Aceptar</Button>
    );
    actionsCampus.push(<Button raised onClick={() => this.toggleCampusDialog(false)}>Aceptar</Button>);

    const controlsCampus = [{ label: 'Todas las sedes', value: 'allCampus' }];
    campusList.map((campus) => {
      if (campus.name == null)
        controlsCampus.push({ label: 'null', value: 'null' });
      else
        controlsCampus.push({ label: campus.name, value: campus.name });
    });

    return (
      <DefaultLayout>
        <BillingNotif />
        <Grid>
          <Cell size={12}>
            <Card style={styles.card}>
              <CardTitle title={isAssets ? 'Distribución de los Activos' : 'Distribución de la Colección'} />
              <CardText>
                {loadingCopiesSummary && <LinearProgress id="loanProgress" />}
                <Button
                  disabled={
                    loadingCopiesSummary ||
                    totalCopies.length === 0 ||
                    notEncodedCopies.length === 0
                  }
                  raised
                  onClick={() => this.toggleCollectionsDialog(true)}
                  style={{ marginBottom: 10 }}
                >
                  {labelButton}
                </Button>
                <DialogContainer
                  id={idModal}
                  visible={dialogVisible}
                  actions={actions}
                  title={titleModal}
                  onHide={() => this.toggleCollectionsDialog(false)}
                  dialogStyle={{ paddingLeft: 15, paddingBottom: 15, width: 500 }}
                >
                  <List>
                    {listItems.map(
                      (label, index) =>
                        label && (
                          <Checkbox
                            key={index}
                            id={`${label}`}
                            name={label}
                            label={label}
                            checked={selectedCheckboxes.indexOf(label) !== -1 ? true : false}
                            onChange={this.toggleCheckbox}
                          />
                        )
                    )}
                  </List>
                </DialogContainer>
                {isAssets ? null : <Button
                  // disabled={
                  //   loadingCopiesSummary ||
                  //   totalCopies.length === 0 ||
                  //   notEncodedCopies.length === 0
                  // }
                  raised
                  onClick={() => this.toggleCampusDialog(true)}
                  style={{ marginBottom: 10 }}
                >
                  Filtrar por Sede
                </Button>}
                <DialogContainer
                  id="campus-dialog"
                  visible={dialogCampusVisible}
                  actions={actionsCampus}
                  title="Sedes"
                  onHide={() => this.toggleCampusDialog(false)}
                  dialogStyle={{ paddingLeft: 15, paddingBottom: 15, width: 500 }}
                >
                  <List>
                    <SelectionControlGroup
                      id="campus-selected"
                      name="campus-selected"
                      type="radio"
                      label="Seleccione la sede a consultar"
                      controls={controlsCampus}
                      defaultValue={selectedCampusCheckbox}
                      onChange={this.toggleCampusCheckbox}
                    />
                  </List>
                </DialogContainer>
                <Cell>
                  <label style={{ display: (selectedCampusCheckbox === 'allCampus' ? 'none' : 'block') }}>{selectedCampusCheckbox}</label>
                </Cell>
                <DoubleBarChart
                  clearData={loadingCopiesSummary}
                  name="Colección"
                  data1={totalCopiesArray}
                  color1="#fdc02f"
                  title1={'Total: ' + this.getTotalsCopies().totalCopies}
                  data2={notEncodedCopiesArray}
                  color2="#20A39E"
                  title2={'No Asociados: ' + this.getTotalsCopies().totalNotEncodedCopies}
                  data3={[]}
                  color3="#ffffff"
                  title3={'Asociados: ' + this.getTotalsCopies().totalEncodedCopies}
                  height={380} //300
                  margin={{ bottom: 200, left: 120, top: 70, right: 80 }} //140 60 70
                />
              </CardText>
            </Card>
          </Cell>
          {!cameras.length ? null : <Cell size={12}>
            <Card style={styles.card}>
              <CardTitle title="Contador de Personas" />
              <CardText>
                <Grid>
                  <Cell size={6}>
                    <SelectField
                      simplifiedMenu
                      id="type-select-field"
                      label="Tipo de gráfico"
                      placeholder="Tipo de gráfico"
                      menuItems={pickerMenuItems}
                      value={menuItemSelected}
                      // eslint-disable-next-line no-underscore-dangle
                      onChange={value => this._onChangePicker(value)}
                      style={styles.selectField}
                    />
                    <SelectField
                      simplifiedMenu
                      id="type-select-field"
                      label="Selecciona una cámara"
                      placeholder="Selecciona una cámara"
                      menuItems={cameras}
                      value={selectedCamera}
                      onChange={value => selectACamera(value)}
                      style={styles.selectField2}
                    />
                  </Cell>
                  <Cell size={3}>
                    <List>
                      <ListItem
                        leftAvatar={<Avatar suffix={'deep-purple'} icon={<FontIcon>people</FontIcon>} />}
                        primaryText='Entradas'
                        secondaryText={'Promedio: ' + this.state.peopleEntryAverage}
                      />
                    </List>
                  </Cell>
                  <Cell size={3}>
                    <List>
                      <ListItem
                        leftAvatar={<Avatar suffix={'teal'} icon={<FontIcon>people</FontIcon>} />}
                        primaryText='Salidas'
                        secondaryText={'Promedio: ' + this.state.peopleDeparturesAverage}
                      />
                    </List>
                  </Cell>
                </Grid>
                {/* {loadingPersonsCounterSummary && <LinearProgress id="loanProgress" />} */}
                <DoubleBarChart
                  // clearData={loadingPersonsCounterSummary}
                  name="Fecha"
                  data1={inData}
                  color1="#134074"
                  title1="Entradas"
                  data2={outData}
                  color2="#21C590"
                  title2="Salidas"
                  height={300}
                  margin={{ bottom: 90, left: 60, top: 70 }}
                />
              </CardText>
            </Card>
          </Cell>}
          {!((this.state.booleanLoan || this.state.booleanReturn) &&
            (this.props.userData.role === "admin" || this.props.userData.status.includes('web_reports'))) ?
            null :
            <Cell size={12}>
              <Card style={styles.card}>
                <CardTitle title="Estaciones Autopréstamo/Autodevolución" />
                <CardText>
                  <Grid>
                    {this.props.stations.filter(item => item.indexOf('SELFLOAN') !== -1)[0] !== undefined ?
                      <Cell size={6}>
                        <h5>Autopréstamo</h5>
                        <SelectField
                          simplifiedMenu
                          id="type-select-selfloan"
                          label="Selecciona una estación"
                          placeholder="Selecciona una estación"
                          menuItems={this.state.menuItemsSelfLoan}
                          value={this.state.selectedSelfLoan}
                          onChange={value => this.getSelfLoanStationReport(value)}
                          style={styles.selectField2}
                        />
                        {/* <LineSeriesChart
                          title1='Préstamos'
                          data1={this.state.loanSelfLoanReport}
                          color1='#44aabb'
                          title2='Devoluciones'
                          data2={this.state.devolutionSelfLoanReport}
                          color2='#aa22ff'
                          title3='Renovaciones'
                          data3={this.state.renewSelfLoanReport}
                          color3='#ef7f1a'
                          height={300}
                          margin={{ bottom: 30, left: 60, top: 70 }}
                        /> */}
                        <DoubleBarChart
                          name="Autoprestamo"
                          data1={this.state.loanSelfLoanReport}
                          color1='#44aabb'
                          title1='Préstamos'
                          data2={this.state.devolutionSelfLoanReport}
                          color2='#aa22ff'
                          title2='Devoluciones'
                          data3={this.state.renewSelfLoanReport}
                          color3='#ef7f1a'
                          title3='Renovaciones'
                          height={300}
                          margin={{ bottom: 90, left: 60, top: 70 }}
                        />
                      </Cell> : null}
                    {this.props.stations.filter(item => item.indexOf('SELFRETURN') !== -1)[0] !== undefined ?
                      <Cell size={6}>
                        <h5>Autodevolución</h5>
                        <SelectField
                          simplifiedMenu
                          id="type-select-selfreturn"
                          label="Selecciona una estación"
                          placeholder="Selecciona una estación"
                          menuItems={this.state.menuItemsSelfReturn}
                          value={this.state.selectedSelfReturn}
                          onChange={value => this.getSelfReturnStationReport(value)}
                          style={styles.selectField2}
                        />
                        {/* <LineSeriesChart
                          title1='Préstamos'
                          data1={this.state.loanSelfReturnReport}
                          color1='#44aabb'
                          title2='Devoluciones'
                          data2={this.state.devolutionSelfReturnReport}
                          color2='#aa22ff'
                          title3='Renovaciones'
                          data3={this.state.renewSelfReturnReport}
                          color3='#ef7f1a'
                          height={300}
                          margin={{ bottom: 30, left: 60, top: 70 }}
                        /> */}
                        <DoubleBarChart
                          name="Autodevolucion"
                          data1={this.state.loanSelfReturnReport}
                          color1='#44aabb'
                          title1='Préstamos'
                          data2={this.state.devolutionSelfReturnReport}
                          color2='#aa22ff'
                          title2='Devoluciones'
                          data3={this.state.renewSelfReturnReport}
                          color3='#ef7f1a'
                          title3='Renovaciones'
                          height={300}
                          margin={{ bottom: 90, left: 60, top: 70 }}
                        />
                      </Cell> : null}
                  </Grid>
                </CardText>
              </Card>
            </Cell>}
          {/* {!stationsActivity.length ? null : <Cell size={12}>
            <Card style={styles.card}>
              <CardTitle title="Consolidado de actividad por estaciones" />
              <CardText>
                {loadingStationsActivitySummary && <LinearProgress id="loanProgress" />}
                <Grid style={styles.cellContainer}>
                  {Array.from(stationsActivity).map((station, index) => (
                    <Cell key={index} size={6} position="center" align="top">
                      <CardText style={styles.stationInfoContainer}>
                        <p style={styles.textTitle}>{`Estación de ${station.type}`}</p>
                        <p style={styles.textSubtitle}>{`${station.name}`}</p>
                        <Grid style={styles.cellContainer}>
                          <Cell size={4}>
                            <img
                              src={station.image}
                              alt=""
                              width={100}
                              height={100}
                              style={styles.img}
                            />
                          </Cell>
                          <Cell size={8}>
                            <Grid>
                              <Cell size={3}>
                                <p style={styles.text}>Hoy</p>
                              </Cell>
                              <Cell size={9} align="stretch">
                                {Array.from(station.today).map((item, i) => (
                                  <p key={i} style={styles.numberText}>
                                    {`${item.value} `}{' '}
                                    <span style={styles.text}>{`${item.name}`}</span>
                                  </p>
                                ))}
                              </Cell>
                            </Grid>
                            <Grid>
                              <Cell size={3}>
                                <p style={styles.text}>{`${capitalize(getCurrentMonth())}`}</p>
                              </Cell>
                              <Cell size={9} align="stretch">
                                {Array.from(station.month).map((item, id) => (
                                  <p key={id} style={styles.numberText}>
                                    {`${item.value} `}{' '}
                                    <span style={styles.text}>{`${item.name}`}</span>
                                  </p>
                                ))}
                              </Cell>
                            </Grid>
                          </Cell>
                        </Grid>
                      </CardText>
                    </Cell>
                  ))}
                </Grid>
              </CardText>
            </Card>
          </Cell>} */}
          {this.props.userData.role === 'root' && <Cell size={12}>
            <Card style={styles.card}>
              <CardTitle title="Estado de aplicaciones" />
              <CardText>
                <Grid>
                  <Cell
                    size={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      border: '1px solid #ddd',
                      borderRadius: 15,
                    }}
                  >
                    {Object.entries(this.state.modules).map((item, i) =>
                      <Card key={i} style={{ width: 200 }}>
                        <CardActions centered>
                          <Button
                            floating
                            style={{
                              margin: 15,
                              backgroundColor: item[1].color,
                              color: 'white',
                            }}
                            onClick={() => this.getModuleRegistersChart(item[0], item[1].module, item[1].color, item[1].count)}
                          >
                            monitor
                          </Button>
                        </CardActions>
                        <CardText
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <strong style={{ textAlign: 'center' }}>{item[1].module}</strong>
                        </CardText>
                      </Card>
                    )}
                  </Cell>
                  <Cell size={12} style={{ overflowX: 'auto' }}>
                    <DynamicLineSeriesChart
                      data={this.state.moduleRegistersDataChart}
                      height={380}
                      margin={{ bottom: 130, left: 60, top: 95 }}
                    />
                  </Cell>
                </Grid>
              </CardText>
            </Card>
          </Cell>}
        </Grid>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  userData: state.user.user,
  selectedCompany: state.user.selectedCompany,
  companies: state.companies.companies,
  cameras: state.cameras.cameras,
  selectedCamera: state.cameras.selectedCamera,
  loadingCamerasByCompany: state.cameras.loadingCamerasByCompany,
  totalCopies: state.summaries.totalCopies,
  collections: state.collections.collections,
  notEncodedCopies: state.summaries.notEncodedCopies,
  loadingCopiesSummary: state.summaries.loadingCopiesSummary,
  inData: state.summaries.inData,
  outData: state.summaries.outData,
  loadingPersonsCounterSummary: state.summaries.loadingPersonsCounterSummary,
  stationsActivity: state.summaries.stationsActivity,
  loadingStationsActivitySummary: state.summaries.loadingStationsActivitySummary,
  stations: state.stations.stations,
});

const mapDispatchToProps = dispatch => ({
  getCopiesSummary: () => dispatch(fetchingCopiesSummary()),
  getCameras: () => dispatch(fetchingCamerasByCompany()),
  selectACamera: value => dispatch(selectCamera({ selectedCamera: value })),
  getPersonsCounterSummary: params => dispatch(fetchingPersonsCounterSummary(params)),
  cleanDataPersonsCounter: () => dispatch(cleanPersonsCounterData()),
  getStationsActivitySummary: () => dispatch(fetchingStationsActivitySummary()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);