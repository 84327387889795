/* eslint-disable no-undef */
/* eslint-disable no-console */
import { sessionEndpoint } from './config/endpoints';

import { optionsPOST, optionsDELETE } from './config/options';

const AuthenticationService = {
  logIn: credentials =>
    fetch(sessionEndpoint(), optionsPOST(credentials, false))
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.statusText);
        }
        return response.json();
      })
      .then(response => {
        if (response.jwt && response.user) {
          localStorage.setItem('user', JSON.stringify(response.user));
          localStorage.setItem('session', JSON.stringify({ jwt: response.jwt, exp: response.exp }));
          localStorage.setItem(
            'selected_company',
            JSON.stringify({ value: response.user.companies[0] })
          );
          const session = localStorage.getItem('session');
          const selectedCompany = localStorage.getItem('selected_company');
          console.log('session', session);
          console.log('login response', response);
          console.log('selected_company', selectedCompany);
        }
        return response;
      })
      .catch(error => console.log(error)),

  logOut: () =>
    fetch(sessionEndpoint(), optionsDELETE())
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.statusText);
        }
        return response.json();
      })
      .then(response => {
        if (response.jwt === 'revoked') {
          localStorage.removeItem('user');
          localStorage.removeItem('session');
          localStorage.removeItem('selected_company');
        }
        return response;
      })
      .catch(error => console.log(error)),
};

export default AuthenticationService;
