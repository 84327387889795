import React, { useState, useEffect } from 'react'

import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  LinearProgress,
  Button,
  FontIcon,
  TablePagination,
} from 'react-md';

export default function InventoryTable(props) {
  let [slicedData, setSlicedData] = useState([])
  let [translatedProps, setTranslatedProps] =useState([])
  let rows= props.inventories && props.inventories.length

  useEffect(()=>{
  let translated
  translated=props.inventories.map((inventory)=>{
     return {Nombre: inventory.name,
      id: inventory.id}
    })
    setTranslatedProps(translated)
  }, [props.inventories])

  useEffect(()=>setSlicedData(translatedProps.slice(0,10)), [translatedProps])
  let handlePagination = (start, rowsPerPage) => {
   setSlicedData(translatedProps.slice(start, start + rowsPerPage));
  };
  
  return (
    <Card tableCard>
      {props.loading && <LinearProgress id='usersProgress' />}
      <DataTable baseId='dynamic-content-desserts'>
        {(
          <React.Fragment>
            <TableHeader>
              <TableRow>
                {slicedData[0] && Object.keys(slicedData[0]).map((header, i) => {
                  return (<TableColumn key={header}>{header}</TableColumn>)
                }
                )}
                <TableColumn key='actions'>Acciones</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {slicedData.map((item, i) => (
                <TableRow key={item.id} >
                  {Object.keys(item).map(key => (
                     <TableColumn key={key}>{item[key]}</TableColumn>
                  ))}
                  <TableColumn key='actions' onClick={()=>{}}>
                    <div className='buttons__group' style={{ marginBottom: '2rem' }}>
                    <Button onClick={() => props.deleteItem(item)} icon>
                        <FontIcon>delete</FontIcon>
                      </Button>
                      <Button onClick={() => props.showInventorySummary(item)} icon>
                        <FontIcon>zoom_in</FontIcon>
                      </Button>
                    </div>
                  </TableColumn>
                </TableRow>
              ))}
            </TableBody>
            <TablePagination rows={rows} rowsPerPageLabel={"Filas"} onPagination={handlePagination} />
          </React.Fragment>
        )}
      </DataTable>
    </Card>
  );
}