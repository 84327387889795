import {
  GET_CAMERAS_BY_COMPANY,
  LOADING_CAMERAS_BY_COMPANY,
  CAMERAS_BY_COMPANY_ERROR,
  SELECTED_CAMERA,
} from './types';
import { getCamerasByCompany } from '../services/cameras';
import store from '../store';

export const fetchingCamerasByCompany = () => {
  return async dispatch => {
    try {
      dispatch({ type: LOADING_CAMERAS_BY_COMPANY, loadingCamerasByCompany: true });
      const company = store.getState().user.selectedCompany;
      const params = { company };
      const camerasResponse = await getCamerasByCompany(params);

      const cameras = camerasResponse.cameras.map(camera => ({
        value: camera.id,
        label: camera.camera_name,
      }));
      if (cameras.length > 0) {
        dispatch({ type: SELECTED_CAMERA, payload: cameras[0].value });
      } else {
        dispatch({ type: SELECTED_CAMERA, payload: 0 });
      }
      dispatch({ type: GET_CAMERAS_BY_COMPANY, cameras });
    } catch (err) {
      dispatch({ type: CAMERAS_BY_COMPANY_ERROR, camerasByCompanyError: true });
      // eslint-disable-next-line no-console
      console.log(`Unable to get the cameras list from the database: ${err}`);
      throw err;
    }
  };
};

export const selectCamera = payload => dispatch => {
  dispatch({ type: SELECTED_CAMERA, payload: payload.selectedCamera });
};
