import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';

import { getNewsReport } from '../../../services/assets';
import {
    Cell, Card, DatePicker, CardTitle, Grid,
    Button
} from 'react-md';
import moment from 'moment';

import 'moment/locale/es';
import ReactExport from "react-data-export";

import PaginationTable from '../../../components/tables/pagination_table';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
moment.locale('es');

export const NewsReport = () => {

    const { loggedIn, userData, selectedCompany } = useSelector(state => ({
        loggedIn: state.user.loggedIn,
        userData: state.user.user,
        selectedCompany: state.user.selectedCompany || 13
    }));

    let [isLoading, setIsLoading] = useState(false);
    let [messageFetch, setMessageFetch] = useState('');
    let [newsReport, setNewsReport] = useState([]);
    let [encodedReportParams, setencodedReportParams] = useState({});

    // =======================================================
    /// 
    // =======================================================
    const getNewsReportFromApi = () => {
        setIsLoading(true);
        let startDateForEndpoint = moment(
            encodedReportParams.start_at,
            'YYYY-MM-DD'
        ).format()
        let endDateForEndpoint = moment(
            encodedReportParams.end_at,
            'YYYY-MM-DD'
        ).format()
        getNewsReport({
            company: selectedCompany,
            start_at: startDateForEndpoint.split("T")[0],
            end_at: endDateForEndpoint.split("T")[0],
        })
            .then(resp => {
                if (resp.success) {
                    console.log(resp.data);
                    const data = resp.data.map( d => ({...d, encoded_at: moment(d.encoded_at).format('YYYY-MM-DD')}) )
                    setMessageFetch('');
                    setNewsReport(data);
                } else {
                    setNewsReport([]);
                    setMessageFetch('No hay registros');
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err.message);
                setMessageFetch('No hay registros');
                setIsLoading(false);
                alert(err.message);
            });
    }

    // =======================================================
    /// 
    // =======================================================
    const handleNewsStartDateChange = (value, dateValue, event) => {
        setencodedReportParams({ ...encodedReportParams, start_at: dateValue })
    }

    // =======================================================
    /// 
    // =======================================================
    const handleNewsEndDateChange = (value, dateValue, event) => {
        setencodedReportParams({ ...encodedReportParams, end_at: dateValue })
    }

    return (
        <Cell size={12}>
            <Card>
                <CardTitle title="Reporte de Novedades" />
                {
                    isLoading && (<LinearProgress />)
                }
                <Grid>
                    <Cell size={3}>
                        <DatePicker
                            label="Fecha de inicio"
                            fullWidth={false}
                            value={encodedReportParams.start_at}
                            onChange={handleNewsStartDateChange}
                        />
                    </Cell>
                    <Cell size={3}>
                        <DatePicker
                            label="Fecha final"
                            fullWidth={false}
                            value={encodedReportParams.end_at}
                            onChange={handleNewsEndDateChange}
                        />
                    </Cell>
                    <Cell size={3}>
                        <Button
                            type="submit"
                            flat
                            className="md-cell md-cell--bottom"
                            primary
                            swapTheming
                            onClick={getNewsReportFromApi}
                        >
                            Ver
                    </Button>
                    </Cell>
                    <Cell size={12}>
                        {newsReport.length > 0 &&
                            <ExcelFile
                                filename={`Reporte de novedades`}
                                element={<Button
                                    raised
                                    primary
                                    swapTheming
                                >
                                    Descargar
                            </Button>}>
                                <ExcelSheet data={newsReport} name={`Reporte de novedades`}>
                                    {Object.keys(newsReport[0]).map((title) =>
                                        <ExcelColumn label={title} value={`${title}`} />
                                    )}
                                </ExcelSheet>
                            </ExcelFile>
                        }
                        <PaginationTable
                            rows={newsReport}
                            countRows={newsReport.length}
                            title="Reporte Novedades"
                            headCells={[
                                { id: 'user', numeric: false, disablePadding: false, label: 'Usuario' },
                                { id: 'encode_note', numeric: false, disablePadding: false, label: 'Nota' },
                                { id: 'serial', numeric: false, disablePadding: false, label: 'Código de Barras' },
                                { id: 'status', numeric: false, disablePadding: false, label: 'Estado' },
                                { id: 'encoded_at', numeric: false, disablePadding: false, label: 'Fecha' },
                            ]}
                            fields={['user', 'encode_note', 'serial', 'status', 'encoded_at']}
                        />
                    </Cell>
                </Grid>
            </Card>
        </Cell>
    );
};