import {
  GET_CAMERAS_BY_COMPANY,
  LOADING_CAMERAS_BY_COMPANY,
  CAMERAS_BY_COMPANY_ERROR,
  SELECTED_CAMERA,
} from '../actions/types';

const initialState = {
  cameras: [],
  loadingCamerasByCompany: false,
  camerasByCompanyError: false,
  selectedCamera: 0,
};

const camerasReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_CAMERAS_BY_COMPANY:
      return {
        ...state,
        loadingCamerasByCompany: true,
        cameras: [],
      };
    case GET_CAMERAS_BY_COMPANY:
      return {
        ...state,
        loadingCamerasByCompany: false,
        camerasByCompanyError: false,
        cameras: action.cameras,
      };
    case CAMERAS_BY_COMPANY_ERROR:
      return {
        ...state,
        cameras: [],
        loadingCamerasByCompany: false,
        camerasByCompanyError: action.camerasByCompanyError,
      };
    case SELECTED_CAMERA:
      return {
        ...state,
        selectedCamera: action.payload,
      };
    default:
      return state;
  }
};

export default camerasReducer;
