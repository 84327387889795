import React, { Component } from 'react';
import { connect } from 'react-redux';
import { readRemoteFile } from 'react-papaparse';
import XLSX from 'xlsx';
import {
  Button,
  Cell,
  Card,
  CardTitle,
  CardText,
  DataTable,
  DialogContainer,
  Grid,
  LinearProgress,
  List,
  Checkbox,
  DatePicker,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  TablePagination,
  SelectField
} from 'react-md';
import moment from 'moment';
import 'moment/locale/es';
import ReactExport from 'react-data-export';
import { isNull } from 'util';
import { session } from '../../services/config/options';
import { DefaultLayout, ReportsNav, FlatOrIconButton } from '../../components';
import { getReport, getGeneralAssetsReport } from '../../services/assets'
import {
  getNotEncodedReport,
  paginateNotEncodedReport,
  handlePickerChangeNotEncodedReport,
} from '../../actions';

moment.locale('es');

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

const styles = {
  card: {
    borderRadius: '2px',
  },
  selectField: {
    width: '100%',
  },
};

class NotEncodedReport extends Component {
  state = {
    queryDone: false,
    dialogVisible: false,
    selectedCollections: [],
    hasFetched: false,
    encodedReportParams: {},
    report: [],
    initRow: 0, // Para dar manejo si aumenta o decrementa la pagina de la tabla
    perPage: 10, // Es el límite para la consulta, si es 10, 20, 30, 40, 50, 100 datos por pagina
    offsetData: 0, // Desde donde inicia la consulta
    itemsType: [
      {
        label: 'Todos los tipos',
        value: 3
      },
      {
        label: 'Asociados',
        value: 2
      },
      {
        label: 'No Asociados',
        value: 1
      }
    ],
    selectedType: 3,
    linkGeneralReports: '',
    amazonData: [],
  };

  componentDidMount() {
    if (isNull(session)) window.location.reload();
    this.getGeneralReport();
  }

  componentDidUpdate(prevProps) {
    const { selectedCompany } = this.props;
    if (prevProps.selectedCompany !== selectedCompany) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedCollections: [] });
      this.getGeneralReport();
    }
  }

  handleStartDateChange = (value, dateValue, event) => {
    this.setState({encodedReportParams: { ...this.state.encodedReportParams, start_at: dateValue }});
  }

  handleEndDateChange = (value, dateValue, event) => {
    this.setState({encodedReportParams: { ...this.state.encodedReportParams, end_at: dateValue }});
  }

  getReportFromApi = () => {
    let startDateForEndpoint = moment(
      this.state.encodedReportParams.start_at,
      'YYYY-MM-DD'
    ).format()
    let endDateForEndpoint = moment(
      this.state.encodedReportParams.end_at,
      'YYYY-MM-DD'
    ).format()
    getReport({
      company: this.props.selectedCompany,
      start_at: startDateForEndpoint.split("T")[0],
      end_at: endDateForEndpoint.split("T")[0],
    })
      .then(resp => {
        if (resp.success) {
          this.setState({report: resp.data});
        }
        this.setState({hasFetched: true});
      })
      .catch(err => console.log(err))
  }

  handleDatePickerChange = (pickerValue, pickerId) => {
    const { setDate } = this.props;
    setDate({ pickerValue, pickerId });
  };

  getGeneralReport = async () => {
    const {selectedCompany} = this.props;
    const params = {
      company: selectedCompany,
    }
    try {
      const link = await getGeneralAssetsReport(params);
      this.setState({linkGeneralReports: link.link}, () => this.csvToObject());
    } catch (error) {
        console.log(error);
    }
  }

  csvToObject = () => {
    readRemoteFile(this.state.linkGeneralReports, {
      download: true,
      header: true,
      complete: async (results) => {
        this.setState({amazonData: await results.data});
      }
    });
  }

  getNotEncodedReportData = async () => {
    const { selectedCollections } = this.state;

    const {
      selectedCompany,
      getNotEncodedReportConnect,
      startDateNotEncoded,
      endDateNotEncoded,
    } = this.props;

    if (!startDateNotEncoded) {
      alert('Seleccione una fecha de inicio');
      return;
    }
    if (!endDateNotEncoded) {
      alert('Seleccione una fecha de fin');
      return;
    }

    this.setState({ queryDone: true });

    let startDateForEndpoint = moment(startDateNotEncoded, 'DD/MM/YYYY').format();
    startDateForEndpoint = startDateForEndpoint.substring(0, 10);

    let endDateForEndpoint = moment(endDateNotEncoded, 'DD/MM/YYYY').format();
    endDateForEndpoint = endDateForEndpoint.substring(0, 10);

    const collectionsForEndpoint = selectedCollections
      .reduce((accumulator, currentValue) => {
        accumulator.push(`${encodeURIComponent(currentValue)}`);
        return accumulator;
      }, [])
      .join('|');

    let params = {
      company: selectedCompany,
      start_at: startDateForEndpoint,
      end_at: endDateForEndpoint,
      type: this.state.selectedType,
      limit: this.state.perPage,
      offset: this.state.offsetData,
    };
    if (selectedCollections.length > 0) {
      params = { ...params, location: collectionsForEndpoint };
    }

    try {
      
      await getNotEncodedReportConnect(params);

    } catch (error) {
      
      alert('Hubo un error obteniendo la información de Reporte de No Asociados.');

    }
  };

  handleDataPagination = async (startRow, rowsPerPage) => {

    await this.setState({offsetData: startRow});

    await this.setState({perPage: rowsPerPage});

    await this.getNotEncodedReportData();

  };

  truncateString = (text, truncLength) => {
    if(text !== null){
      return text.length > truncLength ? `${text.substr(0, truncLength - 1)} ...` : text;
    }
    else {
        return '';
    }
  };

  toggleCollectionsDialog = value => {
    this.setState({ dialogVisible: value });
  };

  toggleCheckbox = (checked, changeEvent) => {
    const { selectedCollections } = this.state;
    const label = changeEvent.target.id;
    const selectedCollectionsArray = selectedCollections.slice();
    if (checked) {
      selectedCollectionsArray.push(label);
    } else {
      const index = selectedCollectionsArray.indexOf(label);
      selectedCollectionsArray.splice(index, 1);
    }
    this.setState({ selectedCollections: selectedCollectionsArray });
  };

  exportXLSX = () => {
    /*  Building the fileName  */
    let date = new Date();
    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun",
      "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ];

    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    const fileName = 'TotalEjemplaresGenia ' + day + '-' + month + '-' + year;

    /*  Building Export Excel */

    /* make the worksheet */
    let ws = XLSX.utils.json_to_sheet(this.state.amazonData);

    /* add to workbook */
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Inventory");

    /* generate an XLSX file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  render() {
    const {
      notEncodedReport,
      //slicedNotEncodedReport,     Usado en REDUX
      loadingNotEncodedReport,
      startDateNotEncoded,
      endDateNotEncoded,
      collections,
    } = this.props;
    const { dialogVisible, queryDone, selectedCollections } = this.state;

    return (
      <DefaultLayout>
        <Grid>
          <Cell size={12}>
            <ReportsNav />
          </Cell>
          <Cell size={12}>
            <Card style={styles.card}>
              <CardTitle title="Reporte de Ejemplares" />
              <CardText>
                <Card tableCard>
                  {loadingNotEncodedReport && <LinearProgress id="usersProgress" />}
                  <Grid className="grid-example">
                    <Cell size={2}>
                      <p>
                        {`Desde ${notEncodedReport.length > 0 ? ` -> ${startDateNotEncoded}` : ''}`}
                      </p>
                      <DatePicker
                        id="date-picker-auto-ok"
                        label="Fecha"
                        className="md-cell--12"
                        onChange={value =>
                          this.handleDatePickerChange(value, 'startDateNotEncoded')
                        }
                        locales="es"
                      />
                    </Cell>
                    <Cell size={2}>
                      <p>
                        {`Hasta ${notEncodedReport.length > 0 ? ` -> ${endDateNotEncoded}` : ''}`}
                      </p>
                      <DatePicker
                        id="date-picker-auto-ok"
                        label="Fecha"
                        className="md-cell--12"
                        onChange={value => this.handleDatePickerChange(value, 'endDateNotEncoded')}
                        locales="es"
                      />
                    </Cell>
                    <Cell size={3}>
                      <p>Filtrar por Coleccion(es)</p>
                      <p>(opcional)</p>
                      <Button raised onClick={() => this.toggleCollectionsDialog(true)}>
                        Seleccionar Colecciones
                      </Button>
                      <DialogContainer
                        id="collections-dialog"
                        visible={dialogVisible}
                        title="Colecciones"
                        onHide={() => this.toggleCollectionsDialog(false)}
                        dialogStyle={{ paddingLeft: 15, paddingBottom: 15, width: 500 }}
                      >
                        <List>
                          {collections.map(
                            (label, index) =>
                              label && (
                                <Checkbox
                                  key={index}
                                  id={`${label}`}
                                  name="collections-checkboxes[]"
                                  label={label}
                                  checked={selectedCollections.indexOf(label) !== -1}
                                  onChange={this.toggleCheckbox}
                                />
                              )
                          )}
                        </List>
                      </DialogContainer>
                    </Cell>
                    <Cell size={3}>
                      <SelectField
                        simplifiedMenu
                        id="type-select-field"
                        label="Selecciona un tipo"
                        placeholder="Selecciona un tipo"
                        menuItems={this.state.itemsType}
                        defaultValue={this.state.itemsType[0].value}
                        onChange={value => this.setState({selectedType: value})}
                        style={styles.selectField2}
                      />
                    </Cell>
                    <Cell size={2}>
                      <Button
                        type="submit"
                        className="md-cell--12 md-cell--bottom"
                        onClick={this.getNotEncodedReportData}
                        primary
                        raised
                      >
                        Buscar
                      </Button>
                    </Cell>
                    <Cell size={2}>
                      <Button
                        raised
                        primary
                        disabled = {this.state.amazonData.length !== 0 ? false : true}
                        onClick = {() => this.exportXLSX()}
                      >
                        {this.state.amazonData.length !== 0 ? 'Descargar XLSX' : 'Convirtiendo...'}
                      </Button>
                    </Cell>
                    <Cell size={2}>
                      <Button
                        primary
                        raised
                      >
                        <a style={{textDecoration: 'none', color: 'white'}} href={this.state.linkGeneralReports}>Descargar CSV</a>
                      </Button>
                    </Cell>
                  </Grid>
                  {/* <Grid>
                    {notEncodedReport.length > 0 && (
                      <ExcelFile
                        filename="ReporteDeAsociados"
                        element={
                          <FlatOrIconButton raised iconChildren="folder">
                            »Exportar
                          </FlatOrIconButton>
                        }
                      >
                        <ExcelSheet data={notEncodedReport} name="Ejemplares Asociados">
                          <ExcelColumn label="Tag Id" value="Tag Id" />
                          <ExcelColumn label="Código de Barras" value="Código de Barras" />
                          <ExcelColumn label="Título" value="Título" />
                          <ExcelColumn label="Colección" value="Colección" />
                          <ExcelColumn label="Estado" value="Estado" />
                          <ExcelColumn label="Autor" value="Autor" />
                        </ExcelSheet>
                      </ExcelFile>
                    )}
                  </Grid> */}
                  <DataTable baseId="simple-pagination">
                    {notEncodedReport.length === 0 ? (
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'center', padding: '10px' }}>
                            {queryDone ? 'No Hay Registros' : ''}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <>
                        <TableHeader>
                          <TableRow selectable={false}>
                            {notEncodedReport.length > 0 &&       //slicedNotEncodedReport .length por defecto REDUX
                              Object.keys(notEncodedReport[0]).map((header, index) => (     //slicedNotEncodedReport[0] .map por defecto REDUX
                                <TableColumn key={`${header}_${index}`} grow={index === 1}>
                                  {header}
                                </TableColumn>
                              ))}
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {notEncodedReport.map((item, index) => (            //slicedNotEncodedReport .map por defecto REDUX
                            <TableRow key={`${item.id}_${index}`} selectable={false}>
                              {Object.keys(item).map(key => (
                                <TableColumn key={key}>
                                  {this.truncateString(item[key], 50)}
                                </TableColumn>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                        <TablePagination
                          rows={notEncodedReport[0].count} //notEncodedReport.length por defecto.
                          rowsPerPageLabel="Registros por página"
                          onPagination={this.handleDataPagination}
                        />
                      </>
                    )}
                  </DataTable>
                </Card>
              </CardText>
            </Card>
          </Cell>
          <Cell size={12}>
            <Card style={styles.card}>
              <CardTitle title="Reporte por usuario" />
              <Grid>
                <Cell size={3}>
                  <DatePicker
                    label="Fecha de inicio"
                    fullWidth={false}
                    value={this.state.encodedReportParams.start_at}
                    onChange={this.handleStartDateChange}
                  />
                </Cell>
                <Cell size={3}>
                  <DatePicker
                    label="Fecha final"
                    fullWidth={false}
                    value={this.state.encodedReportParams.end_at}
                    onChange={this.handleEndDateChange}
                  />
                </Cell>
                <Cell size={3}>
                  <Button
                    type="submit"
                    flat
                    className="md-cell md-cell--bottom"
                    primary
                    swapTheming
                    onClick={this.getReportFromApi}
                  >
                    Ver
                  </Button>
                </Cell>
              </Grid>
              {this.state.report.length > 0 &&
                <ExcelFile
                  filename={`Reporte por usuario`}
                  element={<Button
                    raised
                    primary
                    swapTheming
                  >
                    Descargar
                  </Button>}>
                  <ExcelSheet data={this.state.report} name={`Reporte por usuario`}>
                    <ExcelColumn label="Usuario" value="user" />
                    <ExcelColumn label="Estado" value="state" />
                    <ExcelColumn label="Número" value="count" />
                  </ExcelSheet>
                </ExcelFile>
              }
              <DataTable>
                <TableHeader>
                  <TableRow>
                    <TableColumn>
                      Usuario
                    </TableColumn>
                    <TableColumn>
                      Número
                    </TableColumn>
                    <TableColumn>
                      Estado
                    </TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {this.state.report.map((user, index) => {
                    return <TableRow key={index}>
                      <TableColumn>{user.user}</TableColumn>
                      <TableColumn>{user.count}</TableColumn>
                      <TableColumn>{user.state}</TableColumn>
                    </TableRow>
                  })}
                </TableBody>
              </DataTable>
            </Card>
          </Cell>
        </Grid>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  selectedCompany: state.user.selectedCompany,
  loadingNotEncodedReport: state.notEncodedReport.loadingNotEncodedReport,
  notEncodedReport: state.notEncodedReport.notEncodedReport,
  slicedNotEncodedReport: state.notEncodedReport.slicedNotEncodedReport,
  startDateNotEncoded: state.notEncodedReport.startDateNotEncoded,
  endDateNotEncoded: state.notEncodedReport.endDateNotEncoded,
  collections: state.collections.collections,
  selectedCollection: state.notEncodedReport.selectedCollection,
});

const mapDispatchToProps = dispatch => ({
  getNotEncodedReportConnect: params => dispatch(getNotEncodedReport(params)),
  paginateNotEncodedReportConnect: params => dispatch(paginateNotEncodedReport(params)),
  setDate: params => dispatch(handlePickerChangeNotEncodedReport(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotEncodedReport);
