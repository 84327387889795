import React, { useState } from 'react'
import { connect } from 'react-redux'
import { DefaultLayout } from '../../components'
import { getReport, getEncodedReport, getNewsReport } from '../../services/assets'
import {
  DataTable, TableHeader, TableRow, TableColumn, TableBody,
  Cell, Card, DatePicker, CardTitle, SelectField, Grid,
  Button
} from 'react-md'

import moment from 'moment';

import 'moment/locale/es';
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
moment.locale('es');


function AssetReport(props) {
  let [hasFetched, setHasFetched] = useState(false)
  let [report, setReport] = useState([])
  let [encodedReport, setEncodedReport] = useState([])
  let [newsReport, setNewsReport] = useState([])
  let [encodedReportParams, setencodedReportParams] = useState({})

  const getReportFromApi = () => {
    let startDateForEndpoint = moment(
      encodedReportParams.start_at,
      'YYYY-MM-DD'
    ).format()
    let endDateForEndpoint = moment(
      encodedReportParams.end_at,
      'YYYY-MM-DD'
    ).format()
    getReport({
      company: props.selectedCompany,
      start_at: startDateForEndpoint.split("T")[0],
      end_at: endDateForEndpoint.split("T")[0],
    })
      .then(resp => {
        if (resp.success) {
          setReport(resp.data);
        }
        setHasFetched(true)
      })
      .catch(err => console.log(err))
  }
  const getEncodedReportFromApi = () => {
    let startDateForEndpoint = moment(
      encodedReportParams.start_at,
      'YYYY-MM-DD'
    ).format()
    let endDateForEndpoint = moment(
      encodedReportParams.end_at,
      'YYYY-MM-DD'
    ).format()
    getEncodedReport({
      company: props.selectedCompany,
      start_at: startDateForEndpoint.split("T")[0],
      end_at: endDateForEndpoint.split("T")[0],
      type: encodedReportParams.type
    })
      .then(resp => {
        if (resp.success) {
          setEncodedReport(resp.data)
        }
        setHasFetched(true)
      })
      .catch(err => console.log(err))
  }
  const getNewsReportFromApi = () => {
    let startDateForEndpoint = moment(
      encodedReportParams.start_at,
      'YYYY-MM-DD'
    ).format()
    let endDateForEndpoint = moment(
      encodedReportParams.end_at,
      'YYYY-MM-DD'
    ).format()
    getNewsReport({
      company: props.selectedCompany,
      start_at: startDateForEndpoint.split("T")[0],
      end_at: endDateForEndpoint.split("T")[0],
    })
      .then(resp => {
        if (resp.success) {
          setNewsReport(resp.data)
        }
        setHasFetched(true)
      })
      .catch(err => console.log(err))
  }
  const handleSelectChange = (value, index, event, details) => {
    setencodedReportParams({ ...encodedReportParams, type: value })
  }

  const handleStartDateChange = (value, dateValue, event) => {
    setencodedReportParams({ ...encodedReportParams, start_at: dateValue })
  }
  const handleEndDateChange = (value, dateValue, event) => {
    setencodedReportParams({ ...encodedReportParams, end_at: dateValue })
  }

  const handleNewsStartDateChange = (value, dateValue, event) => {
    setencodedReportParams({ ...encodedReportParams, start_at: dateValue })
  }
  const handleNewsEndDateChange = (value, dateValue, event) => {
    setencodedReportParams({ ...encodedReportParams, end_at: dateValue })
  }
  const EncodedReport = () => {
    return (
      <Card>
        <CardTitle title="Reporte por tipo" />
        <Grid>
          <Cell size={3}>
            <DatePicker
              label="Fecha de inicio"
              fullWidth={false}
              value={encodedReportParams.start_at}
              onChange={handleStartDateChange}
            />
          </Cell>
          <Cell size={3}>
            <DatePicker
              label="Fecha final"
              fullWidth={false}
              value={encodedReportParams.end_at}
              onChange={handleEndDateChange}
            />
          </Cell>
          <Cell size={3}>
            <SelectField
              placeholder="Tipo"
              menuItems={[
                { label: "No asociados", value: 1 },
                { label: "Asociados", value: 2 },
                { label: "Todos", value: 3 },
              ]}
              value={encodedReportParams.type}
              onChange={handleSelectChange}
            />
          </Cell>
          <Cell size={3}>
            <Button
              type="submit"
              flat
              className="md-cell md-cell--bottom"
              primary
              swapTheming
              onClick={getEncodedReportFromApi}
            >
              Ver
          </Button>
          </Cell>
          <Cell size={12}>
            {encodedReport.length > 0 &&
              <ExcelFile
                filename={`Reporte por Tipo`}
                element={<Button
                  raised
                  primary
                  swapTheming
                >
                  Descargar
             </Button>}>
                <ExcelSheet data={encodedReport} name={`Reporte por Tipo`}>
                  {Object.keys(encodedReport[0]).map((title) =>
                    <ExcelColumn label={title} value={`${title}`} />
                  )}
                </ExcelSheet>
              </ExcelFile>
            }
            <DataTable>
              <TableHeader>
                <TableRow>
                  {encodedReport[0]
                    ? Object.keys(encodedReport[0]).map((title) =>
                      <TableColumn key={title}>{title}</TableColumn>
                    )
                    : null}
                </TableRow>
              </TableHeader>
              <TableBody>
                {encodedReport.length > 0
                  ? encodedReport.map((reportObj, index) =>
                    <TableRow
                      key={index}
                    >
                      {Object.values(reportObj).map(val =>
                        <TableColumn key={val}>{val}</TableColumn>
                      )}
                    </TableRow>
                  )
                  : null}

              </TableBody>
            </DataTable>
          </Cell>
        </Grid>
      </Card>
    )
  }
  const UserReport = () => {
    return (
      <Card>
        <CardTitle title="Reporte por usuario" />
        <Grid>
          <Cell size={3}>
            <DatePicker
              label="Fecha de inicio"
              fullWidth={false}
              value={encodedReportParams.start_at}
              onChange={handleStartDateChange}
            />
          </Cell>
          <Cell size={3}>
            <DatePicker
              label="Fecha final"
              fullWidth={false}
              value={encodedReportParams.end_at}
              onChange={handleEndDateChange}
            />
          </Cell>
          <Cell size={3}>
            <Button
              type="submit"
              flat
              className="md-cell md-cell--bottom"
              primary
              swapTheming
              onClick={getReportFromApi}
            >
              Ver
            </Button>
          </Cell>
        </Grid>
        {report.length > 0 &&
          <ExcelFile
            filename={`Reporte por usuario`}
            element={<Button
              raised
              primary
              swapTheming
            >
              Descargar
             </Button>}>
            <ExcelSheet data={report} name={`Reporte por usuario`}>
              <ExcelColumn label="Usuario" value="user" />
              <ExcelColumn label="Estado" value="state" />
              <ExcelColumn label="Número" value="count" />
            </ExcelSheet>
          </ExcelFile>
        }
        <DataTable>
          <TableHeader>
            <TableRow>
              <TableColumn>
                Usuario
              </TableColumn>
              <TableColumn>
                Número
              </TableColumn>
              <TableColumn>
                Estado
              </TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {report.map((user, index) => {
              return <TableRow key={index}>
                <TableColumn>{user.user}</TableColumn>
                <TableColumn>{user.count}</TableColumn>
                <TableColumn>{user.state}</TableColumn>
              </TableRow>
            })}
          </TableBody>
        </DataTable>
      </Card>
    )
  }
  const NewsReport = () => {
    return (
      <Card>
        <CardTitle title="Reporte de Novedades" />
        <Grid>
          <Cell size={3}>
            <DatePicker
              label="Fecha de inicio"
              fullWidth={false}
              value={encodedReportParams.start_at}
              onChange={handleNewsStartDateChange}
            />
          </Cell>
          <Cell size={3}>
            <DatePicker
              label="Fecha final"
              fullWidth={false}
              value={encodedReportParams.end_at}
              onChange={handleNewsEndDateChange}
            />
          </Cell>
          <Cell size={3}>
            <Button
              type="submit"
              flat
              className="md-cell md-cell--bottom"
              primary
              swapTheming
              onClick={getNewsReportFromApi}
            >
              Ver
        </Button>
          </Cell>
          <Cell size={12}>
            {newsReport.length > 0 &&
              <ExcelFile
                filename={`Reporte de novedades`}
                element={<Button
                  raised
                  primary
                  swapTheming
                >
                  Descargar
             </Button>}>
                <ExcelSheet data={newsReport} name={`Reporte de novedades`}>
                  {Object.keys(newsReport[0]).map((title) =>
                    <ExcelColumn label={title} value={`${title}`} />
                  )}
                </ExcelSheet>
              </ExcelFile>
            }
            <DataTable>
              <TableHeader>
                <TableRow>
                  {newsReport[0]
                    ? Object.keys(newsReport[0]).map((title) =>
                      <TableColumn key={title}>{title}</TableColumn>
                    )
                    : null}
                </TableRow>
              </TableHeader>
              <TableBody>
                {newsReport.length > 0
                  ? newsReport.map((reportObj, index) =>
                    <TableRow
                      key={index}
                    >
                      {Object.values(reportObj).map(val =>
                        <TableColumn key={val}>{val}</TableColumn>
                      )}
                    </TableRow>
                  )
                  : null}

              </TableBody>
            </DataTable>
          </Cell>
        </Grid>
      </Card>
    )
  }
  return (
    <DefaultLayout>
      <UserReport />
      <EncodedReport />
      <NewsReport />
    </DefaultLayout>
  )

}
const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  userData: state.user.user,
  selectedCompany: state.user.selectedCompany
})


export default connect(
  mapStateToProps,
  null
)(AssetReport)