import { GET_STATIONS, CLEAR_SELECTED_STATION_STATIONS_REPORT } from './types';
import { fetchStations } from '../services/stations';

export const getStationsData = params => {
  return async dispatch => {
    dispatch({ type: CLEAR_SELECTED_STATION_STATIONS_REPORT });
    try {
      const { stations = [] } = await fetchStations(params);
      const stationsList = stations.map(item => item.full_name);
      dispatch({ type: GET_STATIONS, stations: stationsList });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`Unable to get the companies from the database: ${err}`);
      throw err;
    }
  };
};
