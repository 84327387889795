import AuthenticationService from '../services/authentication';

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SELECT_COMPANY,
} from './types';

export const loginRequest = payload => dispatch =>
  AuthenticationService.logIn(payload)
    .then(response => {
      dispatch({ type: LOGIN_SUCCESS, payload: response.user });
      return true;
    })
    .catch(error => {
      dispatch({ type: LOGIN_FAILURE, payload: error });
      return false;
    });

export const logoutRequest = () => dispatch =>
  AuthenticationService.logOut()
    .then(response => {
      dispatch({ type: LOGOUT_SUCCESS, payload: response.user });
      return true;
    })
    .catch(error => {
      dispatch({ type: LOGOUT_FAILURE, payload: error });
      return false;
    });

export const selectCompany = payload => dispatch => {
  // eslint-disable-next-line no-undef
  localStorage.setItem('selected_company', JSON.stringify({ value: payload.selectedCompany }));
  dispatch({ type: SELECT_COMPANY, payload: payload.selectedCompany });
}
