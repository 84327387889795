import React, { useState, useEffect } from 'react';
import { DefaultLayout } from '../../components';
import { Button, Cell, Card, CardTitle, CardText, Grid, TextField, SelectField, FileUpload, Media, DatePicker, Switch, ExpansionList, ExpansionPanel, 
    DataTable, TableHeader, TableBody, TableRow, TableColumn, TablePagination, FontIcon, DialogContainer } from 'react-md';
import moment from 'moment';
import { connect } from 'react-redux';
import { postCompany, patchCompany } from '../../services/companies';

const Billing = (props) => {

    const [companies, setCompanies] = useState([]);
    const [idPatch, setIdPatch] = useState(null);
    const [customFields, setCustomFields] = useState({});
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [priceType, setPriceType] = useState('COL');
    const [servicesDinamicFields, setServicesDinamicFields] = useState([]);
    const [logoFile, setLogoFile] = useState(null);
    // Pagination
    const [sliceData, setSliceData] = useState([]);
    const [rows, setRows] = useState(0);
    // Operation Control
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setCompanies(props.companies);
    }, [props.companies]);

    useEffect(() => {
        setSliceData(companies.slice(0, 10));
        setRows(companies.length);
    }, [companies]);

    const handlePagination = (start, rowsPerPage) => {
        setSliceData(companies.slice(start, start + rowsPerPage));
    }

    const getValuesOnFields = (item) => {
        setIdPatch(item.id);
        item.custom_fields && setCustomFields(item.custom_fields);
        setType(item.type);
        setName(item.name);
        setState(item.state);
        item.custom_fields ? setCountry(item.custom_fields.country) : setCountry('');
        item.custom_fields && item.custom_fields.services !== undefined ? setServicesDinamicFields(item.custom_fields.services) : setServicesDinamicFields([]);
        setIsEditing(true);
    }

    const clearValuesOnFields = () => {
        setType('');
        setName('');
        setState('');
        setCountry('');
        setServicesDinamicFields([]);
        setLogoFile(null);
        setIsEditing(false);
    }

    const addServiceFields = () => {
        const inputsArray = [...servicesDinamicFields];
        inputsArray.push({price: '', currency: priceType, description: '', paid: false, date_paid: null, service_start: null, service_end: null});
        
        setServicesDinamicFields(inputsArray);
    }

    const removeServiceFields = (index) => {
        const inputsArray = [...servicesDinamicFields];
        inputsArray.splice(index, 1);

        setServicesDinamicFields(inputsArray);
    }

    const handlePriceOnChange = (value, index) => {
        const inputsArray = [...servicesDinamicFields];
        inputsArray[index].price = value;

        setServicesDinamicFields(inputsArray);
    }

    const handleDescriptionOnChange = (value, index) => {
        const inputsArray = [...servicesDinamicFields];
        inputsArray[index].description = value;

        setServicesDinamicFields(inputsArray);
    }

    const handlePaidOnChange = (checked, index) => {
        const inputsArray = [...servicesDinamicFields];
        inputsArray[index].paid = checked;

        if (!checked) {
            inputsArray[index].date_paid = null;
        }

        setServicesDinamicFields(inputsArray);
    }

    const handleDatePaidOnChange = (value, index) => {
        const inputsArray = [...servicesDinamicFields];
        inputsArray[index].date_paid = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');

        setServicesDinamicFields(inputsArray);
    }

    const handleServiceStartOnChange = (value, index) => {
        const inputsArray = [...servicesDinamicFields];
        inputsArray[index].service_start = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');

        setServicesDinamicFields(inputsArray);
    }

    const handleServiceEndOnChange = (value, index) => {
        const inputsArray = [...servicesDinamicFields];
        inputsArray[index].service_end = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');

        setServicesDinamicFields(inputsArray);
    }

    const handleLoad = (uploadedFile, uploadedData) => {
        const { name, size, type, lastModified } = uploadedFile;
        const file = {
          name,
          size,
          type,
          data: uploadedData,
          lastModified: new Date(lastModified),
        }

        setLogoFile(file);
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isEditing) {
            const data = {
                type,
                state,
                name,
                custom_fields: {
                    header_logo: '',
                    country,
                    services: servicesDinamicFields
                },
            }

            postCompany(data)
            .then(response => {
                console.log('response ', response.company);
                const newCompanies = [...companies];
                newCompanies.push(response.company);

                setCompanies(newCompanies);
            });
        }
        else {
            const params = {
                id: idPatch
            }

            const data = {
                type,
                state,
                name,
                custom_fields: {
                    ...customFields,
                    header_logo: '',
                    country,
                    services: servicesDinamicFields
                },
            }

            patchCompany(params, data)
            .then(response => {
                console.log('response ', response.company);
                const newCompanies = [...companies];
                const index = newCompanies.map(item => {return item.id}).indexOf(idPatch);
                newCompanies[index] = response.company;

                setCompanies(newCompanies);
            });
        }
    }

    return (
        <DefaultLayout>
            <Grid>
                <Cell size={12}>
                    <Card>
                        <CardTitle title='Cliente y facturación'/>
                        <CardText>
                            <form onSubmit={handleSubmit}>
                                <Grid>
                                    <Cell size={12}>
                                        <Grid>
                                            <SelectField
                                                required
                                                id="type"
                                                name="type"
                                                label="Tipo de institución"
                                                placeholder="Tipo"
                                                className="md-cell md-cell--bottom"
                                                menuItems={[
                                                    {
                                                        label: 'Activo',
                                                        value: 'assets',
                                                    },
                                                    {
                                                        label: 'Biblioteca',
                                                        value: 'library',
                                                    }
                                                ]}
                                                value = {type}
                                                onChange={(value) => setType(value)}
                                            />
                                            <TextField
                                                required
                                                id="name"
                                                name="name"
                                                label="Nombre de la institución"
                                                lineDirection="center"
                                                placeholder="Nombre"
                                                className="md-cell md-cell--bottom"
                                                value={name}
                                                onChange={(value) => setName(value)}
                                            />
                                            <SelectField
                                                required
                                                id="state"
                                                name="state"
                                                label="Estado"
                                                placeholder="Estado"
                                                className="md-cell md-cell--bottom"
                                                menuItems={[
                                                    {
                                                        label: 'Activo',
                                                        value: 'active',
                                                    },
                                                ]}
                                                value = {state}
                                                onChange={(value) => setState(value)}
                                            />
                                            <SelectField
                                                required
                                                id="country"
                                                name="country"
                                                label="Seleccione el país"
                                                placeholder="País"
                                                className="md-cell md-cell--bottom"
                                                menuItems={[
                                                    {
                                                        label: 'Colombia',
                                                        value: 'Colombia',
                                                    },
                                                ]}
                                                value = {country}
                                                onChange={(value) => setCountry(value)}
                                            />
                                        </Grid>
                                    </Cell>
                                    <Cell size={12}>
                                        <Grid style={{alignItems: 'center'}}>
                                            <SelectField
                                                required
                                                id="priceType"
                                                name="priceType"
                                                label="Tipo de moneda"
                                                placeholder="Moneda"
                                                className="md-cell md-cell--bottom"
                                                menuItems={[
                                                    {
                                                        label: 'COL',
                                                        value: 'COL',
                                                    },
                                                    {
                                                        label: 'US',
                                                        value: 'US',
                                                    },
                                                ]}
                                                value = {priceType}
                                                onChange={(value) => setPriceType(value)}
                                            />
                                            <Button 
                                                icon 
                                                onClick={() => addServiceFields()}
                                            >
                                                add
                                            </Button>
                                        </Grid>
                                    </Cell>
                                    {servicesDinamicFields.map((item, i) => 
                                        <Cell size={12} key={i} style={{border: '1px solid #ddd'}}>
                                            <Button 
                                                icon 
                                                onClick={() => removeServiceFields(i)}
                                            >
                                                remove
                                            </Button>
                                            <Grid style={{alignItems: 'center'}}>
                                                <TextField
                                                    required
                                                    id={'price' + i}
                                                    name='price'
                                                    lineDirection="center"
                                                    placeholder={'Valor en ' + item.currency}
                                                    className="md-cell md-cell--bottom"
                                                    onChange={(value) => handlePriceOnChange(value, i)}
                                                    value={item.price}
                                                />
                                                <TextField
                                                    required
                                                    id={'description' + i}
                                                    name='description'
                                                    lineDirection="center"
                                                    placeholder='Ingrese el concepto del servicio'
                                                    className="md-cell md-cell--bottom"
                                                    onChange={(value) => handleDescriptionOnChange(value, i)}
                                                    value={item.description}
                                                />
                                                <Switch
                                                    id={'paid' + i}
                                                    name='paid'
                                                    label={item.paid ? 'Pagado' : 'No pagado'}
                                                    onChange={(checked) => handlePaidOnChange(checked, i)}
                                                    checked={item.paid}
                                                />
                                            </Grid>
                                            <Grid>
                                                {item.paid && <DatePicker
                                                    id={'date_paid' + i}
                                                    name='date_paid'
                                                    label="Fecha de pago"
                                                    onChange={(value) => handleDatePaidOnChange(value, i)}
                                                    value={item.date_paid ? moment(item.date_paid, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
                                                />}
                                                <DatePicker
                                                    id={'service_start' + i}
                                                    name='service_start'
                                                    label="Fecha de inicio"
                                                    onChange={(value) => handleServiceStartOnChange(value, i)}
                                                    value={item.service_start ? moment(item.service_start, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
                                                />
                                                <DatePicker
                                                    id={'service_end' + i}
                                                    name='service_end'
                                                    label="Fecha final"
                                                    onChange={(value) => handleServiceEndOnChange(value, i)}
                                                    value={item.service_end ? moment(item.service_end, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
                                                />
                                            </Grid>
                                        </Cell>
                                    )}
                                    <Cell size={12}>
                                        <Grid>
                                            <FileUpload
                                                id="LogoFile-upload"
                                                accept={".png, .jpeg"}
                                                name="logoFile"
                                                label="Cargar logo"
                                                onLoad={handleLoad}
                                                primary
                                                allowDuplicates
                                            />
                                        </Grid>
                                    </Cell>
                                    {logoFile ? 
                                        <Cell size={4}>
                                            <Card>
                                                <Button 
                                                    icon 
                                                    onClick={() => setLogoFile(null)}
                                                >
                                                    close
                                                </Button>
                                                <CardText>
                                                    <Media aspectRatio='1-1'>
                                                        <img src={logoFile.data} alt='logoImage'/>
                                                    </Media>
                                                    <strong>{logoFile.name}</strong>
                                                    <p>{logoFile.size} bytes</p>
                                                </CardText>
                                            </Card>
                                        </Cell>
                                    : null}
                                </Grid>
                                <Button
                                    type="submit"
                                    flat
                                    className="md-cell md-cell--bottom"
                                    primary
                                    swapTheming
                                >
                                    {isEditing ? 'Editar' : 'Crear'}
                                </Button>
                            </form>
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
            <Grid>
                <Cell size={12}>
                    <Card>
                        <CardTitle title='Consultar'/>
                        <CardText>
                            <Button 
                                icon
                                tooltipLabel="Crear"
                                tooltipDelay={300}
                                onClick={() => clearValuesOnFields()}
                            >
                                add
                            </Button>
                            <DataTable baseId="simple-pagination">
                                <TableHeader>
                                    <TableRow selectable={false}>
                                        <TableColumn>Tipo</TableColumn>
                                        <TableColumn>Nombre</TableColumn>
                                        <TableColumn>Estado</TableColumn>
                                        <TableColumn>País</TableColumn>
                                        <TableColumn>Acciones</TableColumn>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                {sliceData.map((item, i) =>
                                    <TableRow key={i} selectable={false}>
                                        <TableColumn>{item.type}</TableColumn>
                                        <TableColumn>{item.name}</TableColumn>
                                        <TableColumn>{item.state}</TableColumn>
                                        <TableColumn>{item.custom_fields && item.custom_fields.country}</TableColumn>
                                        <TableColumn>
                                            <Button 
                                                icon
                                                tooltipLabel="Editar"
                                                tooltipDelay={300}
                                                onClick={() => getValuesOnFields(item)}
                                            >
                                                edit
                                            </Button>
                                        </TableColumn>
                                    </TableRow>
                                )}
                                </TableBody>
                                <TablePagination rows={rows} rowsPerPageLabel={'Filas por página'} onPagination={handlePagination} />
                            </DataTable>
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        </DefaultLayout>
    );
}

const mapStateToProps = state => ({
    companies: state.companies.companies
});

export default connect(mapStateToProps)(Billing);