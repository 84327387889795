import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Cell } from 'react-md';

import Header from '../header/header';
import NavList from '../navigation/navList';
import { BillingToast } from '../';
import {
  selectCompany,
  getCompaniesData,
  getCollectionsData,
  getStationsData,
} from '../../actions';

class DefaultLayout extends Component {
  componentDidMount() {
    const { getCompanies } = this.props;
    getCompanies();
  }

  componentDidUpdate(prevProps) {
    const { companies, selectedCompany, getCollections, getStations } = this.props;
    if (
      prevProps.companies.length !== companies.length ||
      prevProps.selectedCompany !== selectedCompany
    ) {
      getCollections({ company: selectedCompany });
      getStations({ company: selectedCompany });
    }
  }

  onChangePicker = value => {
    const { selectCompanyConnect } = this.props;
    selectCompanyConnect(value);
  };

  render() {
    const {
      userData,
      companies = [],
      selectedCompany = 0,
      loadingCameras,
      loadingCopiesSummary,
      loadingPersonsCounterSummary,
      loadingStationsActivitySummary,
      children,
    } = this.props;
    const { companies: userCompanies = [] } = userData;

    const userCompaniesObjects = [];
    if (companies.length > 0 && userCompanies.length > 0) {
      userCompanies.forEach(item => {
        const found = companies.find(element => {
          return element.id === item;
        });
        userCompaniesObjects.push({
          value: found.id,
          label: found.name,
          custom_fields: found.custom_fields,
        });
      });
    }

    const pickerDisable =
      loadingCameras ||
      loadingCopiesSummary ||
      loadingPersonsCounterSummary ||
      loadingStationsActivitySummary;

    return (
      <>
        <BillingToast/>
        <Header
          pickerMenuItems={userCompaniesObjects}
          pickerValue={selectedCompany}
          pickerOnChange={this.onChangePicker}
          pickerDisable={pickerDisable}
          userData={userData}
        />
        <Grid>
          <Cell size={2}>
            <NavList />
          </Cell>
          <Cell size={10}>{children}</Cell>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.user.user,
  selectedCompany: state.user.selectedCompany,
  companies: state.companies.companies,
  loadingCameras: state.cameras.loadingCamerasByCompany,
  loadingCopiesSummary: state.summaries.loadingCopiesSummary,
  loadingPersonsCounterSummary: state.summaries.loadingPersonsCounterSummary,
  loadingStationsActivitySummary: state.summaries.loadingStationsActivitySummary,
});

const mapDispatchToProps = dispatch => ({
  selectCompanyConnect: value => dispatch(selectCompany({ selectedCompany: value })),
  getCompanies: () => dispatch(getCompaniesData()),
  getCollections: params => dispatch(getCollectionsData(params)),
  getStations: params => dispatch(getStationsData(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayout);
