import {
  GET_COPIES_SUMMARY,
  LOADING_COPIES_SUMMARY,
  COPIES_SUMMARY_ERROR,
  GET_PERSONS_COUNTER_SUMMARY,
  PERSONS_COUNTER_SUMMARY_ERROR,
  LOADING_PERSONS_COUNTER_SUMMARY,
  CLEAN_PERSONS_COUNTER_DATA,
  GET_STATIONS_ACTIVITY_SUMMARY,
  LOADING_STATIONS_ACTIVITY_SUMMARY,
  STATIONS_ACTIVITY_ERROR,
} from './types';
import {
  getPersonsCounterSummary,
  getStationsActivitySummary,
  getTotalCopiesSummary,
} from '../services/summaries';
import { capitalize } from '../utils/textUtils';
import { dateFormat } from '../utils/moment';
import store from '../store';

import selfreturn from '../assets/selfreturn.png';
import selfloan from '../assets/selfloan.png';

export const fetchingCopiesSummary = () => {
  return async dispatch => {
    try {
      dispatch({ type: LOADING_COPIES_SUMMARY, loadingCopiesSummary: true });

      const company = store.getState().user.selectedCompany;
      const params = { company };
      const copiesSummary = await getTotalCopiesSummary(params);

      const total = [];
      const notEncoded = [];
      copiesSummary.forEach(copy => {
        total.push({ x: copy.location, y: copy.total });
        notEncoded.push({ x: copy.location, y: copy.not_encoded });
      });

      dispatch({ type: GET_COPIES_SUMMARY, totalCopies: total, notEncodedCopies: notEncoded });
    } catch (err) {
      dispatch({ type: COPIES_SUMMARY_ERROR, copiesSummaryError: true });
      // eslint-disable-next-line no-console
      console.log(`Unable to get the copies summary from the database: ${err}`);
      throw err;
    }
  };
};

export const fetchingPersonsCounterSummary = params => {
  return async dispatch => {
    try {
      dispatch({ type: LOADING_PERSONS_COUNTER_SUMMARY, loadingPersonsCounterSummary: true });

      const company = store.getState().user.selectedCompany;
      const paramsEndpoint = { company, camera_name: params.camera, type: params.type };
      const summary = await getPersonsCounterSummary(paramsEndpoint);
      const inData = [];
      const outData = [];

      summary.forEach(item => {
        const date = dateFormat(item.start_time, 'MMMM Do dddd');
        inData.push({ x: capitalize(date), y: item.in });
        outData.push({ x: capitalize(date), y: item.out });
      });

      const inDataReversed = inData.reverse();
      const outDataReversed = outData.reverse();

      dispatch({
        type: GET_PERSONS_COUNTER_SUMMARY,
        inData: inDataReversed,
        outData: outDataReversed,
      });
    } catch (err) {
      dispatch({ type: PERSONS_COUNTER_SUMMARY_ERROR, personsCounterError: true });
      // eslint-disable-next-line no-console
      console.log(`Unable to get the persons counter summary from the database: ${err}`);
      throw err;
    }
  };
};

export const cleanPersonsCounterData = () => dispatch => {
  dispatch({ type: CLEAN_PERSONS_COUNTER_DATA });
};

export const fetchingStationsActivitySummary = () => {
  return async dispatch => {
    try {
      dispatch({ type: LOADING_STATIONS_ACTIVITY_SUMMARY, loadingStationsActivitySummary: true });

      const company = store.getState().user.selectedCompany;
      const params = { company };
      const summary = await getStationsActivitySummary(params);

      const summaryArray = summary.map(item => {
        const today = [];
        const month = [];
        let image = null;
        let type = '';
        if (item.station.startsWith('SELFRETURN')) {
          image = selfreturn;
          type = 'Autodevolución';
        } else {
          image = selfloan;
          type = 'Autopréstamo';
        }
        const stationName = item.station.split('-')[1];
        item.operation.forEach(operation => {
          let name = '';
          switch (operation.name) {
            case 'DEVOLUTION':
              name = 'Devoluciones';
              break;
            case 'LOAN':
              name = 'Préstamos';
              break;
            case 'RENEW':
              name = 'Renovaciones';
              break;
            default:
              name = 'Devoluciones';
          }
          today.push({ name, value: operation.today });
          month.push({ name, value: operation.month });
        });
        return {
          today,
          month,
          image,
          type,
          name: stationName,
          ...item,
        };
      });

      dispatch({ type: GET_STATIONS_ACTIVITY_SUMMARY, stationsActivity: summaryArray });
    } catch (err) {
      dispatch({ type: STATIONS_ACTIVITY_ERROR, stationsActivityError: true });
      // eslint-disable-next-line no-console
      console.log(`Unable to get the stations activity summary from the database: ${err}`);
      throw err;
    }
  };
};
