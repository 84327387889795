import React, { useState, useEffect } from 'react';
import { FlexibleXYPlot, XAxis, YAxis, HorizontalGridLines, LineMarkSeries, DiscreteColorLegend, Hint } from 'react-vis';
import 'react-vis/dist/style.css';

function DynamicLineSeriesChart (props) {
    const [count, setCount] = useState([]);
    const [items, setItems] = useState([]);
    const [hintPositionX, setHintPositionX] = useState(0);
    const [values, setValues] = useState({});

    useEffect(() => {
        const countAux = [];
        const itemsAux = [];

        props.data.forEach(item => {
            countAux.push(item.count);
            itemsAux.push({title: item.title, color: item.color});
        });

        setCount(countAux);
        setItems(itemsAux);
    }, [props.data]);

    const onNearestX = (datapoint, { innerX }, item) => {
        const valuesAux = {};

        valuesAux['Módulo'] = item.name;
        valuesAux['Fecha'] = datapoint.x;
        valuesAux[item.title] = datapoint.y;
        valuesAux['Mensaje'] = datapoint.message;

        setHintPositionX(innerX);
        setValues(valuesAux);
    }

    return (
        <FlexibleXYPlot
            { ...( Math.max(...count) > 40 && { width: 8000 } ) }
            height={props.height}
            margin={props.margin}
            xType="ordinal"
            yType="linear"
            yDomain={[9, 0]}
        >
            <HorizontalGridLines />
            {props.data.map((item, i) => <LineMarkSeries
                key={i}
                curve='curveMonotoneX'
                data={item.data}
                color={item.color}
                onNearestX={(datapoint, { innerX }) => onNearestX(datapoint, { innerX }, item)}
            />)}
            <XAxis tickLabelAngle={-90}/>
            <YAxis />
            <DiscreteColorLegend
                style={{
                    position: 'absolute',
                    alignSelf: 'center',
                    left: '42%',
                    top: '-10px',
                }}
                orientation="horizontal"
                items={items}
            />
            <Hint
                xType="literal"
                yType="literal"
                value={values}
                style={{ top: 0, marginLeft: hintPositionX }}
            />
        </FlexibleXYPlot>
    );
}

export default DynamicLineSeriesChart;