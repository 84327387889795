import moment from 'moment';
import {
  GET_PERSONS_COUNT_REPORT,
  TOGGLE_LOADING_PERSONS_COUNT_REPORT,
  PAGINATE_PERSONS_COUNT_REPORT,
  SET_DATE_TIME_PERSONS_COUNT_REPORT,
  GET_NOT_ENCODED_REPORT,
  PAGINATE_NOT_ENCODED_REPORT,
  TOGGLE_LOADING_NOT_ENCODED_REPORT,
  HANDLE_PICKER_CHANGE,
  GET_STATIONS_REPORT,
  PAGINATE_STATIONS_REPORT,
  TOGGLE_LOADING_STATIONS_REPORT,
  HANDLE_PICKER_CHANGE_STATIONS_REPORT,
} from './types';
import {
  fetchPersonsCountReport,
  fetchNotEncodedReport,
  fetchStationsReport,
} from '../services/reports';
import 'moment/locale/es';

moment.locale('es');

export const getPersonsCountReport = params => {
  return async dispatch => {
    dispatch({ type: TOGGLE_LOADING_PERSONS_COUNT_REPORT, payload: true });
    try {
      const personsCountReport = await fetchPersonsCountReport(params);
      const displayData = personsCountReport.map(item => ({
        Inicio: moment(item.start_time).format('LLL'),
        Fin: moment(item.end_time).format('LLL'),
        Entraron: item.in,
        Salieron: item.out,
      }));
      dispatch({ type: GET_PERSONS_COUNT_REPORT, payload: displayData });
      return;
    } catch (err) {
      alert('Hubo un error obteniendo la información de Reporte de Conteo de Personas.');
      // eslint-disable-next-line no-console
      console.log(`Unable to get the Persons Count Report from the database: ${err}`);
      dispatch({ type: TOGGLE_LOADING_PERSONS_COUNT_REPORT, payload: false });
      throw err;
    }
  };
};

export const paginatePersonsCountReport = params => {
  const { startRow, rowsPerPage } = params;
  return dispatch => {
    dispatch({
      type: PAGINATE_PERSONS_COUNT_REPORT,
      startRow,
      rowsPerPage,
    });
  };
};

export const setDateAndTimePersonsCountReport = params => {
  const { pickerValue, pickerId } = params;
  return dispatch => {
    dispatch({
      type: SET_DATE_TIME_PERSONS_COUNT_REPORT,
      pickerValue,
      pickerId,
    });
  };
};

export const getNotEncodedReport = params => {
  return async dispatch => {
    dispatch({ type: TOGGLE_LOADING_NOT_ENCODED_REPORT, payload: true });
    try {
      const notEncodedReport = await fetchNotEncodedReport(params);
      const displayData = notEncodedReport.map(item => {
        if (item.tag_id === null) {
          return(
            {
            'Tag Id': '',
            'Código de Barras': item.serial,
            'Título': item.name,
            'Colección': item.location,
            'Estado': item.status,
            'Autor': item.autor,
            'count': item.count,
          })
        }
        else {
          return(
            {
            'Tag Id': item.tag_id,
            'Código de Barras': item.serial,
            'Título': item.name,
            'Colección': item.location,
            'Estado': item.status,
            'Autor': item.autor,
            'count': item.count,
          })
        }
      });
      dispatch({ type: GET_NOT_ENCODED_REPORT, payload: displayData });
    } catch (err) {
      alert('Hubo un error obteniendo la información de Reporte de No Asociados.');
      // eslint-disable-next-line no-console
      console.log(`Unable to get the Not Encoded Report from the database: ${err}`);
      dispatch({ type: TOGGLE_LOADING_NOT_ENCODED_REPORT, payload: false });
      throw err;
    }
  };
};

export const paginateNotEncodedReport = params => {
  const { startRow, rowsPerPage } = params;
  return dispatch => {
    dispatch({
      type: PAGINATE_NOT_ENCODED_REPORT,
      startRow,
      rowsPerPage,
    });
  };
};

export const handlePickerChangeNotEncodedReport = params => {
  const { pickerValue, pickerId } = params;
  return dispatch => {
    dispatch({
      type: HANDLE_PICKER_CHANGE,
      pickerValue,
      pickerId,
    });
  };
};

export const getStationsReport = params => {
  return async dispatch => {
    dispatch({ type: TOGGLE_LOADING_STATIONS_REPORT, payload: true });
    try {
      const stationsReport = await fetchStationsReport(params);
      const displayData = stationsReport.map(item => ({
        Fecha: moment(item.inserted_at).format('LLL'),
        Usuario: item.user,
        'Código de Barras': item.barcode,
        'Operación': item.operation,
        'Estación': item.station,
        Resultado: item.result,
        Mensaje: item.message,
      }));
      dispatch({ type: GET_STATIONS_REPORT, payload: displayData });
    } catch (err) {
      alert('Hubo un error obteniendo la información de Reporte de Estaciones.');
      // eslint-disable-next-line no-console
      console.log(`Unable to get the Stations Report from the database: ${err}`);
      dispatch({ type: TOGGLE_LOADING_STATIONS_REPORT, payload: false });
      throw err;
    }
  };
};

export const paginateStationsReport = params => {
  const { startRow, rowsPerPage } = params;
  return dispatch => {
    dispatch({
      type: PAGINATE_STATIONS_REPORT,
      startRow,
      rowsPerPage,
    });
  };
};

export const handlePickerChangeStationsReport = params => {
  const { pickerValue, pickerId } = params;
  return dispatch => {
    dispatch({
      type: HANDLE_PICKER_CHANGE_STATIONS_REPORT,
      pickerValue,
      pickerId,
    });
  };
};
