export const hasCompany = (company, types) => (
    types.some(type => company.type === type)
);

export const hasRole = (userData, roles) => (
    roles.some(role => userData.role === role)
);

export const isAllowed = (userData, rights) => (
    rights.some(right => userData.status.split(',').includes(right))
);