// export const baseUrl = 'http://52.14.118.191:4000/';
export const baseUrl = 'https://backend-bibliotecas.geniatecnologia.co:5000/';  // Certificated SSL
export const baseUrlAmazon = 'https://danxmqij4i.execute-api.us-east-2.amazonaws.com/';

function serialize(obj) {
  const serialzedParams = Object.keys(obj)
    .reduce((accumulator, currentValue) => {
      accumulator.push(`${currentValue}=${encodeURIComponent(obj[currentValue])}`);
      return accumulator;
    }, [])
    .join('&');

  return `?${serialzedParams}`;
}

function serializeNoEncoding(obj) {
  const serialzedParams = Object.keys(obj)
    .reduce((accumulator, currentValue) => {
      accumulator.push(`${currentValue}=${obj[currentValue]}`);
      return accumulator;
    }, [])
    .join('&');

  return `?${serialzedParams}`;
}

export const sessionEndpoint = () => `${baseUrl}sessions`;

export const appHealthAmazonEndpoint = (params) => `${baseUrlAmazon}dev/all${serialize(params)}`;

export const campusEndpoint = params => 
  `${baseUrl}/copies/index_campus/${serialize(params)}`;

export const totalCopiesSummaryEndpoint = params =>
  `${baseUrl}copies/total_copies_summary/${serialize(params)}`;

export const totalEncodeSummaryEndpoint = params =>
  `${baseUrl}copies/total_encode_summary/${serialize(params)}`;

export const totalPersonsCounterSummaryEndpoint = params =>
  `${baseUrl}persons/summary/${serialize(params)}`;

export const stationsActivitySummaryEndpoint = params =>
  `${baseUrl}station_activities/summary/${serialize(params)}`;

export const encodeReportEndpoint = params =>
  `${baseUrl}copies/encode_report/${serializeNoEncoding(params)}`;

export const companiesEndpoint = (params) => `${baseUrl}companies/${params ? params.id : ''}`;

export const collectionsEndpoint = params => `${baseUrl}copies/location_list/${serialize(params)}`;

export const camerasEndpoint = params => `${baseUrl}company_persons/${serialize(params)}`;

export const personsCountReportEndpoint = params =>
  `${baseUrl}persons/report/${serializeNoEncoding(params)}`;

export const stationsEndpoint = params =>
  `${baseUrl}station_activities/current_stations/${serialize(params)}`;

export const stationsReportEndpoint = params => `${baseUrl}station_activities/${serialize(params)}`;

export const stationsReportLapseEndpoint = params => 
  `${baseUrl}station_activities/samples_report${serialize(params)}`;

export const assetsEndpoint = params => `${baseUrl}copies/${params ? serialize(params) : ''}`;

export const assetsLowerAmountEndpoint = params =>
  `${baseUrl}copies/custom_fields/${params ? serialize(params) : ''}`;

export const assetsReportEndpoint = params =>
  `${baseUrl}copies/encode_report_by_user${params ? serialize(params) : ''}`;

export const assetsEncodedReportEndpoint = params =>
  `${baseUrl}copies/encode_report${params ? serialize(params) : ''}`;

export const assetsNewsReportEndpoint = params =>
  `${baseUrl}copies/encode_notes_report${params ? serialize(params) : ''}`;

export const assetsPutEndpoint = params => `${baseUrl}copies/${params}`;

export const fieldsEndpoint = params => `${baseUrl}fields/${params ? serialize(params) : ''}`;

export const fieldsDropdownEndpoint = params =>
  `${baseUrl}${params.table}/${params ? serialize(params) : ''}`;

export const fieldsDropdownNotSerializeEndpoint = params =>
  `${baseUrl}${params.table}/${params.id}`;

export const inventoryEndpoint = params => `${baseUrl}inventories/${serialize(params)}`;

export const inventoryDeleteEndpoint = params => `${baseUrl}inventories/${params}`;

export const inventorySummaryEndpoint = params =>
  `${baseUrl}inventories/general_summary/${serialize(params)}`;

export const inventoryTotalEndpoint = params =>
  `${baseUrl}inventory_details/total_inventory_download/${serialize(params)}`;

export const generalAssetsReportEndpoint = params =>
  `${baseUrl}copies/total_report_download${params ? serialize(params) : ''}`;

// ---- //

export const generalReportsLoanEndpoint = (params, monthly) => {
  if (monthly) {
    return `${baseUrl}general_report_loans/monthly${serialize(params)}`;
  }
  return `${baseUrl}general_report_loans/${serialize(params)}`;
};

export const userTracesEndpoint = params => `${baseUrl}user_traces/${serialize(params)}`;

export const getSystemUsersEndpoint = () => `${baseUrl}users`;

export const getSystemUserProfileEndpoint = () => `${baseUrl}users/me`;

export const updateSystemUserProfileEndpoint = () => `${baseUrl}users/me`;

export const addSystemUsersEndpoint = () => `${baseUrl}users`;

export const removeSystemUsersEndpoint = id => `${baseUrl}users/${id}`;
