import _moment from 'moment';
import 'moment/locale/es';

export const dateFormat = (date, format) => {
  return _moment(date)
    .locale('es')
    .format(format);
};

export const getCurrentMonth = () => {
  return _moment()
    .locale('es')
    .format('MMMM');
};
