import { GET_COLLECTIONS } from './types';
import { fetchCollections } from '../services/collections';

export const getCollectionsData = params => {
  return async dispatch => {
    try {
      const { collectionsObjects = [] } = await fetchCollections(params);
      const collections = collectionsObjects.map(item => item.location);
      dispatch({ type: GET_COLLECTIONS, collections });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`Unable to get the companies from the database: ${err}`);
      throw err;
    }
  };
};
