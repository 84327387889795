import {
  GET_PERSONS_COUNT_REPORT,
  PAGINATE_PERSONS_COUNT_REPORT,
  TOGGLE_LOADING_PERSONS_COUNT_REPORT,
  SET_DATE_TIME_PERSONS_COUNT_REPORT,
} from '../actions/types';

const initialState = {
  loadingPersonsCountReport: false,
  personsCountReport: [],
  slicedPersonsCountReport: [],
  startDatePersonsCount: '',
  startTimePersonsCount: '',
  endDatePersonsCount: '',
  endTimePersonsCount: '',
};

const personsCountReportReducer = (state = initialState, action) => {
  const { type, payload, startRow, rowsPerPage, pickerId, pickerValue } = action;
  switch (type) {
    case GET_PERSONS_COUNT_REPORT:
      return {
        ...state,
        personsCountReport: payload,
        slicedPersonsCountReport: payload.slice(0, 10),
        loadingPersonsCountReport: false,
      };
    case PAGINATE_PERSONS_COUNT_REPORT:
      return {
        ...state,
        slicedPersonsCountReport: state.personsCountReport.slice(startRow, startRow + rowsPerPage),
      };
    case TOGGLE_LOADING_PERSONS_COUNT_REPORT:
      return {
        ...state,
        loadingPersonsCountReport: payload,
      };
    case SET_DATE_TIME_PERSONS_COUNT_REPORT:
      return {
        ...state,
        [pickerId]: pickerValue,
      };
    default:
      return state;
  }
};

export default personsCountReportReducer;
