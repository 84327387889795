import { camerasEndpoint } from './config/endpoints';

import { optionsGET } from './config/options';

export const getCamerasByCompany = async params => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(camerasEndpoint(params), optionsGET());
    if (response.status === 401) {
      return { status: 'unauthorized' };
    }
    const cameras = await response.json();
    return { cameras };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};
