import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Cell, Card, CardTitle, CardText, Grid, SelectField, TextField, DialogContainer } from 'react-md';

import moment from 'moment';
import { session } from '../../services/config/options';
import { DefaultLayout } from '../../components';
import {
  getPersonsCountReport,
  paginatePersonsCountReport,
  setDateAndTimePersonsCountReport,
} from '../../actions';

import 'moment/locale/es';
import {
  postAsset,
  getAssets,
  searchAssetsService,
  searchAssetsIdService,
  searchAssetsSerialService,
  putAsset,
  getFields,
  getFieldDropdown,
} from '../../services/assets';
import AssetsTable from './table';
import CustomFields from './customFields';

moment.locale('es');

const styles = {
  card: {
    borderRadius: '2px',
  },
};

class Assets extends Component {
  state = {
    showDialogConfirm: false,
    queryDone: false,
    tag_id: '',
    state: '',
    serial: '',
    name: '',
    location: '',
    company: '',
    user: this.props.userData.username,
    finish_date: '',
    selectedCompany: this.props.selectedCompany,
    assets: [],
    customFields: [],
    otherCustomFields: {},
    mainLocationLabel: '',    // label de la ubicacion completa
    mainLocationField: undefined,
    mainDropdownList: [],
    mainValue: '',
    floorLocationField: undefined,
    floorDropdownList: [],
    floorValue: '',
    roomLocationField: undefined,
    roomDropdownList: [],
    roomValue: '',
    concatValues: '',   // concatenar valores de ubicación, ya que el backend así lo requiere
    fillSubLocationsInEdit: false,    // booleano que asegura que el componentDidUpdate se ejecute únicamente en el relleno de campos de ubicacion al editar el activo
  };

  componentDidMount() {
    if (session === null) window.location.reload();
    // this.getAssetList()
    this.getFieldList();
    console.log(this.state.user);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.floorDropdownList !== this.state.floorDropdownList) {
      if(this.state.fillSubLocationsInEdit === true) {
        this.handleFloorSelectChange(this.state.floorValue);
        this.handleRoomSelectChange(this.state.roomValue);
        this.setState({ fillSubLocationsInEdit: false });   // Asegura que el componentDidUpdate no ejecutará los handleSelectChange luego de renderizar.
      }
    }
  }

  handleChange = (value, e) => {
    if (this.state.editing && e.target.name === 'serial') return;
    this.setState({ [e.target.name]: value });
  };

  handleStartDateChange = (value, dateValue, event) => {
    this.setState({ purchase_date: dateValue });
  };

  handlePurchaseDateChange = (value, dateValue, event) => {
    this.setState({ purchase_date: dateValue });
  };

  handleSelectChange = (value, index, event, details) => {
    this.setState({ [details.name]: details.value });
  };

  handleMainSelectChange = (value, index, event, details) => {
    // this.setState({ [details.name]: details.value});

    this.setState({ mainValue: value, concatValues: value });

    if(this.state.mainLocationField.type !== null) {
      const local_id = this.state.mainDropdownList.filter(item => item.name === value)[0];

      if(local_id !== undefined) {
        const params = {
          table: this.state.floorLocationField.table,
          company: this.state.selectedCompany,
          type: this.state.floorLocationField.key,
          local_id: local_id.id,
        }
  
        getFieldDropdown(params)
          .then(resp => {
            this.setState({ floorDropdownList: resp.asset });
          })
          .catch(err => console.log(err));
      }
    }
  };

  handleFloorSelectChange = (value, index, event, details) => {
    // this.setState({ [details.name]: details.value});

    this.setState({ floorValue: value, concatValues: this.state.mainValue + ' / ' + value });

    if(this.state.floorLocationField.type !== null) {
      const local_id = this.state.floorDropdownList.filter(item => item.name === value)[0];

      if(local_id !== undefined) {

        const params = {
          table: this.state.roomLocationField.table,
          company: this.state.selectedCompany,
          type: this.state.roomLocationField.key,
          local_id: local_id.id,
        }
  
        getFieldDropdown(params)
          .then(resp => {
            this.setState({ roomDropdownList: resp.asset });
          })
          .catch(err => console.log(err));
      }
    }
  };

  handleRoomSelectChange = (value, index, event, details) => {
    // this.setState({ [details.name]: details.value});

    this.setState({ roomValue: value, concatValues: this.state.mainValue + ' / ' + this.state.floorValue + ' / ' + value });
  };

  clearFields = () => {
    this.setState({
      tag_id: '',
      state: '',
      serial: '',
      name: '',
      location: '',
      company: '',
      customFields: [],
      otherCustomFields: {},
      mainLocationLabel: '',
      mainValue: '',
      floorValue: '',
      roomValue: '',
      fillSubLocationsInEdit: false,
    });
    this.getFieldList();
  };

  setForEdit = asset => {
    let item = this.state.assets.find(el => el.id === asset.id);

    let mainValue = item.custom_fields.main_location.split(' / ')[0];
    let floorValue = item.custom_fields.main_location.split(' / ')[1];
    let roomValue = item.custom_fields.main_location.split(' / ')[2];

    this.setState({ fillSubLocationsInEdit: true, floorValue: floorValue, roomValue:  roomValue });   // sincronizando para el componentDidUpdate y rellenar los campos de piso y sala

    this.handleMainSelectChange(mainValue);

    this.setState({
      mainLocationLabel: 'Actual: ' + item.custom_fields.main_location,     // label de la ubicacion completa
      editing: true,
      id: item.id,
      tag_id: item.tag_id || '',
      state: item.state || '',
      serial: item.serial || '',
      name: item.name || '',
      location: item.location || '',
      company: item.company || '',
      otherCustomFields: item.custom_fields,
    });

    window.scrollTo(0, 0);
  };

  searchAssets = async code => {
    const { selectedCompany } = this.props;
    try {
      const assets = await searchAssetsService({ company: selectedCompany, location: code });
      this.setState({ assets });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  searchAssetsSerial = async code => {
    const { selectedCompany } = this.props;
    try {
      const assets = await searchAssetsSerialService({ company: selectedCompany, serial: code });
      this.setState({ assets });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  searchAssetsId = async code => {
    const { selectedCompany } = this.props;
    try {
      const assets = await searchAssetsIdService({ company: selectedCompany, id: code });
      this.setState({ assets });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  getAssetList = () => {
    getAssets({ company: this.state.selectedCompany })
      .then(resp => this.setState({ assets: resp.asset.reverse() }))
      .catch(err => console.log(err));
  };

  getFieldList = () => {
    getFields({ company: this.state.selectedCompany })
      .then(resp => this.setState({ customFields: resp.asset.sort((a, b) => a.order > b.order) }, this.setLocationsField))
      .catch(err => console.log(err));
  };

  setLocationsField = () => {
    const locations = this.state.customFields.filter(item => item.key === 'main_location'|| item.key === 'floor_location' || item.key === 'room_location');

    const mainLocation = locations.filter(item => item.key === 'main_location')[0];
    const floorLocation = locations.filter(item => item.key === 'floor_location')[0];
    const roomLocation = locations.filter(item => item.key === 'room_location')[0];

    this.setState({ mainLocationField: mainLocation, floorLocationField: floorLocation, roomLocationField: roomLocation }, this.getMainFieldDropdown);
  }

  getMainFieldDropdown = () => {
    getFieldDropdown({
      table: this.state.mainLocationField.table,
      company: this.state.selectedCompany,
      type: this.state.mainLocationField.key
    })
      .then(resp => {
        this.setState({ mainDropdownList: resp.asset });
      })
      .catch(err => console.log(err));
  }

  setOtherCustomFields = (value, event) => {
    const newState = {
      ...this.state.otherCustomFields,
      [event.target.id]: value,
    };
    this.setState({
      otherCustomFields: newState,
    });
  };

  setOtherCustomFieldsDropdown = (value, index, event, details) => {
    const oldValues = { ...this.state.otherCustomFields };
    oldValues[details.id] = value;
    this.setState({
      otherCustomFields: oldValues,
    });
  };

  setOtherCustomFieldsDate = (value, dateValue, event, id) => {
    const oldValues = { ...this.state.otherCustomFields };
    oldValues[id] = dateValue;
    this.setState({
      otherCustomFields: oldValues,
    });
  };

  onHideDialogConfirm = () => {
    this.setState({ showDialogConfirm: false });
  }

  assetCreating = () => {
    console.log('user', this.state.user);
    if (this.state.user.length <= 0) return window.location.reload();
    return postAsset({
      name: this.state.name,
      state: this.state.state,
      serial: this.state.serial,
      tag_id: this.state.tag_id,
      location: this.state.location,
      company: this.props.selectedCompany,
      custom_fields: {
        ...this.state.otherCustomFields,
        user: this.state.user,
        main_location: this.state.concatValues,
      },
    })
      .then(resp => {
        if (resp.asset.errors) {
          alert('Hubo un error');
        } else {
          alert('El activo ha sido creado');
          this.clearFields();
          this.setState({ assets:[]});
        }
      })
      .catch(error => {
        alert('Ocurrió un error');
        console.log(error);
      });
      //.finally(() => this.getAssetList());
  }

  assetEditing = () => {
    console.log('user', this.state.user);
    if (this.state.user.length <= 0) return window.location.reload();
    return putAsset(this.state.id, {
      id: this.state.id,
      name: this.state.name,
      state: this.state.state,
      serial: this.state.serial,
      tag_id: this.state.tag_id,
      location: this.state.location,
      company: this.props.selectedCompany,
      custom_fields: {
        ...this.state.otherCustomFields,
        user: `${this.state.user}`,
        main_location: this.state.concatValues,
      },
    })
      .then(resp => {
        if (resp.asset.errors) {
          alert('Hubo un error');
        } else {
          alert('El activo ha sido editado');
          this.clearFields();
          this.setState({ editing: false, assets:[]});
        }
      })
      .catch(error => {
        alert('Ocurrió un error');
        console.log(error);
      });
      //.finally(() => this.getAssetList());
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.editing) {
      this.assetEditing();
    } 
    else {
      if (this.state.concatValues !== '') {
        this.assetCreating();
      }
      else {
        this.setState({ showDialogConfirm: true });
      }
    }
  };

  render() {

    return (
      <DefaultLayout>
        <Grid>
          <Cell size={12}>
            <Card style={styles.card}>
              <CardTitle title="Agregar Activo" />
              <CardText>
                <form onSubmit={this.handleSubmit}>
                  <Grid>
                    <Cell size={12}>
                      <Grid>
                        <TextField
                          id="serial"
                          name="serial"
                          value={this.state.serial}
                          label="Código de barras"
                          placeholder="Serial del activo"
                          lineDirection="center"
                          className="md-cell md-cell--bottom"
                          onChange={this.handleChange}
                          required
                        />
                        <TextField
                          id="tag_id"
                          name="tag_id"
                          label="Tag RFID"
                          lineDirection="center"
                          placeholder="Ingrese el ID"
                          className="md-cell md-cell--bottom"
                          value={this.state.tag_id}
                          onChange={this.handleChange}
                        />
                        <TextField
                          id="name"
                          name="name"
                          value={this.state.name}
                          label="Descripción"
                          placeholder="Descripción del activo"
                          lineDirection="center"
                          className="md-cell md-cell--bottom"
                          onChange={this.handleChange}
                          required
                        />
                        <TextField
                          id="location"
                          name="location"
                          value={this.state.location}
                          label="Placa"
                          placeholder="Placa del activo"
                          lineDirection="center"
                          className="md-cell md-cell--bottom"
                          onChange={this.handleChange}
                          required
                        />
                        <SelectField
                          required
                          id="state"
                          name="state"
                          label="Estado"
                          placeholder="Estado del activo"
                          className="md-cell md-cell--bottom"
                          menuItems={[
                            { label: 'Activo', value: '1' },
                            { label: 'Inactivo', value: '0' },
                          ]}
                          value={this.state.state}
                          onChange={this.handleSelectChange}
                          disabled={this.state.addUserLoading}
                        />
                      </Grid>
                    </Cell>
                    <Cell size={12}>
                      <Grid>
                        {this.state.mainLocationField !== undefined ?
                        <SelectField
                          required
                          id="main-location"
                          name="main-location"
                          label="Ubicación"
                          placeholder="Ubicación"
                          className="md-cell md-cell--bottom"
                          menuItems={this.state.mainDropdownList.map(item => {
                            return {
                              label: item.name,
                              value: item.name,
                            };
                          })}
                          value={this.state.mainValue}
                          onChange={this.handleMainSelectChange}
                          disabled={this.state.addUserLoading}
                        />
                        :
                        null}
                        {this.state.floorLocationField !== undefined ? 
                        <SelectField
                          required
                          id="floor-location"
                          name="floor-location"
                          label="Piso"
                          placeholder="Piso"
                          className="md-cell md-cell--bottom"
                          menuItems={this.state.floorDropdownList.map(item => {
                            return {
                              label: item.name,
                              value: item.name,
                            };
                          })}
                          value={this.state.floorValue}
                          onChange={this.handleFloorSelectChange}
                          disabled={this.state.addUserLoading}
                        />
                        :
                        null}
                        {this.state.roomLocationField !== undefined ? 
                        <SelectField
                          required
                          id="room-location"
                          name="room-location"
                          label="Sala"
                          placeholder="Sala"
                          className="md-cell md-cell--bottom"
                          menuItems={this.state.roomDropdownList.map(item => {
                            return {
                              label: item.name,
                              value: item.name,
                            };
                          })}
                          value={this.state.roomValue}
                          onChange={this.handleRoomSelectChange}
                          disabled={this.state.addUserLoading}
                        />
                        :
                        null}
                        {/* {this.state.mainLocationLabel !== '' ?
                        <Cell size={12} offset={1}>
                          <label style={{fontSize: 13, color: 'grey'}}>{this.state.mainLocationLabel}</label>
                        </Cell>
                        :null} */}
                      </Grid>
                    </Cell>
                    <Cell size={12}>
                      <Grid>
                        <CustomFields
                          customFields={this.state.customFields}
                          otherCustomFields={this.state.otherCustomFields}
                          setOtherCustomFields={this.setOtherCustomFields}
                          setOtherCustomFieldsDropdown={this.setOtherCustomFieldsDropdown}
                          setOtherCustomFieldsDate={this.setOtherCustomFieldsDate}
                          company={this.props.selectedCompany}
                        />
                      </Grid>
                    </Cell>
                    <Button
                      type="submit"
                      disabled={this.state.addUserLoading}
                      flat
                      className="md-cell md-cell--bottom"
                      primary
                      swapTheming
                    >
                      {`${this.state.editing ? 'Editar' : 'Agregar'}`} Activo
                    </Button>
                  </Grid>
                </form>
              </CardText>
            </Card>
          </Cell>
        </Grid>
        <DialogContainer
          focusOnMount={false}
          visible={this.state.showDialogConfirm}
          title='Confirmación'
          onHide={() => this.onHideDialogConfirm()}
          id="confirm"
          width={"25%"}
          actions={[
            <Button
              raised
              primary
              onClick={() => {this.assetCreating(); this.onHideDialogConfirm();}}
            >
              Si
            </Button>,
            <Button
              raised
              onClick={() => this.onHideDialogConfirm()}
            >
              No
            </Button>,
          ]}
        >
          <p>¿Está seguro que desea agregar el activo, sin una ubicación establecida?</p>
        </DialogContainer>
        <AssetsTable
          onSearchAssets={this.searchAssets}
          onSearchAssetsSerial={this.searchAssetsSerial}
          onSearchAssetsId={this.searchAssetsId}
          assets={this.state.assets}
          setForEdit={item => this.setForEdit(item)}
          customFields={this.state.customFields}
        />
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  userData: state.user.user,
  selectedCompany: state.user.selectedCompany,
});

const mapDispatchToProps = dispatch => ({
  getPersonsCountReportConnect: params => dispatch(getPersonsCountReport(params)),
  paginatePersonsCountReportConnect: params => dispatch(paginatePersonsCountReport(params)),
  setTimeAndDate: params => dispatch(setDateAndTimePersonsCountReport(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Assets);