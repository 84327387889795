import {
  generalReportsLoanEndpoint,
  userTracesEndpoint,
  getSystemUsersEndpoint,
  addSystemUsersEndpoint,
  removeSystemUsersEndpoint,
  getSystemUserProfileEndpoint,
  updateSystemUserProfileEndpoint,
} from './config/endpoints';

import { optionsGET, optionsPOST, optionsDELETE, optionsPUT } from './config/options';

const loanService = {
  userTraces: () =>
    // eslint-disable-next-line no-undef
    fetch(userTracesEndpoint(), optionsGET()).then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json();
    }),

  generalReportsLoan: params =>
    // eslint-disable-next-line no-undef
    fetch(generalReportsLoanEndpoint(params), optionsGET()).then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json();
    }),

  getSystemUsers: () =>
    // eslint-disable-next-line no-undef
    fetch(getSystemUsersEndpoint(), optionsGET()).then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json();
    }),

  getSystemUserProfile: () =>
    // eslint-disable-next-line no-undef
    fetch(getSystemUserProfileEndpoint(), optionsGET()).then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json();
    }),

  updateSystemUserProfile: data =>
    // eslint-disable-next-line no-undef
    fetch(updateSystemUserProfileEndpoint(), optionsPUT(data)).then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json();
    }),

  addSystemUser: data =>
    // eslint-disable-next-line no-undef
    fetch(addSystemUsersEndpoint(), optionsPOST(data)).then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json();
    }),

  removeSystemUser: id =>
    // eslint-disable-next-line no-undef
    fetch(removeSystemUsersEndpoint(id), optionsDELETE()).then(response => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response;
    }),
};

export default loanService;
