import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getAssetsLowerAmount, getEncodedReport, getGeneralAssetsReport } from '../../../services/assets';
import { FlatOrIconButton } from '../../../components';
import {
    TableRow,
    Cell, Card, CardText, DatePicker, CardTitle, Grid, SelectField,
    Button, DataTable, TableHeader, TableColumn, TablePagination,
} from 'react-md';
import moment from 'moment';
import 'moment/locale/es';
import { TableBody } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import XLSX from 'xlsx';
import { readRemoteFile } from 'react-papaparse';

moment.locale('es');

const styles = {
    card: {
      borderRadius: '2px',
    },
    selectField: {
      width: '100%',
    },
};

function usePrevious (value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export const EncodedReport = () => {

    const { loggedIn, userData, selectedCompany } = useSelector(state => ({
        loggedIn: state.user.loggedIn,
        userData: state.user.user,
        selectedCompany: state.user.selectedCompany || 13
    }));

    let [isLoading, setIsLoading] = useState(false);
    let [encodedReport, setEncodedReport] = useState([]);
    let [encodedReportParams, setencodedReportParams] = useState({});
    let [queryDone, setQueryDone] = useState(false);
    let [excelDone, setExcelDone] = useState(false);
    let [offsetData, setOffsetData] = useState(0);
    let [perPage, setPerPage] = useState(10);
    let [totalItems, setTotalItems] = useState(0);
    let [linkGeneralReports, setLinkGeneralReports] = useState('');
    let [amazonData, setAmazonData] = useState([]);

    const prevPagination = usePrevious({ offsetData, perPage });

    const getEncodedReportFromApi = () => {
        setExcelDone(false);

        let startDateForEndpoint = moment(
            encodedReportParams.start_at,
            'YYYY-MM-DD'
        ).format();
        let endDateForEndpoint = moment(
            encodedReportParams.end_at,
            'YYYY-MM-DD'
        ).format();

        let params = {};

        if (encodedReportParams.type === undefined) {
            alert('Seleccione el tipo de reporte a consultar...');
            return true;
        }
        else {
            setIsLoading(true);
            if (encodedReportParams.start_at !== undefined && encodedReportParams.end_at !== undefined) {
                params = {
                    company: selectedCompany,
                    start_at: startDateForEndpoint.split("T")[0],
                    end_at: endDateForEndpoint.split("T")[0],
                    limit: perPage,
                    offset: offsetData,
                }
            }
            else if (encodedReportParams.start_at !== undefined && encodedReportParams.end_at === undefined) {
                params = {
                    company: selectedCompany,
                    start_at: startDateForEndpoint.split("T")[0],
                    limit: perPage,
                    offset: offsetData,
                }
            }
            else if (encodedReportParams.start_at === undefined && encodedReportParams.end_at !== undefined) {
                params = {
                    company: selectedCompany,
                    end_at: endDateForEndpoint.split("T")[0],
                    limit: perPage,
                    offset: offsetData,
                }
            }
            else {
                params = {
                    company: selectedCompany,
                    limit: perPage,
                    offset: offsetData,
                }
            }
            
            getGeneralReport(params);
            getGeneralReportFiles(startDateForEndpoint, endDateForEndpoint);
        }
    }

    const getGeneralReport = (params) => {
        if (encodedReportParams.type !== 4) {
            params.type = encodedReportParams.type;
            getEncodedReport(params)
            .then(resp => {
                if (resp.success) {
    
                    if ( resp.data && resp.data.length > 0 ) {
                        setTotalItems(resp.data[0].count);
                    }
    
                    const displayData = resp.data.map(item => {
                        return (
                            {
                                'Usuario': item.user,
                                'Tag Id': item.tag_id,
                                'Estado': item.status,
                                'Código de Barras': item.serial,
                                'Placa': item.plate,
                                'Nombre': item.name,
                                'Lote': item.lot,
                                'Ubicación': item.location,
                                'ID': item.id.toString(),
                                'Fecha Asociación': moment(item.encoded_at).format('YYYY-MM-DD'),
                            }
                        );
                    });
    
                    setEncodedReport(displayData);
                    setQueryDone(true);
                } 
                else {
                    setEncodedReport([]);
                    setQueryDone(false);
                }
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                alert(err.message);
            });
        }
        else {
            params.custom_fields = true;
            params.cf_local_status = 'Activo de Menor Cuantía';
            getAssetsLowerAmount(params)
            .then(resp => {
                if (resp.success) {
                    
                    if ( resp.data && resp.data.length > 0 ) {
                        setTotalItems(resp.data[0].count);
                    }
    
                    const displayData = resp.data.map(item => {
                        return (
                            {
                                'Tipo': item.asset_type,
                                'Código de Barras': item.barcode,
                                'Clasificación': item.classifier,
                                'Descripción': item.description,
                                'Fecha Asociación': moment(item.encoded_at).format('YYYY-MM-DD'),
                                'Fecha Adquisición': moment(item.purchase_date).format('YYYY-MM-DD'),
                                'ID': item.id.toString(),
                                'Estado Local': item.local_status,
                                'Propietario': item.owner,
                                'Placa': item.plate,
                                'Serial': item.serial,
                                'Estado': item.status,
                                'Tag Id': item.tag_id,
                                'Usuario': item.user,
                            }
                        );
                    });
    
                    setEncodedReport(displayData);
                    setQueryDone(true);
                } 
                else {
                    setEncodedReport([]);
                    setQueryDone(false);
                }
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                alert(err.message);
            });
        }
    }

    const handleSelectChange = (value, index, event, details) => {
        setencodedReportParams({ ...encodedReportParams, type: value })
    }

    const handleStartDateChange = (value, dateValue, event) => {
        setencodedReportParams({ ...encodedReportParams, start_at: dateValue })
    }

    const handleEndDateChange = (value, dateValue, event) => {
        setencodedReportParams({ ...encodedReportParams, end_at: dateValue })
    }

    const getGeneralReportFiles = async (startDateForEndpoint, endDateForEndpoint) => {
        let params = {};
        if(encodedReportParams.type == 3){
            params = {
                company: selectedCompany,
                limit: perPage,
                offset: offsetData,
            }    
        }
        else if (encodedReportParams.start_at !== undefined && encodedReportParams.end_at !== undefined) {
            params = {
                company: selectedCompany,
                type: encodedReportParams.type,
                start_at: startDateForEndpoint.split("T")[0],
                end_at: endDateForEndpoint.split("T")[0],
            }
        }
        else if (encodedReportParams.start_at !== undefined && encodedReportParams.end_at === undefined) {
            params = {
                company: selectedCompany,
                type: encodedReportParams.type,
                start_at: startDateForEndpoint.split("T")[0],
            }
        }
        else if (encodedReportParams.start_at === undefined && encodedReportParams.end_at !== undefined) {
            params = {
                company: selectedCompany,
                type: encodedReportParams.type,
                end_at: endDateForEndpoint.split("T")[0],
            }
        }
        else {
            params = {
                company: selectedCompany,
                type: encodedReportParams.type,
            }
        }

        try {
console.log("params");
console.log(params);
          const link = await getGeneralAssetsReport(params);
          setLinkGeneralReports(link.link);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (linkGeneralReports !== '') {
            csvToObject();
        }
    }, [linkGeneralReports]);
    
    const csvToObject = () => {
        readRemoteFile(linkGeneralReports, {
            download: true,
            header: true,
            complete: async (results) => {
                setAmazonData(await results.data);
                setExcelDone(true);
            }
        });
    }
    
    useEffect(() => {
        if (prevPagination !== undefined && (prevPagination.offsetData !== offsetData || prevPagination.perPage !== perPage)) {
            getEncodedReportFromApi();
        }
    }, [offsetData, perPage]);

    const handleDataPagination = (startRow, rowsPerPage) => {
        setOffsetData(startRow);
        setPerPage(rowsPerPage);
    };

    const truncateString = (text, truncLength) => {
        if (text !== null && text !== undefined) {
            return text.length > truncLength ? `${text.substr(0, truncLength - 1)} ...` : text;
        }
        else {
            return '';
        }
    };

    const exportXLSX = () => {
        /*  Building the fileName  */
        let date = new Date();
        const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun",
          "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ];
    
        let day = date.getDate();
        let month = monthNames[date.getMonth()];
        let year = date.getFullYear();
    
        const fileName = 'TotalEjemplaresGenia ' + day + '-' + month + '-' + year;
    
        /*  Building Export Excel */
    
        /* make the worksheet */
        let ws = XLSX.utils.json_to_sheet(amazonData);
    
        /* add to workbook */
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Inventory");
    
        /* generate an XLSX file */
        XLSX.writeFile(wb, fileName + '.xlsx');
    }

    return (
        <Cell size={12}>
            <Card style={styles.card}>
                <CardTitle title="Reporte de Activos Asociados / Total" />
                <CardText>
                    <Card tableCard>
                        {
                            isLoading && (<LinearProgress />)
                        }
                        <Grid>
                            <Cell size={3}>
                                <DatePicker
                                    label="Fecha de inicio"
                                    fullWidth={false}
                                    value={encodedReportParams.start_at}
                                    id={'encodedReportParams_start_at'}
                                    onChange={handleStartDateChange}
                                />
                            </Cell>
                            <Cell size={3}>
                                <DatePicker
                                    label="Fecha final"
                                    fullWidth={false}
                                    value={encodedReportParams.end_at}
                                    id={'encodedReportParams_end_at'}
                                    onChange={handleEndDateChange}
                                />
                            </Cell>
                            <Cell size={3}>
                                <SelectField
                                    placeholder="Tipo"
                                    id="Tipo_field"
                                    menuItems={[
                                        { label: "No asociados", value: 1 },
                                        { label: "Asociados", value: 2 },
                                        { label: "Menor cuantía", value: 4 },
                                        { label: "Todos", value: 3 },
                                    ]}
                                    value={encodedReportParams.type}
                                    onChange={handleSelectChange}
                                />
                            </Cell>
                            <Cell size={3}>
                                <Button
                                    type="submit"
                                    flat
                                    className="md-cell md-cell--bottom"
                                    primary
                                    swapTheming
                                    onClick={getEncodedReportFromApi}
                                >
                                    Ver
                                </Button>
                            </Cell>
                            {encodedReport.length !== 0 && <Cell size={4}>
                                <Button
                                    raised
                                    primary
                                    disabled = {!excelDone}
                                    onClick = {() => exportXLSX()}
                                >
                                    {excelDone ? 'Descargar XLSX' : 'Convirtiendo...'}
                                </Button>
                                <Button
                                    primary
                                    raised
                                >
                                <a style={{textDecoration: 'none', color: 'white'}} href={linkGeneralReports}>Descargar CSV</a>
                                </Button>
                            </Cell>}
                        </Grid>
                        <DataTable baseId="simple-pagination">
                            {encodedReport.length === 0 ? (
                                <>
                                <TableHeader>
                                    <TableRow selectable={false}>
                                        <TableColumn>
                                        </TableColumn>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    <TableRow selectable={false}>
                                        <TableColumn style={{ textAlign: 'center', padding: '10px' }}>
                                        {queryDone ? 'No Hay Registros' : ''}
                                        </TableColumn>
                                    </TableRow>
                                </TableBody>
                                </>
                            ) : (
                                <>
                                <TableHeader>
                                    <TableRow selectable={false}>
                                    {encodedReport.length > 0 &&       //slicedNotEncodedReport .length por defecto REDUX
                                        Object.keys(encodedReport[0]).map((header, index) => (     //slicedNotEncodedReport[0] .map por defecto REDUX
                                        <TableColumn key={`${header}_${index}`} grow={index === 1}>
                                            {header}
                                        </TableColumn>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {encodedReport.map((item, index) => (            //slicedNotEncodedReport .map por defecto REDUX
                                    <TableRow key={`${item.id}_${index}`} selectable={false}>
                                        {Object.keys(item).map(key => (
                                        <TableColumn key={key}>
                                            {truncateString(item[key], 50)}
                                        </TableColumn>
                                        ))}
                                    </TableRow>
                                    ))}
                                </TableBody>
                                <TablePagination
                                    rows={totalItems} //encodedReport.length por defecto.
                                    rowsPerPageLabel="Registros por página"
                                    onPagination={handleDataPagination}
                                />
                                </>
                            )}
                        </DataTable>
                    </Card>
                </CardText>
            </Card>
        </Cell>
    );
};