import React, { Component } from 'react';
import { connect } from 'react-redux';
import fetchProgress from 'fetch-progress';
import * as d3 from "d3";
import XLSX from 'xlsx';
import {
  PieChart, Pie, Tooltip, ResponsiveContainer, Cell as ChartCell
} from 'recharts';
import { DefaultLayout } from '../../components';
import { fetchCampus } from '../../services/companies';
import { getInventories, deleteInventorySummary, getInventoryTotal } from '../../services/inventories';
import InventoryTable from './table';
import { DialogContainer, List, ListItem, Grid, Cell, Button, SelectField, LinearProgress, CircularProgress, Card, CardTitle, CardText, } from 'react-md';

const styles = {
  selectField: {
    width: 300,
  },
};

class Inventory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      queryDone: false,
      selectedCompany: props.selectedCompany,
      inventories: [],
      visible: false,
      visibleLoading: false,
      isloading: false,
      summary: "",
      dialogTitle: "",
      itemInventory: {},
      downloadData: '',
      amazonData: [],
      campusList: [
        {
          label: 'Todas las Sedes',
          value: -1
        }
      ],
      selectedCampus: 'Todas las Sedes',
      summaryData: [],
      booksLoaded: 0,
    }

    this.getInventoryList = this.getInventoryList.bind(this)
    this.fetchInventorySummary = this.fetchInventorySummary.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.showInventorySummary = this.showInventorySummary.bind(this)
    this.fetchInventoryTotal = this.fetchInventoryTotal.bind(this)
  }

  componentDidMount() {
    this.getInventoryList();
    this.getCampusList();
  }

  hide = () => {
    this.setState(
      { visible: false, 
        isloading: false, 
        selectedCampus: 'Todas las Sedes', 
        downloadData: '', 
        amazonData: [],
      }
    );
  };

  hideLoading = () => {
    this.setState({visibleLoading: false});
  }

  handleKeyDown = (e) => {
    const key = e.which || e.keyCode;
    if (key === 13 || key === 32) {
      // also close on enter or space keys
      this.hide();
    }
  };

  getCampusList = async () => {
    const params = {
      company: this.state.selectedCompany,
    }
    
    let aux = await fetchCampus(params);

    let campusSelectField = aux.map((item, i) => {
      if(item.name === null){
        return {
          label: 'null',
          value: i
        }
      }
      else{
        return {
          label: item.name,
          value: i
        }
      }
    });

    let campusListSelectField = this.state.campusList.concat(campusSelectField);

    this.setState({campusList: campusListSelectField});
  }

  getInventoryList() {
    getInventories({ company: this.state.selectedCompany })
      .then(resp => this.setState({ inventories: resp.asset }))
      .catch(err => console.log(err))
  }

  getSelectedCampus = async (value) => {
    let selected = this.state.campusList.filter(item => item.value === value)[0].label;

    this.setState({selectedCampus: selected}, () => this.fetchInventorySummary(this.state.amazonData));
  }

  fetchInventorySummary = (results) => {
    /* FILTRANDO REPORTE POR SEDE */
    let noAsociated = 0;
    let loaned = 0;
    let inventoried = 0;

    let auxCampusSummary = results.filter(item => {
      if(item.Sede === this.state.selectedCampus){
        return item;
      }
      else if(this.state.selectedCampus === 'Todas las Sedes'){
        return item;
      }
    });

    auxCampusSummary.map(item => {
      switch (item.Estado) {
        case 'INVENTARIADO': inventoried = inventoried + 1;
          break;
        case 'PRESTADO': loaned = loaned + 1;
          break;
        case 'NO ASOCIADO': noAsociated = noAsociated + 1;
          break;
      }
    });

    const campusSummary = {
      total: auxCampusSummary.length,
      noAsociated: noAsociated,
      loaned: loaned,
      inventoried: inventoried,
      company: this.state.selectedCompany,
    }
    /* //////////////////////////////////////// */

    let data = [];
    
    data = Object.keys(campusSummary).map(key => {
      if (key !== "company") {
        let item = {}
        switch (key) {
          case "inventoried":
            item = {
              name: "Inventariados",
              color: "#00ff00",
              value: campusSummary[key]
            }
            break;
          case "total":
            item = {
              name: "No inventariados",
              color: "#ff0000",
              value: (campusSummary["total"]
                - campusSummary["inventoried"]
                - campusSummary["loaned"]
                - campusSummary["noAsociated"]
              )
            }
            break;
          case "loaned":
            item = {
              name: "Prestados",
              color: "#ffff00",
              value: campusSummary[key]
            }
            break;
          case "noAsociated":
            item = {
              name: "No asociados",
              color: "#808080",
              value: campusSummary[key]
            }
            break;
          default:
            break;
        }
        return item
      }
    }).filter(el => el);

    this.setState({summaryData: data});
    this.setState({summary: campusSummary, visible: true, visibleLoading: false, booksLoaded: 0});
  }

  fetchInventoryTotal = async (id) => {
    let s3Link = await getInventoryTotal({ "inventory_id": id }).then(resp => resp.asset.link);
    const self = this;
    await fetch(s3Link)
    .then(
      fetchProgress({
        // implement onProgress method
        onProgress(progress) {
          self.setState({booksLoaded: progress.percentage});
        },
        onError(err) {
          console.log(err);
        },
      })
    )
    .then(response => response.blob())
    .then(async (src) => {
      let csvFileLocation = URL.createObjectURL(src);
      let dataset = [];
      await d3.csv(csvFileLocation, (row) => {
          dataset.push(row);
      });
      this.setState({amazonData: dataset, downloadData: s3Link});
      this.fetchInventorySummary(dataset);
    });
  }

  deleteItem(item) {
    if (window.confirm(`Oprima aceptar para eliminar el inventario ${item.name}`))
      deleteInventorySummary(item.id)
        .then(resp => {
          alert(`Hemos borrado el inventario ${item.name}`)
        })
        .catch(err => console.log(err))
        .finally(() => this.getInventoryList())
  }

  showInventorySummary = (item) => {
    this.setState({ visibleLoading: true, isloading: true, itemInventory: item, dialogTitle: item.Nombre });
    this.fetchInventoryTotal(item.id);
  }

  exportXLSX = () => {
    /*  Building the fileName  */
    let date = new Date();
    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun",
      "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ];

    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    const fileName = 'InvGenia_' + this.state.itemInventory.id + '_' + this.state.itemInventory.Nombre + ' ' + day + '-' + month + '-' + year;

    /*  Building Export Excel */

    /* make the worksheet */
    let ws = XLSX.utils.json_to_sheet(this.state.amazonData);

    /* add to workbook */
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Inventory");

    /* generate an XLSX file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  render() {

    return (
      <DefaultLayout>
        <Card>
          <CardTitle title='Inventario'/>
          {this.state.isloading && <LinearProgress id="loanProgress" />}
          <CardText>
            <InventoryTable
              inventories={this.state.inventories}
              deleteItem={this.deleteItem}
              showInventorySummary={this.showInventorySummary}
            />
          </CardText>
        </Card>
        <DialogContainer
          focusOnMount={false}
          visible={this.state.visibleLoading}
          title={`Preparando Reporte...`}
          onHide={() => this.hideLoading()}
          id='Loading'
        >
          <Grid>
            <Cell size={12}>
              <CircularProgress id="loanProgress" scale={2}/>
            </Cell>
            <Cell size={12} offset={1}>
              <strong>Procesando registros... {this.state.booksLoaded}%</strong>
            </Cell>
          </Grid>
        </DialogContainer>
        <DialogContainer
          visible={this.state.visible}
          title={`Distribución del inventario de ${this.state.dialogTitle}`}
          onHide={this.hide}
          id={this.state.title || "dialog"}
          width={"65%"}
          actions={[
            <Button
              raised
              primary
              disabled = {this.state.amazonData.length !== 0 ? false : true}
              onClick = {() => this.exportXLSX()}
            >
              {this.state.amazonData.length !== 0 ? 'Descargar XLSX' : 'Convirtiendo...'}
            </Button>,
            <Button
              primary
              raised
              disabled = {this.state.downloadData !== '' ? false : true}
            >
              <a style={{textDecoration: 'none', color: 'white'}} href={this.state.downloadData}>Descargar CSV</a>
            </Button>,
            { secondary: true, children: 'Salir', onClick: this.hide },
          ]}
        >
          <Grid>
            <Cell size={6}>
              <ResponsiveContainer minHeight={245}>
                <PieChart>
                  <Pie
                    data={this.state.summaryData}
                    dataKey="value"
                    startAngle={360}
                    endAngle={0}
                    cx={150}
                    cy={115}
                    outerRadius={80}
                    label >
                    {this.state.summaryData.map((entry, index) => (
                      <ChartCell key={index} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Cell>
            <Cell size={6}>
              <Grid>
                <Cell size={12}>
                  {this.state.campusList.length !== 0 && <SelectField
                    simplifiedMenu
                    id="type-select-field"
                    label="Selecciona una sede"
                    placeholder="Selecciona una sede"
                    menuItems={this.state.campusList}
                    defaultValue={this.state.campusList[0].value}
                    onChange={this.getSelectedCampus}
                    style={styles.selectField}
                  />}
                </Cell>
                <Cell size={12}>
                  <List onKeyDown={this.handleKeyDown}>
                    {Object.keys(this.state.summary).map((datum, index) => {
                      let title = ""
                      switch (datum) {
                        case "inventoried":
                          title = "Inventariados"
                          break;
                        case "total":
                          title = "Total"
                          break;
                        case "loaned":
                          title = "Prestados"
                          break;
                        case "noAsociated":
                          title = "No asociados"
                          break;
                        default:
                          title = "No inventariados"
                          break;
                      }
                      return (
                        <ListItem
                          key={index}
                          primaryText={title}
                          secondaryText={title === "No inventariados"
                            ? this.state.summaryData.find(el => el.name === "No inventariados").value
                            : this.state.summary[datum]}
                        />
                      )
                    })}
                  </List>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </DialogContainer>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  selectedCompany: state.user.selectedCompany
});

export default connect(
  mapStateToProps,
  null
)(Inventory);