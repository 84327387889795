import { uid } from 'uid';
import { appHealthAmazonEndpoint } from './config/endpoints';

const validateAccess = () => {
    const now = new Date();
  
    const s1Id = uid(32);
    const s2Id = uid(32);

    const s1 = s1Id.slice(0, 31);
    const s2 = s2Id.slice(0, 13);
    const s3 = s2Id.slice(14, 16);

    return `${now.getDate() * 3}${s1}${(now.getMonth() + 1) * 4}${s2}${(now.getFullYear() * 2) + 16}${s3}`;
}

export const getAppHealth = async (params) => {
    try {
        const validate = validateAccess();
        const response = await fetch(appHealthAmazonEndpoint(params), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                jwt_token: validate,
            },
        });

        if (!response.ok) {
            return {
                success: false,
                message: 'error',
            };
        }

        const data = await response.json();

        return {
            success: true,
            message: 'OK',
            data,
        };
    } catch (err) {
        console.log(`Unable to get the information from the Amazon database: ${err}`);
        throw err;
    }
}