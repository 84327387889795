import {
  GET_NOT_ENCODED_REPORT,
  PAGINATE_NOT_ENCODED_REPORT,
  TOGGLE_LOADING_NOT_ENCODED_REPORT,
  HANDLE_PICKER_CHANGE,
} from '../actions/types';

const initialState = {
  loadingNotEncodedReport: false,
  notEncodedReport: [],
  slicedNotEncodedReport: [],
  startDateNotEncoded: '',
  endDateNotEncoded: '',
};

const notEncodedReportReducer = (state = initialState, action) => {
  const { type, payload, startRow, rowsPerPage, pickerId, pickerValue } = action;
  switch (type) {
    case GET_NOT_ENCODED_REPORT:
      return {
        ...state,
        notEncodedReport: payload,
        slicedNotEncodedReport: payload.slice(0, 10),
        loadingNotEncodedReport: false,
      };
    case PAGINATE_NOT_ENCODED_REPORT:
      return {
        ...state,
        slicedNotEncodedReport: state.notEncodedReport.slice(startRow, startRow + rowsPerPage),
      };
    case TOGGLE_LOADING_NOT_ENCODED_REPORT:
      return {
        ...state,
        loadingNotEncodedReport: payload,
      };
    case HANDLE_PICKER_CHANGE:
      return {
        ...state,
        [pickerId]: pickerValue,
      };
    default:
      return state;
  }
};

export default notEncodedReportReducer;
