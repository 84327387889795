import { assetsEndpoint, assetsLowerAmountEndpoint, assetsPutEndpoint, fieldsEndpoint, 
  fieldsDropdownEndpoint, fieldsDropdownNotSerializeEndpoint, assetsReportEndpoint, assetsEncodedReportEndpoint,
  assetsNewsReportEndpoint, generalAssetsReportEndpoint } from './config/endpoints'
import { optionsGET, optionsPOST, optionsPUT, optionsPATCH, optionsDELETE } from './config/options'

export const postAsset = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(assetsEndpoint(), optionsPOST(data))
    if (response.status === 401) {
      return { status: 'unauthorized' }
    }
    const asset = await response.json()
    return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const searchAssetsService = async ({ company, location }) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(assetsEndpoint({ company, location }), optionsGET());
    if (response.status === 401) {
      return { status: 'unauthorized' };
    }
    const assets = await response.json();
    return assets;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`);
    throw err;
  }
};

export const searchAssetsSerialService = async ({ company, serial }) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(assetsEndpoint({ company, serial }), optionsGET());
    if (response.status === 401) {
      return { status: 'unauthorized' };
    }
    const assets = await response.json();
    return assets;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`);
    throw err;
  }
};

export const searchAssetsIdService = async ({ company, id }) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(assetsEndpoint({ company, id }), optionsGET());
    if (response.status === 401) {
      return { status: 'unauthorized' };
    }
    const assets = await response.json();
    return assets;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`);
    throw err;
  }
};

export const putAsset = async (id,data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(assetsPutEndpoint(id), optionsPUT(data))
    if (response.status === 401) {
      return { status: 'unauthorized' }
    }
    const asset = await response.json()
    return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const getAssets = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(assetsEndpoint(data), optionsGET());
    if (response.status !== 200) {
      return {
        success: false,
        message: 'unauthorized',
      };
    }
    const asset = await response.json();
    return {
      success: true,
      message: 'OK',
      asset,
    };
    // if (response.status === 401) {
    //   return { status: 'unauthorized' }
    // }
    // const asset = await response.json()
    // return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const getAssetsLowerAmount = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(assetsLowerAmountEndpoint(data), optionsGET());
    if (response.status !== 200) {
      return {
        success: false,
        message: 'unauthorized',
      };
    }
    const asset = await response.json();
    return {
      success: true,
      message: 'OK',
      data: asset,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const getFields = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(fieldsEndpoint(data), optionsGET())
    if (response.status === 401) {
      return { status: 'unauthorized' }
    }
    const asset = await response.json()
    return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const getFieldDropdown = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(fieldsDropdownEndpoint(data), optionsGET())
    if (response.status === 401) {
      return { status: 'unauthorized' }
    }
    const asset = await response.json();
    return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const postFieldDropdown = async (params, data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(fieldsDropdownEndpoint(params), optionsPOST(data))
    if (response.status === 401) {
      return { status: 'unauthorized' }
    }
    const asset = await response.json();
    return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const patchFieldDropdown = async (params, data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(fieldsDropdownNotSerializeEndpoint(params), optionsPATCH(data))
    if (response.status === 401) {
      return { status: 'unauthorized' }
    }
    const asset = await response.json();
    return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const deleteFieldDropdown = async (params) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(fieldsDropdownNotSerializeEndpoint(params), optionsDELETE());
    if (response.status === 401) {
      return { status: 'unauthorized' }
    }
    // const asset = await response.json();    // response 204 no content, nada para retornar como respuesta en .json()...
    // return { asset }
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}

export const getReport = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(assetsReportEndpoint(data), optionsGET());
    if (response.status !== 200) {
      return { 
        success: false,
        message: 'unauthorized',
      };
    }
    const asset = await response.json();
    return { 
      success: true,
      message: 'OK',
      data: asset,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw new Error(err.message)
  }
}
export const getEncodedReport = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(assetsEncodedReportEndpoint(data), optionsGET())
    if (response.status !== 200) {
      return {
        success: false,
        message: 'unauthorized',
      };
    }
    const asset = await response.json();
    return {
      success: true,
      message: 'OK',
      data: asset,
    };
    // if (response.status === 401) {
    //   return { status: 'unauthorized' }
    // }
    // const asset = await response.json()
    // return { asset }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw err
  }
}
export const getNewsReport = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(assetsNewsReportEndpoint(data), optionsGET())
    if (response.status !== 200) {
      return {
        success: false,
        message: 'unauthorized',
      };
    }
    const asset = await response.json();
    return {
      success: true,
      message: 'OK',
      data: asset,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`Unable to post the information to the database: ${err}`)
    throw new Error(err.message)
  }
}

export const getGeneralAssetsReport = async params => {
  try {
    let response = await fetch(generalAssetsReportEndpoint(params), optionsGET());
    response = response.json();
    return response;
  } catch (err) {
    console.log(`Unable to get the information from the database: ${err}`);
    throw err;
  }
};